import React, { useState } from "react";
import { HiDownload } from "react-icons/hi";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

export const EachDocument = ({
  eachDocument,
  size,
  openInNewTab,
  draft,
  selectedId,
  setSelectedId,
  setShowDeleteModal,
  deleteLoading,
  showDelete = false,
}) => {
  const navigate = useNavigate();

  // ! CHANGE DOCUMENT ID

  const openDocument = (e) => {
    e.preventDefault();

    if (typeof e.target.className === "string") {
      if (e.target.className?.split("eachDocument")[0] === "") {
        if (draft) {
          navigate(`/document/${eachDocument.id}/draft`);
        } else if (openInNewTab) {
          window.open(`/document/${eachDocument.id}`, "_blank");
        } else {
          navigate(`/document/${eachDocument.id}`);
        }
      }
    } else {
      //
    }
  };

  const toggleMenu = (id) => {
    if (id === selectedId) {
      setSelectedId("");
    } else {
      setSelectedId(id);
    }
  };

  return (
    <div
      className={`eachDocument  ${size === "small" && "smallDocumentPreview"}`}
      onClick={(e) => openDocument(e)}
    >
      <div className="eachDocumentPreview">
        <div className="eachDocumentImage">
          <p className="eachDocumentImageName">
            {eachDocument ? eachDocument.name : ""}
          </p>

          <>
            <div className="eachDocumentImageSkeletonContainer">
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
            </div>

            <div className="eachDocumentImageSkeletonContainer">
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
            </div>
          </>

          {/* <p className="eachDocumentImageName">{eachDocument.name}</p> */}
        </div>
      </div>
      <div className="eachDocumentInfo">
        <h1 className="eachDocumentName">
          {eachDocument ? eachDocument.name : ""}
        </h1>
        {showDelete && (
          <div className="documentDetailButton">
            <HiOutlineEllipsisVertical
              className="documentDetailIcon"
              style={{
                backgroundColor:
                  eachDocument.id === selectedId ? "rgb(228, 228, 230)" : "",
              }}
              onClick={() => toggleMenu(eachDocument.id)}
            />

            {/* {eachDocument.id} */}

            {selectedId === eachDocument.id && (
              <div className="detailDropdown">
                {/* <div
                className="eachDetailDropdown"
                onClick={() => downloadDocument()}
              >
                {downloadLoading ? (
                  <Oval
                    height={15}
                    width={15}
                    color="#1252f3"
                    wrapperStyle={{ marginLeft: 5 }}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#1252f320"
                    strokeWidth={5}
                    strokeWidthSecondary={5}
                  />
                ) : (
                  <HiDownload className="eachDropdownIcon" />
                )}
                <p className="eachDropdownName">Download</p>
              </div> */}

                <div
                  className="eachDetailDropdown deleteDropdown"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <MdDelete className="eachDropdownIcon" />

                  <p className="eachDropdownName">Delete</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
