import React, { useEffect, useRef, useState } from "react";
import { Link, redirect, useParams } from "react-router-dom";

import { IoClose, IoWarning } from "react-icons/io5";
import { TbSignature, TbVersions } from "react-icons/tb";

import ReactDOMServer from "react-dom/server";
import { Oval } from "react-loader-spinner";
import {
  createSignatureAPI,
  getDocumentDraftAPI,
  getDocumentPaymentLinkAPI,
  getSignedDocument,
  getUserAPI,
  saveDraftDocumentAPI,
  upgradePlanAPI,
} from "../../api/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { updateDocument } from "../../redux/actions/documentActions";
import { PdfViewer } from "../PDFViewer";
import { DocumentViewerLoader } from "./loader";
import { MdUpdate } from "react-icons/md";
import { animated, easings, useSpring } from "@react-spring/web";

import { v4 as uuidv4 } from "uuid";
import { useDocumentElementsContext } from "../FinalDocumentChat/FinalDocumentContext";
import { FiInfo } from "react-icons/fi";
import {
  BsCheck,
  BsFillRocketTakeoffFill,
  BsInfoCircleFill,
  BsRocketFill,
} from "react-icons/bs";
import { returnFormattedDocumentsArray } from "../../functions";
import { paymentPlans } from "../../data";
import { updateUser } from "../../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";
import { IoMdCheckmark } from "react-icons/io";
import RadixModal from "../Modal/RadixModal";
import { BiStar } from "react-icons/bi";

const documentAsArray = [
  {
    id: 1,
    text: "Service Agreement",
    type: "TITLE",
  },
  {
    id: 2,
    text: 'This Service Agreement (hereinafter referred to as "the Agreement") is made and entered into as of the 23rd of August 2023, by and between Minto, a financial platform for businesses that utilizes AI to understand financial data and create reports, analytics, forecast revenues, and other financial business planning (hereinafter referred to as "the Company"), and its users (hereinafter referred to as "the Users"). This Agreement governs the provision of services by Minto to its Users in accordance with the laws of California, United States.',
    type: "PARAGRAPH",
  },
  {
    id: 3,
    text: "Definitions",
    type: "SUBTITLE",
  },
  {
    id: 4,
    text: '1. "Minto" refers to the financial platform for businesses, which uses AI to understand financial data and create reports, analytics, forecast revenues, and other financial business planning.',
    type: "PARAGRAPH",
  },
  {
    id: 5,
    text: '2. "AI" refers to artificial intelligence, a branch of computer science that simulates human intelligence to perform tasks such as data analysis, problem-solving, and decision-making.',
    type: "PARAGRAPH",
  },
  {
    id: 6,
    text: '3. "Basic features" refer to the functionalities of Minto that are accessible to all users without the need for a premium plan, including limited projects, product integrations, API access, and customer support.',
    type: "PARAGRAPH",
  },
  {
    id: 7,
    text: '4. "Premium plan" refers to the upgraded subscription package offered by Minto, which grants users access to unlimited projects, unlimited product integrations, unlimited API access for developers, early access to new features, and 24/7 customer support.',
    type: "PARAGRAPH",
  },
  {
    id: 8,
    text: "5. 'Users' refer to individuals or entities who utilize Minto's services, including both free and premium plan users.",
    type: "PARAGRAPH",
  },
  {
    id: 9,
    text: '6. "Service provider" refers to Stripe, a company that makes international payments simpler through easy API and fraud detection.',
    type: "PARAGRAPH",
  },
  {
    id: 10,
    text: '7. "Services" refer to the financial planning, reporting, analytics, and other related services provided by Minto to its Users.',
    type: "PARAGRAPH",
  },
  {
    id: 11,
    text: '8. "Effective Date" refers to the date on which this Agreement comes into force, which is the 23rd of August 2023.',
    type: "PARAGRAPH",
  },
];

let drafted = false;

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        type: "header",
        data: {
          text: "This is my awesome editor!",
          level: 1,
        },
      },
    ],
  };
};

export const DocumentViewer = ({
  updatingElementId,
  updatingTextStream,
  setUpdatingElementId,
  setUpdatingTextStream,
  updatingElementData,
}) => {
  const { id, versionId } = useParams();

  const [signatures, setSignatures] = useState([]);
  // const [signatureId, setSignatureId] = useState(1);

  const {
    documentElementsArray,
    updateArray: setDocumentElementsArray,
    // saveDocumentElements: saveDocument,
  } = useDocumentElementsContext();

  const {
    signatures: documentSignatures,
    documentInfo,
    editor: savedEditor,
  } = useSelector((state) => state.document);

  const [currentSelectedSignature, setCurrentSignature] = useState(null);

  const { token, ...userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { unsavedChanges } = useSelector((state) => state.document);

  const [addSignatureLoading, setAddSignatureLoading] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [streamStatus, setStreamStatus] = useState("");
  const [showVersions, setShowVersions] = useState(false);

  // document changing
  const [updatingElementInfo, setUpdatingElementInfo] = updatingElementData;
  const [isDocumentChanging, setIsDocumentChanging] = useState(false);

  let localArray = [];

  const [documentViewerProps, setDocumentViewerProps] = useSpring(() => ({
    backgroundColor: "white",
    padding: 0,
    borderRadius: 0,
  }));

  const removeSignature = (elementId) => {
    // // //"removing sign");
    setCurrentSignature(null);

    document.getElementById(elementId).remove();

    const newSignaturesArray = signatures.filter(
      (signature) => signature.id !== elementId
    );

    setSignatures(newSignaturesArray);
    dispatch(
      updateDocument({
        signatures: newSignaturesArray,
      })
    );
  };
  let signatureId = 1;

  const handleSignatureDrop = (dragEvent) => {
    // // //"Signature Drag Event: ", dragEvent);

    let signatureIdDiv = `finalDocumentSignatureElement${signatureId}`;

    const signatureElement = document.createElement("div");
    signatureElement.contentEditable = "false";
    signatureElement.className = "finalDocumentSignatureElement";
    signatureElement.id = `finalDocumentSignatureElement${signatureId}`;
    signatureElement.innerHTML = ReactDOMServer.renderToString(
      <>
        <p>Add your signature</p>
        <IoClose
          className="signatureElementRemove"
          id={`finalDocumentSignatureElementRemove${signatureId}`}
        />
      </>
    );

    setTimeout(() => {
      const removeSignElement = document.getElementById(
        `finalDocumentSignatureElementRemove${signatureId}`
      );

      if (removeSignElement) {
        const signatureElement = document.getElementById(signatureIdDiv);
        signatureElement.addEventListener("click", (e) => {
          // // //e.target);

          // // //"adding sign");
          // // //"from adding sign: ", currentSelectedSignature);

          setCurrentSignature(signatureIdDiv);

          // const signature = signatures.filter(
          //   (signature) => signature.id === signatureIdDiv
          // );

          // alert(JSON.stringify(signature));

          // setCurrentSignature(signature);
        });

        removeSignElement.addEventListener("click", (e) => {
          removeSignature(e.target.parentElement.id);
          // // // //`finalDocumentSignatureElement${signatureId}`);
        });
      }
    }, 500);

    signatureElement.style = `position: absolute; top: ${
      dragEvent.layerY - 0
    }px; left: ${dragEvent.layerX - 80}px`;

    // // //dragEvent.target);

    const documentViewerElement =
      document.getElementById(dragEvent.target.id) ||
      document.getElementById(dragEvent.target.offsetParent.id);
    documentViewerElement.appendChild(signatureElement);

    const signaturesList = [...signatures];
    signaturesList.push({
      id: signatureIdDiv,
      pageNumber: dragEvent.target.id.split("finalDocumentViewer")[1],
      xPosition: dragEvent.layerX,
      yPosition: dragEvent.layerY,
      details: null,
    });

    signatureId = signatureId + 1;

    setCurrentSignature(signatureIdDiv);

    setSignatures(signaturesList);
    dispatch(
      updateDocument({
        signatures: [
          ...documentSignatures,
          {
            id: signatureIdDiv,
            pageNumber: dragEvent.target.id.split("finalDocumentViewer")[1],
            xPosition: dragEvent.layerX,
            yPosition: dragEvent.layerY,
            details: null,
          },
        ],
      })
    );
  };

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html.body;
  };

  // let localDocumentElementsArray = [...documentElementsArray];

  const saveDocument = async () => {
    // const formatted = returnFormattedDocumentsArray();

    const response = await saveDraftDocumentAPI({
      draft_id: id,
      content: localArray,
    });

    if (response.data) {
      dispatch(
        updateDocument({
          documentInfo: {
            ...documentInfo,
            document_drafts: [localArray],
          },
        })
      );

      setStreamStatus("COMPLETED");

      const mainContainer = document.querySelector("#finalDocumentViewer");
      if (mainContainer) {
        mainContainer.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const addToElements = (object) => {
    // if (documentInfo && documentInfo?.status === "LIMITED_DOCUMENT_DRAFTED") {
    //   //("PUSHING SAVED ARRAY TO LOCAL ARRAY");
    //   localArray = [
    //     documentInfo?.document_drafts[documentInfo?.document_drafts?.length - 1],
    //   ];
    // }

    if (object && !object.text.includes("null")) {
      localArray.push({
        id: uuidv4(),
        ...object,
      });
      setDocumentElementsArray((prev) => {
        return [...localArray];
      });
      dispatch(
        updateDocument({
          documentElements: [...localArray],
        })
      );
    }
  };

  const [showIncompleteDocModal, setShowIncompleteDocModal] = useState(false);

  let newElement = null;

  async function readStream(stream, editor) {
    const populateStreams = (object) => {
      // //object.data.delta);

      if (!newElement && object.data?.delta?.content.includes("^")) {
        newElement = "";
        return;
      }

      if (newElement !== null && object.data?.delta?.content.includes("^")) {
        // //"Completed Title", newElement);
        addToElements({
          type: "TITLE",
          text: newElement + object.data?.delta?.content.split("^")[0],
        });
        newElement = null;
        return;
      }

      if (!newElement && object.data?.delta?.content.includes("#")) {
        newElement = "";
        return;
      }

      if (
        newElement !== null &&
        object.data?.delta?.content.includes("#\n\n")
      ) {
        //"Completed Subtitle: ", newElement);

        addToElements({ type: "SUBTITLE", text: newElement });

        newElement = null;
        return;
      }

      if (
        (newElement && object.data?.delta?.content === ".\n\n") ||
        object.data?.delta?.content === " \n\n" ||
        object.data?.delta?.content === "\n\n"
      ) {
        newElement = newElement + object.data?.delta?.content;

        addToElements({ type: "PARAGRAPH", text: newElement });

        //"Para done: ", newElement);
        newElement = null;
        return;
      }

      if (newElement !== null) {
        newElement = newElement + object.data?.delta?.content;
        return;
      }

      if (!newElement && object.data?.delta?.content) {
        newElement = object.data?.delta?.content;
        return;
      }
    };

    const reader = stream.getReader();
    while (true) {
      setStreamStatus("PENDING");

      const { done, value } = await reader.read();
      if (done) {
        saveDocument(editor);

        // getDocumentDraft();

        if (documentInfo?.resourceType === "FREE_RESOURCE") {
          //("DOCUMENT IS FROM FREE PLAN: SHOWING PURCHASE");

          setShowIncompleteDocModal(true);

          setShowExpandedUpgradeButton(true);
          setShowUpgradePlan(true);
          dispatch(
            updateDocument({
              documentInfo: {
                ...documentInfo,
                payment: false,
                resourceType: "FREE_RESOURCE",
                status: "LIMITED_DOCUMENT_DRAFTED",
              },
            })
          );
        } else {
          // setShowUpgradePlan(true);

          //(documentInfo);

          setTimeout(() => {
            const link = document.createElement("a");
            const linkUrl = `https://tally.so#tally-open=mVQJll&tally-width=570&tally-emoji-text=👋&tally-emoji-animation=wave&email=${userData.email}&name=${userData.name}`;
            link.href = linkUrl;
            link.target = "_blank";
            link.style.display = "none";

            document.body.appendChild(link);
            link.click();
          }, 20000);
        }
        return;
      }

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data?.delta) {
          populateStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        const objects = input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data?.delta) {
              populateStreams(object);
            }
          });
      }
    }
  }

  const streamDocumentDraft = async (editor) => {
    // alert("here");

    setDocumentLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/flow/draft-document`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
        body: JSON.stringify({
          draftID: id,
        }),
      }
    );

    if (response.ok) {
      setDocumentLoading(false);

      await readStream(response.body);
    }
  };

  const closeSignature = () => {
    const isSignatureAdded = signatures.filter(
      (signature) =>
        signature.id === currentSelectedSignature &&
        signature.details !== null &&
        signature.details.name &&
        signature.details.email
    )[0];

    if (isSignatureAdded) {
      setCurrentSignature(null);
    } else {
      removeSignature(currentSelectedSignature);
      setCurrentSignature(null);
    }
  };

  const addDetailsToSignature = (signatureId, label, value) => {
    let newSignaturesArray = signatures.map((signature) => {
      if (signature.id === signatureId) {
        return {
          ...signature,
          details: {
            ...signature.details,
            [label]: value,
          },
        };
      } else {
        return {
          ...signature,
        };
      }
    });

    setSignatures(newSignaturesArray);
    dispatch(
      updateDocument({
        signatures: newSignaturesArray,
      })
    );
  };

  const getSignatureDetailsValue = (signatureId, label) => {
    const signature = signatures.filter(
      (signature) => signature.id === signatureId
    )[0];
    if (signature && signature.details) {
      return signature.details[label];
    } else return "";
  };

  const getSignatureInfo = (signatureId) => {
    const signature = signatures.filter(
      (signature) => signature.id === signatureId
    )[0];
    if (signature) {
      return signature;
    } else return;
  };

  const addSignature = async (signatureId) => {
    setAddSignatureLoading(true);

    const signature = getSignatureInfo(currentSelectedSignature);

    const response = await createSignatureAPI({
      draft: id,
      name: signature.details.name,
      email: signature.details.email,
      xPosition: signature.xPosition - 40,
      yPosition: signature.yPosition + 150,
      pageNumber: signature.pageNumber,
    });

    if (response.data) {
      addDetailsToSignature(signatureId, "finalised", true);
      const addSignatureElement = document.getElementById(signatureId);

      addSignatureElement.addEventListener("click", () => {
        setCurrentSignature(signatureId);
      });

      // // //addSignatureElement.children);

      const removeSignatureIconElement = document.getElementById(
        addSignatureElement.children[1].id
      );

      removeSignatureIconElement.remove();

      addSignatureElement.children[0].innerHTML =
        getSignatureInfo(signatureId).details.name;
      addSignatureElement.classList.add("detailAddedSignature");

      setAddSignatureLoading(false);

      closeSignature();
    }
  };

  const addSignaturesToTheDocument = (signatureData) => {
    const newSignaturesArray = [];

    if (
      signatureData &&
      signatureData.signers &&
      signatureData.signers.length !== 0
    ) {
      signatureData.signers.map((signature) => {
        const signaturePageElement = document.getElementById(
          `finalDocumentViewer${signature.position.pageNumber}`
        );

        // // //signaturePageElement);

        newSignaturesArray.push({
          id: signature._id,
          xPosition: signature.position.xPosition + 40,
          yPosition: signature.position.yPosition - 150,
          details: {
            name: signature.name,
            email: signature.email,
            finalised: true,
          },
        });

        const newSignatureElement = document.createElement("div");
        newSignatureElement.contentEditable = false;
        newSignatureElement.className = "finalDocumentSignatureElement";
        newSignatureElement.classList.add("detailAddedSignature");

        newSignatureElement.innerHTML = `<p>${signature.name}</p>`;

        newSignatureElement.style = `position: absolute; top:${
          signature.position.yPosition - 170
        }px; left: ${signature.position.xPosition + 40}px;`;

        newSignatureElement.addEventListener("click", () => {
          setCurrentSignature(signature._id);
        });

        signaturePageElement.appendChild(newSignatureElement);
      });

      setSignatures(newSignaturesArray);
    }
  };

  const [pdfData, setPdfData] = useState(null);

  const [documentLoading, setDocumentLoading] = useState(false);

  useEffect(() => {
    dispatch(
      updateDocument({
        signatures: [],
      })
    );
  }, []);

  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const generatePaymentLink = async (onSuccess) => {
    setPurchaseLoading(true);

    // //document.documentInfo
    const response = await upgradePlanAPI({
      redirectUrl: window.location.href,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPurchaseLoading(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const [perDocumentLoading, setPerDocumentLoading] = useState(false);

  const buyDocument = async () => {
    setPerDocumentLoading(true);

    const response = await getDocumentPaymentLinkAPI({
      documentId: documentInfo?.document,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPerDocumentLoading(false);

      //
    }
  };

  const getDocumentDraft = async (editor) => {
    setDocumentLoading(true);

    // dispatch(updateDocument({
    //   documentInfo:
    // }))

    try {
      dispatch(
        updateDocument({
          signatures: [],
          documentInfo: {
            ...documentInfo,
            lawyerReview: "",
          },
        })
      );

      const response = await getDocumentDraftAPI(id);

      if (response.data) {
        setDocumentLoading(false);

        streamDocumentAfterPayment(response.data);

        if (response.data.resourceType === "FREE_RESOURCE") {
          setShowExpandedUpgradeButton(true);
          setShowIncompleteDocModal(true);
          setShowUpgradePlan(true);
        }

        dispatch(
          updateDocument({
            documentInfo: response.data,
          })
        );

        //"VERSION ID: ", versionId);

        // if (
        //   response.data.accessStatus === "FREE" &&
        //   response.data?.status === "LIMITED_DOCUMENT_DRAFTED"
        // ) {
        //   // alert("setting one");
        //   setShowExpandedUpgradeButton(true);
        // }
        if (versionId) {
          const documentElements =
            documentInfo?.document_drafts[parseInt(versionId) - 1].content;

          //"document elements: ", documentElements);

          setDocumentElementsArray(documentElements);

          dispatch(updateDocument({ documentElements: documentElements }));
        } else if (response.data?.document_drafts?.length !== 0) {
          setDocumentElementsArray(
            response.data?.document_drafts[
              response.data?.document_drafts?.length - 1
            ].content
          );

          dispatch(
            updateDocument({
              documentElements:
                response.data?.document_drafts[
                  response.data?.document_drafts?.length - 1
                ].content,
            })
          );
        } else {
          if (response.data?.document_drafts?.length === 0) {
            streamDocumentDraft();
          }
        }
      }
    } catch (error) {
      //"ERROR: ", error);
      return;
    }
  };

  useEffect(() => {
    if (streamStatus === "PENDING") {
      setInterval(() => {
        const loaderElement = document.getElementById("simplifyDocumentLoader");
        if (loaderElement) loaderElement.scroll({ behavior: "smooth" });
      }, 5000);
    }
  }, [streamStatus]);

  useEffect(() => {
    // setCurrentSignature(null);
    // // //signatures);
    if (signatures.length === 0) {
      setCurrentSignature(null);
    }
  }, [signatures, currentSelectedSignature]);

  useEffect(() => {
    getDocumentDraft();
  }, []);

  const showDocumentVersion = (index) => {
    const documentElements =
      documentInfo?.document_drafts[parseInt(versionId)].content;

    setDocumentElementsArray(documentElements);

    dispatch(updateDocument({ documentElements: documentElements }));
    //"adding");
  };

  useEffect(() => {
    // document.querySelector(".documentContent").innerHTML = "";

    if (versionId) {
      // showDocumentVersion();
      // getDocumentDraft();
      setShowVersions(false);
    }
  }, [versionId]);

  const isParagraphBetweenHeaders = (paragraphID, headerID) => {
    let foundHeader = false;
    let foundParagraph = false;

    for (const item of documentElementsArray) {
      if (item.type === "SUBTITLE" && item.id === headerID) {
        foundHeader = true;
      } else if (foundHeader && item.type === "SUBTITLE") {
        break; // Reached the next header, exit the loop
      } else if (foundHeader && item.type === "PARAGRAPH") {
        if (item.id === paragraphID) {
          foundParagraph = true;
          break; // Found the paragraph, exit the loop
        }
      }
    }

    return foundParagraph;
  };

  const [showExpandedUpgradeButton, setShowExpandedUpgradeButton] =
    useState(true);

  const [userInfo, setUserInfo] = useState(null);
  const [showUpgradePlan, setShowUpgradePlan] = useState(false);

  const getUserInfo = async () => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const streamDocumentAfterPayment = async (localDocumentData) => {
    // console.log("CALLING STREAM FUNCTION");
    // console.log("DOCUMENT INFO BEFORE STREAMING: ", localDocumentData);

    if (
      localDocumentData &&
      localDocumentData.resourceType === "PAID_RESOURCE" &&
      localDocumentData.payment === true &&
      localDocumentData.status === "LIMITED_DOCUMENT_DRAFTED" &&
      streamStatus !== "PENDING" &&
      !documentLoading &&
      localDocumentData._id === id
    ) {
      // console.log("🔴 STREAMING DOCUMENT");

      localArray = [];

      let newDocumentInfo = {
          ...localDocumentData,
          payment: true,
          status: "DOCUMENT_DRAFTED",
          resourceType: "PAID_RESOURCE",
        },
        drafted = true;
      dispatch(
        updateDocument({
          documentInfo: {
            ...documentInfo,
            payment: true,
            status: "DOCUMENT_DRAFTED",
            resourceType: "PAID_RESOURCE",
          },
        })
      );

      setShowExpandedUpgradeButton(false);
      setShowUpgradePlan(false);

      // console.log("DOCUMENT INFO: ", newDocumentInfo);

      streamDocumentDraft();
    }
  };

  useEffect(() => {
    if (
      userInfo &&
      documentInfo &&
      documentInfo.document_drafts &&
      documentInfo?.payment === false &&
      documentInfo?.document_drafts?.length !== 0
    ) {
      // setShowExpandedUpgradeButton(true);
      setShowUpgradePlan(true);
    } else {
      setShowExpandedUpgradeButton(false);
      setShowUpgradePlan(false);
    }
  }, [documentInfo, userInfo]);

  // document change function
  let currentSubTitleId = null;

  let latestDocumentContent =
    documentInfo &&
    documentInfo.document_drafts &&
    documentInfo.document_drafts.length !== 0 &&
    documentInfo.document_drafts[
      versionId ? versionId - 1 : documentInfo.document_drafts.length - 1
    ]?.content;

  const getElementDataFromID = (id) => {
    return documentElementsArray.filter(
      (eachElement) => eachElement.id === id
    )[0];
  };

  const updateDocumentFromChat = async () => {
    if (
      updatingElementInfo &&
      updatingElementInfo?.ID &&
      updatingElementInfo?.Data &&
      updatingElementInfo?.Action &&
      updatingElementInfo?.Reason
    ) {
      // // console.log("its here: ", getElementDataFromID(updatingElementInfo.ID));

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "UPDATE_CLAUSE"
      ) {
        // // // console.log(
        //   "UPDATING PARAGRAPH",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent = latestDocumentContent.map(
          (eachContentObject) => {
            if (eachContentObject.id === updatingElementInfo.ID) {
              return {
                ...eachContentObject,
                text: updatingElementInfo.Data,
              };
            } else {
              return {
                ...eachContentObject,
              };
            }
          }
        );
      }

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "UPDATE_SECTION"
      ) {
        // // console.log(
        //   "UPDATING SECTION",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent.map((eachContentObject, index) => {
          if (eachContentObject.type === "SUBTITLE") {
            currentSubTitleId = eachContentObject.id;

            if (currentSubTitleId === updatingElementInfo.ID) {
              latestDocumentContent[index + 1] = {
                type: "PARAGRAPH",
                id: uuidv4(),
                text: updatingElementInfo.Data,
              };

              // // console.log(latestDocumentContent[index + 1]);
            }
          } else if (
            eachContentObject.type === "PARAGRAPH" &&
            currentSubTitleId === updatingElementInfo.ID &&
            latestDocumentContent[index - 1].type !== "SUBTITLE"
          ) {
            let newDocumentContentArray = latestDocumentContent.filter(
              (eachObject) => eachObject.id !== eachContentObject.id
            );
            latestDocumentContent = newDocumentContentArray;
          }
        });
      }

      if (
        updatingElementInfo.Action &&
        updatingElementInfo.Action === "NEW_CLAUSE"
      ) {
        // // console.log(
        //   "ADDING NEW CLAUSE",
        //   getElementDataFromID(updatingElementInfo.ID)
        // );

        latestDocumentContent.map((eachContentObject, index) => {
          if (eachContentObject.id === updatingElementInfo.ID) {
            let newObject = {
              type: "PARAGRAPH",
              id: uuidv4(),
              text: updatingElementInfo.Data,
            };

            latestDocumentContent.splice(index + 1, 0, newObject);

            // // console.log(latestDocumentContent[index + 1]);
          }
        });
      }

      setUpdatingElementInfo(null);

      // // console.log("NEW DOCUMENT ARRAY: ", latestDocumentContent);

      setDocumentElementsArray(latestDocumentContent);

      const response = await saveDraftDocumentAPI({
        draft_id: id,
        content: latestDocumentContent,
      });
      dispatch(
        updateDocument({
          documentInfo: {
            ...documentInfo,
            document_drafts: [
              ...documentInfo?.document_drafts,
              {
                version: documentInfo?.document_drafts?.length,
                content: latestDocumentContent,
              },
            ],
          },
          unsavedChanges: false,
        })
      );
    }
  };

  useEffect(() => {
    updateDocumentFromChat();
  }, [updatingElementInfo]);

  return (
    <>
      {currentSelectedSignature !== null && (
        <div className="modal">
          <div
            className="modalOverlay"
            onClick={() => {
              closeSignature();
            }}
          ></div>

          {/* {JSON.stringify(signatures)} */}

          <div className="modalContent">
            <div className="modalContentHeader flexModalContentHeader">
              <div className="modalContentHeaderLeft">
                <TbSignature className="modalContentHeaderIcon" />
                <p className="modalContentHeaderText">
                  Details of the signature
                </p>
              </div>

              <IoClose
                className="modalContentHeaderCloseIcon"
                onClick={() => {
                  closeSignature();
                }}
              />
            </div>

            <div className="modalMainContent">
              <div className="modalInputs modalCenterContent">
                <div className="modalInputContainer">
                  <p className="modalInputLabel inputLabel">
                    Name of the Signer
                  </p>
                  <input
                    type="text"
                    className={`modalInput createDocumentInput ${
                      getSignatureDetailsValue(
                        currentSelectedSignature,
                        "finalised"
                      ) && "disabledInput"
                    }`}
                    placeholder="John"
                    autoFocus
                    disabled={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "finalised"
                    )}
                    value={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "name"
                    )}
                    onChange={(e) =>
                      addDetailsToSignature(
                        currentSelectedSignature,
                        "name",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div className="modalInputContainer">
                  <p className="modalInputLabel inputLabel">
                    Email of the Signer
                  </p>
                  <input
                    type="text"
                    className={`modalInput createDocumentInput ${
                      getSignatureDetailsValue(
                        currentSelectedSignature,
                        "finalised"
                      ) && "disabledInput"
                    }`}
                    placeholder="james@gmail.com"
                    disabled={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "finalised"
                    )}
                    value={getSignatureDetailsValue(
                      currentSelectedSignature,
                      "email"
                    )}
                    onChange={(e) =>
                      addDetailsToSignature(
                        currentSelectedSignature,
                        "email",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>

            {!getSignatureDetailsValue(
              currentSelectedSignature,
              "finalised"
            ) && (
              <div
                className={`modalButtonContainer ${
                  addSignatureLoading && "disabledButton"
                }`}
                onClick={() => addSignature(currentSelectedSignature)}
                disabled={addSignatureLoading}
              >
                <div className="modalMainContentButton">
                  <p className="modalMainContentButtonText">Add Signature</p>

                  {addSignatureLoading && (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{}}
                      wrapperClass="modalMainContentButtonLoader"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!showIncompleteDocModal &&
        ((documentInfo?.resourceType === "FREE_RESOURCE" &&
          documentInfo?.payment === false) ||
          userData.plan !== paymentPlans.PRO_PLAN) && (
          <div className="finalDocumentUpgradeInfo">
            <button
              className={`finalDocumentUpgradeInfoButton ${
                purchaseLoading && "disabledButton"
              }`}
              onClick={() => setShowIncompleteDocModal(true)}
            >
              ❕ Upgrade and Get the Complete document
            </button>
          </div>
        )}

      <div className="finalDocumentViewerContent">
        <RadixModal
          title="This document is Incomplete!"
          description="You are using the free version of this document. This only has drafted a part of the document."
          secondaryText="No, I will use the incomplete document"
          className="incompleteDocumentModal"
          openModal={showIncompleteDocModal}
          buttonText="Yes, I want the complete Document"
          setOpenModal={setShowIncompleteDocModal}
          icon={<IoWarning color="orange" />}
          loading={perDocumentLoading}
          onClick={() => buyDocument()}
          secondaryOnClick={() => setShowIncompleteDocModal(false)}
          // loading={deleteLoading}
        >
          <div className="incompleteDocModalContent">
            <div className="incompleteDocPricingBenefits">
              <h1 className="incompleteTitle">Upgrade to access</h1>

              <div className="incompleteDocPricingBenefitsList">
                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Customised to your requirements</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Compliance with relevant laws</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Unlimited Documents (Pro)</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Unlimited Revisions</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Professional Lawyer Review</p>
                </div>

                <div>
                  <IoMdCheckmark className="benefitsIcon" />
                  <p>Save lots of $$$</p>
                </div>
              </div>
            </div>

            <div className="incompleteDocTable">
              <div
                className="eachPricingTableSection"
                style={{
                  background: "#191f2f04",
                }}
              >
                <div
                  className="eachPricingTableHeader"
                  style={{ boxShadow: "none" }}
                >
                  <p
                    className="eachPricingTableType"
                    style={{ marginTop: "35px" }}
                  >
                    Free (current plan)
                  </p>
                  <h1 className="eachPricingTablePrice">$0</h1>
                </div>

                {/* <div className="eachPricingBenefits">
                  <p className="featureNotAvailable">
                    Customised to requirements
                  </p>
                  <p className="featureNotAvailable">Compliant with the laws</p>
                  <p className="featureNotAvailable">Unlimited Documents</p>
                  <p className="featureNotAvailable">Unlimited Revisions</p>
                  <p className="featureNotAvailable">
                    Minimal Lawyer Intervention
                  </p>
                  <p className="featureNotAvailable">
                    Professional Lawyer Review
                  </p>
                  <p>✅ Sample document</p>
                </div> */}
              </div>
              <div
                className="eachPricingTableSection"
                style={{
                  backgroundColor: "#4f37e815",
                  outline: "4px solid #4f37e810",
                  border: "1px solid #4f37e8",
                }}
              >
                <div
                  className="eachPricingTableHeader"
                  // style={{ background: "#191f2f12" }}
                >
                  <div className="eachPricingTableLabel">Most Popular 🎉</div>

                  <p className="eachPricingTableType">One-time</p>
                  <h1
                    className="eachPricingTablePrice"
                    // style={{ fontWeight: "700" }}
                  >
                    $30
                    <span style={{ fontSize: "24px", opacity: 0.5 }}>
                      /document
                    </span>
                  </h1>

                  {/* <p>✅ Customised to requirements </p>
                  <p>✅ Compliant with the laws </p>
                  <p className="featureNotAvailable">Unlimited Documents</p>
                  <p>✅ Unlimited Revisions</p>
                  <p>✅ Minimal Lawyer Intervention</p>
                  <p>✅ Professional Lawyer Review</p>
                  <p className="featureNotAvailable">Sample document</p> */}

                  <button
                    className="ButtonMain"
                    style={{
                      fontSize: "13px",
                      background: "#4f37e8",
                      width: "87%",
                    }}
                    disabled={perDocumentLoading}
                    onClick={() => buyDocument()}
                  >
                    {perDocumentLoading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <p> Get complete document</p>
                    )}
                  </button>
                </div>
              </div>
              <div className="eachPricingTableSection">
                <div className="eachPricingTableHeader">
                  <p
                    className="eachPricingTableType"
                    style={{ marginTop: "35px" }}
                  >
                    Pro
                  </p>
                  <h1 className="eachPricingTablePrice">
                    $50
                    <span style={{ fontSize: "24px", opacity: 0.5 }}>
                      /month
                    </span>
                  </h1>

                  {/* <p>✅ Customised to requirements</p>
                  <p>✅ Compliant with the laws</p>
                  <p>✅ Unlimited Documents</p>
                  <p>✅ Unlimited Revisions</p>
                  <p>✅ Minimal Lawyer Intervention</p>
                  <p>✅ Professional Lawyer Review</p>
                  <p className="featureNotAvailable">Sample document</p> */}

                  <button
                    disabled={purchaseLoading}
                    className="ButtonSecondary"
                    style={{ background: "#191f2f05", width: "87%" }}
                    onClick={() => generatePaymentLink()}
                  >
                    {purchaseLoading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      "Upgrade to Pro"
                    )}
                  </button>
                </div>
              </div>
            </div>

            <p className="incompleteDocModalText">
              The document you have cannot be safely or professionaly used as
              you are on the free plan. Get the full document to get the
              complete, compliant version to confidently use it.{" "}
              <a target="_blank" href="https://useairstrip.com/pricing">
                Compare plans
              </a>
            </p>
          </div>
        </RadixModal>

        <div
          className="finalDocumentViewerContainer"
          id="finalDocumentViewerContainer"
        >
          {/* {JSON.stringify(documentInfo)} */}
          {/* {JSON.stringify(currentSelectedSignature)} */}

          <div id="document-builder">
            <animated.div
              style={documentViewerProps}
              className="finalDocumentViewer"
              id="finalDocumentViewer"
              contentEditable="true"
              onInput={() => {
                // alert("here");
                if (documentInfo?.document_drafts?.length !== 0) {
                  dispatch(
                    updateDocument({
                      unsavedChanges: true,
                    })
                  );
                }
              }}
            >
              {documentInfo && (
                <div className="versionsContainer" contentEditable="false">
                  <TbVersions
                    className="versionsIcon"
                    onClick={() => {
                      setShowVersions(true);
                    }}
                  />

                  {showVersions && (
                    <div className="versionsList">
                      <div className="versionsListHeader">
                        <p className="versionsListHeaderText">Versions</p>
                        <IoClose
                          className="closeVersionsListIcon"
                          onClick={() => setShowVersions(false)}
                        />
                      </div>

                      {documentInfo?.document_drafts.map(
                        (eachVersion, index) => (
                          <div
                            className={`eachVersionContainer ${
                              (index === parseInt(versionId) - 1 ||
                                (!versionId &&
                                  index ===
                                    documentInfo?.document_drafts?.length -
                                      1)) &&
                              "currentVersionContainer"
                            }`}
                            onClick={() => {
                              // showDocumentVersion(index);
                              window.location.pathname = `/document/${id}/draft/${
                                index + 1
                              }`;
                            }}
                            // to={`/document/${id}/draft/${index + 1}`}
                          >
                            <MdUpdate className="versionsIcon eachVersionIcon" />
                            <p className="eachVersionDate">
                              {/* {formatDateAndTime(eachVersion)} */}
                              Version {index + 1}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}

              {unsavedChanges && (
                <p
                  className="unsavedChangesTextContainer"
                  contentEditable="false"
                >
                  <BsInfoCircleFill className="unsavedChangesIcon" />
                  <div className="unsavedChangesText">Unsaved Changes</div>
                </p>
              )}

              {/* {JSON.stringify(documentElementsArray)} */}

              <div
                className="documentContent"
                contentEditable="true"
                defaultValue=""
              >
                {documentLoading && (
                  <div className="documentViewerLoadingContainer">
                    <DocumentViewerLoader text="Loading Document" />
                  </div>
                )}
                {/* {JSON.stringify(documentElementsArray)} */}
                {/* <h1
                  className="finalDocumentViewerHeading"
                  // id={eachElement.id}
                >
                  Cloud Service Agreement with Netflix, Inc.
                </h1> */}
                {documentElementsArray?.map((eachElement) => {
                  if (
                    eachElement.type === "TITLE" ||
                    eachElement.type === "HEADER"
                  ) {
                    return (
                      <>
                        <h1
                          className="finalDocumentViewerHeading"
                          id={eachElement.id}
                        >
                          {eachElement.text}
                        </h1>
                        {/* here{JSON.stringify(updatingElementId)} */}
                      </>
                    );
                  } else if (eachElement.type === "SUBTITLE") {
                    return (
                      <>
                        <p
                          className="finalDocumentViewerSubHeading"
                          id={eachElement.id}
                        >
                          {eachElement.text}
                        </p>

                        {updatingElementInfo?.ID === eachElement.id && (
                          <div
                            className="skeletonLoader"
                            style={{
                              width: "700px",
                              margin: "auto",
                              padding: "0px 0px",
                            }}
                            id="simplifyDocumentLoader"
                          >
                            <div className="skeletonLoaderElement skeletonHeader"></div>
                            <div className="skeletonParagraphGroup">
                              <div className="skeletonLoaderElement skeletonParagraph"></div>
                              <div className="skeletonLoaderElement skeletonParagraph"></div>
                              <div className="skeletonLoaderElement skeletonParagraph"></div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      {/* <p>{eachElement.id}</p>
                      <p>{updatingElementInfo?.ID}</p> */}
                      {/* {} */}
                      {eachElement.id === updatingElementInfo?.ID ? (
                        <div
                          className="skeletonLoader"
                          style={{
                            width: "700px",
                            margin: "auto",
                            padding: "0px 0px",
                          }}
                          id="simplifyDocumentLoader"
                        >
                          <div className="skeletonLoaderElement skeletonHeader"></div>
                          <div className="skeletonParagraphGroup">
                            <div className="skeletonLoaderElement skeletonParagraph"></div>
                            <div className="skeletonLoaderElement skeletonParagraph"></div>
                            <div className="skeletonLoaderElement skeletonParagraph"></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          {!isParagraphBetweenHeaders(
                            eachElement.id,
                            updatingElementInfo?.ID
                          ) && (
                            <p
                              className="finalDocumentViewerParagraph"
                              id={eachElement.id}
                            >
                              {eachElement.text}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </animated.div>

            {streamStatus === "PENDING" && (
              <div
                className="skeletonLoader"
                style={{ width: "700px", margin: "auto", padding: "0px 0px" }}
                id="simplifyDocumentLoader"
              >
                <div className="skeletonLoaderElement skeletonHeader"></div>
                <div className="skeletonParagraphGroup">
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                  <div className="skeletonLoaderElement skeletonParagraph"></div>
                </div>
              </div>
            )}

            {pdfData && <PdfViewer pdfUrl={pdfData} />}

            {/* <div
              className="finalDocumentViewer"
              id="finalDocumentViewer"
              contentEditable="true"
            >
              

              <div
                className="documentContent"
                id="documentContent"
                style={{ opacity: showVersions ? 0.6 : 1 }}
              >
                {documentAsArray.map((eachElement) => {
                  if (eachElement.type === "TITLE") {
                    return (
                      <h1
                        className="finalDocumentViewerHeading"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </h1>
                    );
                  } else if (eachElement.type === "SUBTITLE") {
                    return (
                      <p
                        className="finalDocumentViewerSubHeading"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </p>
                    );
                  } else if (eachElement.type === "PARAGRAPH") {
                    return (
                      <p
                        className="finalDocumentViewerParagraph"
                        id={eachElement.id}
                      >
                        {eachElement.text}
                      </p>
                    );
                  }
                })}
              </div>
            </div> */}

            {/* <div
            className="finalDocumentViewer"
            id="finalDocumentViewer1"
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => handleSignatureDrop(event)}
          ></div>

          <div
            className="finalDocumentViewer"
            id="finalDocumentViewer1"
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => handleSignatureDrop(event)}
          ></div> */}
          </div>
        </div>
      </div>
    </>
  );
};
