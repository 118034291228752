import axios from "axios";
import store from "../redux/store";

const reduxStore = store().store;

export const getToken = async () => {
  const rootState = reduxStore.getState();

  const token = JSON.parse(await localStorage.getItem("persist:root"));

  return JSON.parse(token?.user).token ?? rootState.user.token;
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OTE1NTJhOWVmNzE1MTUwY2RhYjIwMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTY4NzkzMjMxOX0.bD-IwMxgiz0VbRuV-8I8vQW4B4V7oK5AlrI9EcOsiPY";
};

reduxStore.subscribe(getToken);

const getBaseURL = () => {
  const devUrl =
    process.env.DEV_API_URL || "https://stark-dev-cd38a2ba8865.herokuapp.com/";
  const prodUrl =
    process.env.PROD_API_URL || "https://stark-dev-cd38a2ba8865.herokuapp.com/";

  // return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  //   ? devUrl
  //   : prodUrl;

  return (
    process.env.REACT_APP_API_URL ||
    "https://stark-dev-cd38a2ba8865.herokuapp.com/"
  );
};

const api = axios.create({
  baseURL: getBaseURL(),
  headers: {
    "Content-Type": "application/json",
    api_key: "krishna",
  },
  withCredentials: false,
});

api.interceptors.request.use(async (req) => {
  const authToken = await getToken();

  req.headers.Authorization = `${authToken}`;

  return req;
});

export default api;
