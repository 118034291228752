export const UPDATE_CHAT = "UPDATE_CHAT";

const initialState = {
  chatOpen: false,
  defaultState: null,
  chatContext: null,
  allChats: null,
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHAT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
