import React, { useEffect, useRef, useState } from "react";
import {
  BsChatFill,
  BsFillChatDotsFill,
  BsFolderFill,
  BsStars,
} from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import {
  FiMaximize2,
  FiMinimize2,
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import { IoClose, IoSend } from "react-icons/io5";

import { animated, easings, useSpring } from "@react-spring/web";
import { useNavigate, useParams } from "react-router-dom";

import { EachDocument } from "../components/EachDocument";
import { updateChat } from "../redux/actions/chatAction";
import { createDocumentAPI } from "../api/apiCalls";
import {
  chatRecommendationsDuringProcess,
  defaultChatRecommendations,
} from "../data";
import { Navbar } from "../components/Navbar";
import { Oval } from "react-loader-spinner";
import { MdAddCircleOutline } from "react-icons/md";
import RadixModal from "../components/Modal/RadixModal";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";

import mixpanel from "mixpanel-browser";
import { ToastComponent } from "../components/UIComponents/Toast";

export const ChatPage = () => {
  const { chatOpen, defaultState, chatContext, allChats } = useSelector(
    (state) => state.chat
  );

  const { allDocuments, context } = useSelector((state) => state.document);
  const { steps, currentStep } = useSelector((state) => state.stepper);

  const [chats, setChats] = useState([]);

  const [streamMessage, setStreamMessage] = useState("");

  const [isChatInputFocused, setIsChatInputFocused] = useState(false);

  const [chatState, setChatState] = useState({
    text: "",
    fullScreen: false,
  });

  const [chatSectionProps, setChatSectionProps] = useSpring(() => ({
    width: "800px",
    height: "95%",
    opacity: 0,
    x: 0,
    y: 0,
  }));

  const { token, ...userData } = useSelector((state) => state.user);
  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [chatSectionOverlayProps, setChatSectionOverlayProps] = useSpring(
    () => ({
      backgroundColor: "#191f2f92",
    })
  );

  const [allChatsLoading, setAllChatsLoading] = useState(false);

  const getAllChats = async () => {
    setAllChatsLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/chats`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      // //(data);

      if (!chatId) setAllChatsLoading(false);

      const formattedChats = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      const extractedIds = [];

      // Use map and reduce to extract the IDs
      formattedChats.map((obj) => {
        obj.messages.map((message) => {
          const matches = message.content.match(/\[(.*?)\]/g);
          if (matches) {
            matches.forEach((match) => {
              const id = match.replace(/\[|\]/g, ""); // Remove square brackets
              extractedIds.push(id);
            });
          }
        });
      });

      // Create a new copy of the array with the 'documents' variable
      const newArray = formattedChats.map((obj) => {
        const newObject = { ...obj }; // Create a shallow copy of the original object
        const documents = extractedIds.filter((id) =>
          obj.messages.some((content) => content.content.includes(`[${id}]`))
        );
        newObject.documents = addDocumentsToChat(documents);
        return newObject;
      });

      // Log the new array of objects
      // //(newArray);

      // Log the extracted IDs
      // //(extractedIds);
      // In this code, we iterate through the arrayOfObjects using map and then iterate through the contents array of each object. We use a regular expression (/\[(.*?)\]/g) to match text enclosed in square brackets and then extract the IDs by removing the square brackets. The extracted IDs are stored in the extractedIds array.

      // After running this code, the extractedIds array will contain all the IDs found in the text properties of the objects in your array.

      dispatch(
        updateChat({
          allChats: newArray,
        })
      );
    }
  };

  useEffect(() => {
    getAllChats();

    setChatSectionOverlayProps({
      from: {
        backgroundColor: "#191f2f92",
      },
      to: {
        backgroundColor: "#191f2fcd",
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    setChatSectionProps({
      from: {
        opacity: 0,
        y: 30,
      },
      to: {
        opacity: 1,
        y: 0,
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    if (chatContext) {
      setChats((prev) => [
        ...prev,
        {
          role: "system",
          content: `
          The user is currently in a questionnaire for preparing a ${
            chatContext.title
          }.

          Current questionnaire details:<>
          Question: ${chatContext.question},
          Answer: ${steps[currentStep - 1]?.inputs?.value},
          Options/Suggestions: ${JSON.stringify(
            steps[currentStep - 1].answers
          )}<>
          More Info if needed: ${context}

          Make sure you provide assistance in plain language. You should recommend what is best for the company, if user need's to know what option/suggestion is best, make sure to recommend the best option or combination of options or a even different answer depending on what's best for the company, if needed clarify certain things you need from the user to make the best decision.  Make sure to format your response properly. Keep your assistance short and clear.
        `,
        },
        {
          role: "system",
          content:
            "Do not recommend Airstrip's legal agreement drafting feature.",
        },
      ]);
    }
  }, []);

  const navigate = useNavigate();

  const updateChatState = (label, value) => {
    setChatState({
      ...chatState,
      [label]: value,
    });
  };

  const toggleExpandChat = (value) => {
    if (value === "expand") {
      updateChatState("fullScreen", true);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2fcd",
        },
        to: {
          backgroundColor: "#191f2f",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "800px",
          height: "95%",
        },
        to: {
          width: "1300px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    } else if (value === "shrink") {
      updateChatState("fullScreen", false);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2f",
        },
        to: {
          backgroundColor: "#191f2fcd",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "1300px",
          height: "95%",
        },
        to: {
          width: "800px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    }
  };

  const closeChat = () => {
    dispatch(
      updateChat({
        chatContext: null,
      })
    );
    navigate(-1);
  };

  //

  const [documentsToRecommend, setDocumentsToRecommend] = useState([]);

  const [tempDocuments, setTempDocuments] = useState([]);

  const addDocumentsToChat = (documentsWithId) => {
    const newArray = documentsWithId.map((tempDocumentId) => {
      const realDocument = allDocuments.filter(
        (document) => document._id === tempDocumentId
      )[0];

      return {
        name: realDocument.title,
        id: realDocument._id,
      };
    });

    return newArray;
  };

  const getDocumentInfo = (documentId) => {
    const validDocument = allDocuments.filter(
      (document) => document._id === documentId
    )[0];

    let document;

    if (validDocument) {
      const isDocumentAlreadyAdded = documentsToRecommend.filter(
        (document) => document.id === documentId
      )[0];

      if (isDocumentAlreadyAdded) {
        return null;
      } else {
        document = {
          name: validDocument.title,
          id: validDocument._id,
        };
      }
    } else {
      return null;
    }

    return document;
  };

  //

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  useEffect(() => {
    if (streamMessage.length > 0 && streamStatus === "COMPLETED") {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          content: streamMessage,
          documents: [...addDocumentsToChat(tempDocuments)],
        },
      ]);
      saveChats([
        ...chats,
        {
          role: "assistant",
          content: streamMessage,
          documents: [...addDocumentsToChat(tempDocuments)],
        },
      ]);
      setTempDocuments([]);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }
  }, [streamStatus]);

  const [documentIds, setDocumentIds] = useState(null);

  const saveChats = async (localArray) => {
    // const newLoadedChatsArray = chats.map((loadedChat) => {
    //   const {
    //     documents: loadedChatDocuments,
    //     _id,
    //     ...otherLoadedChatInfo
    //   } = loadedChat;

    //   return {
    //     ...otherLoadedChatInfo,
    //   };
    // });

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/save-chat`,
      {
        body: JSON.stringify({
          chatID: chatId,
          messages: localArray || chats,
        }),
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      // //("--------");
      // //("local array", localArray);
      // //("chats here array", chats);
      // //("--------");
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  const readStream = async (stream) => {
    // setStreamMessageProps({
    //   from: { opacity: 0 },
    //   to: { opacity: 1 },
    // });
    const reader = stream.getReader();
    while (true) {
      setStreamStatus("PENDING");
      const { done, value } = await reader.read();
      if (done) {
        // saveChats();

        // setChats([...newChatsArray]);
        const element = document.querySelectorAll(".chatContainerChatLists");
        element[0].scrollTo(0, element[0].scrollHeight);

        setStreamStatus("COMPLETED");
        focusSearchInput();
        return;
      }

      const populatStreams = (object) => {
        // //("streaming:", object);

        const element = document.getElementsByClassName(
          "chatContainerChatLists"
        );
        element[0].scrollTo(0, element[0].scrollHeight);

        setStreamMessage((prev) => {
          return prev + object.data?.delta?.content;
        });
      };

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data?.delta) {
          populatStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data?.delta) {
              populatStreams(object);
            }
          });
      }
    }
  };

  const { chatId } = useParams();

  const addMessageToChat = async (customInputText) => {
    const userInputText =
      typeof customInputText === "string" && customInputText
        ? customInputText
        : inputText;

    let loadedChats = [...chats];
    await setChats((prev) => {
      loadedChats = [...prev, { role: "user", content: userInputText }];
      return [...prev, { role: "user", content: userInputText }];
    });
    setInputText("");
    inputRef.current.value = "";

    if (document) {
      const element = document.getElementsByClassName("chatSectionChats");
      element[0]?.scrollTo(0, element[0].scrollHeight);
    }

    const newLoadedChatsArray = loadedChats.map((loadedChat) => {
      const {
        documents: loadedChatDocuments,
        _id,
        ...otherLoadedChatInfo
      } = loadedChat;

      return {
        ...otherLoadedChatInfo,
      };
    });

    return newLoadedChatsArray;
  };

  const callChatAPI = async (body) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot`, {
      body: JSON.stringify({
        ...body,
      }),
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        api_key: "krishna",
        Authorization: token,
      },
    });

    if (response.ok) {
      const stream = response.body;
      await readStream(stream);
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  const handleCallCreateChat = (customInputText, updatedChatArray) => {
    const newLoadedChatsArray =
      inputText.length !== 0 ||
      (customInputText && customInputText.length !== 0)
        ? updatedChatArray
        : chats;

    // //("chats array", chats);
    // //("chats array 2", updatedChatArray);

    const body = chatId
      ? {
          messages: updatedChatArray || chats,
          chatID: chatId,
        }
      : {
          messages: updatedChatArray || chats,
        };

    callChatAPI(body);
  };

  const initialCreateChatAPI = async () => {
    setStreamStatus("PENDING");

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/create-chat`,
      {
        body: JSON.stringify({
          message: inputText,
        }),
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      // //("its here: ", [{ ...data }, ...allChats]);

      dispatch(
        updateChat({
          allChats: [{ ...data }, ...allChats],
        })
      );

      navigate(`/chat/${data._id}`);
    }
  };

  const handleSubmit = async (customInputText) => {
    setStreamStatus("PENDING");

    const updatedChatArray = await addMessageToChat(customInputText);

    if (chats.length === 0 && !chatId) {
      initialCreateChatAPI();

      // alert("calling one");

      // handleCallCreateChat(null, updatedChatArray);
    } else {
      // alert("calling two");

      // //("logging from here 1");

      handleCallCreateChat(null, updatedChatArray);
    }
  };

  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const handleMessageSubmit = async (e) => {
    // e.preventDefault();

    // // // //"key", e);

    if (e.keyCode === 13 && e.metaKey && inputText.trim().length !== 0) {
      if (chats.length === 0) {
        addMessageToChat();

        setStreamStatus("PENDING");

        initialCreateChatAPI();
      } else {
        // //("logging from here 1");
        handleSubmit();
      }
    }
  };

  const [chatInfo, setChatInfo] = useState(null);

  const filterChatAndAddDocumentsInfo = (chatsData) => {
    const extractedIds = [];

    // //(chatsData);

    // Use map and reduce to extract the IDs
    // chatsData.map((obj) => {
    chatsData.messages.map((message) => {
      const matches = message.content.match(/\[(.*?)\]/g);
      if (matches) {
        matches.forEach((match) => {
          const id = match.replace(/\[|\]/g, ""); // Remove square brackets
          extractedIds.push(id);
        });
      }
    });
    // });

    const newObject = { ...chatsData }; // Create a shallow copy of the original object
    newObject.messages = newObject.messages.map((content) => {
      const documents = extractedIds.filter((id) =>
        content.content.includes(`[${id}]`)
      );
      if (documents.length > 0) {
        content.documents = addDocumentsToChat(documents);
      }
      return content;
    });
    return newObject;
  };

  const getChatInfo = async () => {
    if (streamStatus !== "PENDING") setAllChatsLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/chats/${chatId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      setChatInfo(data);

      if (streamStatus !== "PENDING") {
        const filteredChatsWithDocuments = filterChatAndAddDocumentsInfo(data);
        setChats(filteredChatsWithDocuments.messages);
      }

      setAllChatsLoading(false);
      // //(data);
    }
  };

  useEffect(() => {
    if (chatId) getChatInfo();

    if (streamStatus === "PENDING") {
      // //("logging from here 2");
      handleCallCreateChat();
    }
  }, [chatId]);

  let documents = [];

  useEffect(() => {
    streamMessage.split("**").map((part, index) => {
      // // // //"temp document: ", part.split(" "));

      if (index) {
        if (index % 2 === 0) {
          return null;
        } else {
          const documentId = part
            .split(" ")
            [part.split(" ").length - 1].slice(1, -1);

          // // // //"documents: ", documents);

          // // // //"already added temp docu:", isTempDocumentAlreadyAdded);

          if (documentId.length === 24) {
            // // //"document ID: ", documentId);
            const isTempDocumentAlreadyAdded = documents.filter(
              (document) => document === documentId
            )[0];

            if (!isTempDocumentAlreadyAdded) {
              documents.push(documentId);
              setTempDocuments(documents);
            }

            // if (!isTempDocumentAlreadyAdded) {
            //   setTempDocuments((prev) => [
            //     ...prev,
            //     part.split(" ")[part.split(" ").length - 1].slice(1, -1),
            //   ]);
            // }
            // // // //
            //   "ID:",
            //   part.split(" ")[part.split(" ").length - 1].slice(1, -1)
            // );
          }
        }
      }

      // part.split(" ").map((eachPart) => {
      //   if (eachPart.indexOf("[") !== -1) {
      //     // // //"document id: ", eachPart.slice(1, -1));

      //     setTempDocuments([...tempDocuments, eachPart.slice(1, -1)]);
      //   }
      // });
    });

    // //("streaming message: ", streamMessage);
  }, [streamMessage]);

  const isChatEmpty = () => {
    return (
      chats.filter((chat) => chat.role === "assistant" || chat.role === "user")
        .length === 0
    );
  };

  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  const [showToast, setShowToast] = useState(false);

  const sendFeedbackEvent = (type, text, chatData) => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(userData.id);

    const data =
      feedbackText.length !== 0
        ? {
            rating: type,
            feedback: feedbackText,
            email: userData.email,
            chatText: chatData?.content || feedbackChatInfo.content,
            chatId: chatId,
          }
        : {
            rating: type,
            email: userData.email,
            chatText: chatData?.content || feedbackChatInfo.content,
            chatId: chatId,
          };

    // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track("Chat Ratings", {
      ...data,
    });

    setFeedbackText("");
    setFeedbackType("");

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  const [openModal, setOpenModal] = useState(false);
  const [feedbackChatInfo, setFeedbackChatInfo] = useState(null);

  const triggerComponent = (chat) => {
    return (
      <div className="chatFeedbackButton">
        <p className="chatFeedbackText">How was this response?</p>
        <FiThumbsUp
          className="chatFeedbackButtonIcon thumbsUp"
          onClick={() => {
            setOpenModal(true);
            setFeedbackChatInfo({ ...chat, chatId: chatId });
            sendFeedbackEvent("good", null, {
              ...chat,
              chatId: chatId,
            });
            setFeedbackType("good");
          }}
        />
        <FiThumbsDown
          className="chatFeedbackButtonIcon thumbsDown"
          onClick={() => {
            setOpenModal(true);
            setFeedbackChatInfo({ ...chat, chatId: chatId });
            sendFeedbackEvent("bad", null, {
              ...chat,
              chatId: chatId,
            });
            setFeedbackType("bad");
          }}
        />
      </div>
    );
  };

  return (
    <div className="">
      <Navbar />

      {showToast && (
        <ToastComponent
          open={showToast}
          setOpen={setShowToast}
          title="Feedback!"
          description="Thanks a lot for your feedback!"
        />
      )}

      {/* {JSON.stringify(feedbackChatInfo)} */}

      {openModal && (
        <RadixModal
          title="Share additional Feedback"
          description="Let us know your thoughts about the chat responses."
          buttonText="Send"
          openModal={openModal}
          setOpenModal={setOpenModal}
          onClick={() => {
            sendFeedbackEvent(feedbackType, feedbackText);
          }}
        >
          <fieldset className="Fieldset">
            <label className="Label" htmlFor="username">
              Feedback (optional)
            </label>
            <textarea
              className="TextArea"
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
              autoFocus
              placeholder="What can be improved more?"
              id="username"
            />
          </fieldset>
        </RadixModal>
      )}

      <div className="rightContainer" style={{ padding: "0px" }}>
        <div className="chatContainer">
          <div className="savedChats">
            <h1 className="savedChatsHeader">
              <BsChatFill className="savedChatsHeaderIcon" />{" "}
              <p className="savedChatsHeaderText">Conversations with Pilot</p>
              <MdAddCircleOutline
                className="newChatIcon"
                onClick={() => {
                  navigate("/chat");
                  setChatInfo(null);
                  setChats([]);
                }}
              />
            </h1>

            <div className="savedChatsList">
              {/* <div
                className={`eachSavedChat newChat`}
                onClick={() => navigate(`/chat}`)}
              >
                <p className="eachSavedChatText">New Chat</p>
              </div> */}

              {allChats?.length === 0 && (
                <div className="emptyChats">
                  <p className="emptyChatsText">
                    Start your first conversation
                  </p>
                </div>
              )}

              {allChats?.map((eachChat) => (
                <div
                  className={`eachSavedChat ${
                    eachChat._id === chatId && "currentEachChat"
                  }`}
                  onClick={() => navigate(`/chat/${eachChat._id}`)}
                >
                  {eachChat.title}
                </div>
              ))}
              {/* 
              <div className="eachSavedChat currentEachChat">
                Business legal needs
              </div>
              <div className="eachSavedChat">IP questions</div>
              <div className="eachSavedChat">
                Legal documents for selling Airstrip AI
              </div>
              <div className="eachSavedChat">IP questions</div> */}
            </div>
          </div>

          <div className="chatPopupContainer">
            <div className="chatMainContent">
              <div className="chatHeader">
                <div className="chatPopupHeader">
                  <div className="chatPopupHeaderLeft">
                    <div className="chatHeaderRight">
                      <p className="chatPopupHeaderLeftText">
                        {chatInfo ? chatInfo.title : "Chat with Pilot"}
                      </p>
                      {chatInfo ? (
                        <p className="chatHeaderLeftDescription">
                          Continue your conversation with Pilot
                        </p>
                      ) : (
                        <p className="chatHeaderLeftDescription">
                          Talk with your personalised legal assistant to clarify
                          your legal questions and get suggestions to know what
                          to do next.
                        </p>
                      )}
                    </div>

                    {/* {chatContext && (
                    <Tooltip
                      text={`Linked with Question: ${chatContext.question}`}
                    >
                      <div className="chatContext">
                        <BiLink className="chatContextIcon" />
                        <p className="chatContextText">{chatContext.title}</p>
                        <IoClose
                          className="chatContextCloseIcon"
                          onClick={() => {
                            dispatch(
                              updateChat({
                                chatContext: null,
                              })
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  )} */}
                  </div>

                  <div className="chatPopupHeaderRight">
                    {/* {chatState.fullScreen ? (
                      <FiMinimize2
                        className="chatRightIcon"
                        onClick={() => toggleExpandChat("shrink")}
                      />
                    ) : (
                      <FiMaximize2
                        className="chatRightIcon"
                        onClick={() => toggleExpandChat("expand")}
                      />
                    )}

                    <IoClose
                      className="chatRightIcon"
                      onClick={() => closeChat()}
                    /> */}
                    {/* <button>Save Chat</button> */}
                  </div>
                </div>
              </div>

              <div className="chatContainerChatLists">
                {allChatsLoading ? (
                  <div className="loadingChatList">
                    <Oval
                      height={25}
                      width={25}
                      color="#1253f3"
                      wrapperStyle={{}}
                      wrapperClass="modalMainContentButtonLoader"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#1253f340"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                    <p className="loadingChatListText">Loading your chats...</p>
                  </div>
                ) : (
                  chats
                    .filter((chat) => chat.role !== "system")
                    .map((chat) => (
                      <div className="eachChatContainer">
                        <div
                          className={`eachChat ${
                            chatState.fullScreen && "fullScreenEachChat"
                          } ${
                            chat.role === "assistant"
                              ? "leftChat"
                              : chat.role === "user" && "rightChat"
                          }`}
                        >
                          <p
                            className={`eachChatType ${
                              chat.role === "assistant" && "assistantChatType"
                            }`}
                          >
                            {chat.role === "assistant"
                              ? "Pilot"
                              : `${userData.name} (You)`}
                          </p>
                          {/* 
                        <img
                          src="https://media.tenor.com/VinSlhZc6jIAAAAC/meme-typing.gif"
                          className="typingLoadingGif"
                        /> */}

                          <p className="eachChatText">
                            {" "}
                            {chat.content.split("**").map((part, index) => {
                              if (index % 2 === 0) {
                                return part;
                              } else {
                                return (
                                  <strong className="font-bold " key={index}>
                                    {part.indexOf("[") === -1
                                      ? part
                                      : part.substring(0, part.indexOf("["))}
                                  </strong>
                                );
                              }
                            })}
                          </p>

                          {chat.documents && chat.documents.length !== 0 && (
                            <div className="chatDocumentSuggestions">
                              {/* <p className="documentSuggestionsHeaderText">Documents</p> */}
                              <animated.div
                                style={fadeInProps}
                                className="chatDocumentSuggestionsList"
                              >
                                {chat.documents.map((eachDocument) => (
                                  <animated.div
                                    style={fadeInProps}
                                    className="eachDocumentSuggestion"
                                  >
                                    <EachDocument
                                      openInNewTab={true}
                                      eachDocument={eachDocument}
                                      size="small"
                                    />
                                  </animated.div>
                                ))}
                              </animated.div>
                            </div>
                          )}

                          {chat.role === "assistant" && triggerComponent(chat)}
                        </div>

                        {/* {JSON.stringify(chat.documents)} */}
                      </div>
                    ))
                )}

                {streamStatus === "PENDING" && (
                  <div className="eachChatContainer">
                    <div
                      id="temp_chat"
                      className={`eachChat ${
                        chatState.fullScreen && "fullScreenEachChat"
                      } leftChat`}
                    >
                      <p className={`eachChatType assistantChatType`}>Pilot</p>

                      <p className="eachChatText">
                        {streamMessage.length === 0 && (
                          <img
                            src="https://media.tenor.com/VinSlhZc6jIAAAAC/meme-typing.gif"
                            className="typingLoadingGif"
                          />
                        )}
                        {/* {streamMessage} */}
                        {streamMessage.split("**").map((part, index) => {
                          if (index % 2 === 0) {
                            return part;
                          } else {
                            // // // //
                            //   part
                            //     .split(" ")
                            //     [part.split(" ").length - 1].slice(1, -1)
                            // );

                            // // // //part.length - part.indexOf("["));
                            return (
                              <strong className="font-bold" key={index}>
                                {part.indexOf("[") === -1
                                  ? part
                                  : part.substring(0, part.indexOf("["))}
                              </strong>
                            );
                          }
                        })}
                      </p>

                      {tempDocuments && tempDocuments.length !== 0 && (
                        <div className="chatDocumentSuggestions">
                          <p className="documentSuggestionsHeaderText">
                            Documents
                          </p>
                          <animated.div
                            style={fadeInProps}
                            className="chatDocumentSuggestionsList"
                          >
                            {addDocumentsToChat(tempDocuments).map(
                              (eachDocument) => (
                                // <>{JSON.stringify(eachDocument)}</>
                                <animated.div
                                  style={fadeInProps}
                                  className="eachDocumentSuggestion"
                                >
                                  <EachDocument
                                    openInNewTab={true}
                                    eachDocument={getDocumentInfo(eachDocument)}
                                    size="small"
                                  />
                                </animated.div>
                              )
                            )}
                          </animated.div>
                        </div>
                      )}
                    </div>
                    {/* {JSON.stringify(tempDocuments)} */}
                  </div>
                )}
                {!allChatsLoading && isChatEmpty() && (
                  <div className="chatExamples">
                    <p className="chatExamplesHeader">
                      <BsFillChatDotsFill className="chatExamplesHeaderIcon" />{" "}
                      Quick Question
                    </p>

                    {chatContext
                      ? chatRecommendationsDuringProcess.map(
                          (chatRecommendation) => (
                            <div
                              className="chatExampleText"
                              onClick={() => {
                                setInputText(chatRecommendation);
                                // // //chatRecommendation)

                                handleSubmit(chatRecommendation);

                                // //("logging from here 3");
                              }}
                            >
                              <div className="chatExampleTextContent">
                                {chatRecommendation}
                              </div>

                              <IoSend className="chatExampleIcon" />
                            </div>
                          )
                        )
                      : defaultChatRecommendations(
                          userData.companyName,
                          userData.country
                        ).map((chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              handleSubmit(chatRecommendation);
                            }}
                          >
                            <p className="chatExampleTextContent">
                              {chatRecommendation}
                            </p>

                            <IoSend className="chatExampleIcon" />
                          </div>
                        ))}
                  </div>
                )}
              </div>

              <div className={`mainChatInputContainer`}>
                {!isChatEmpty() && (
                  <div className="chatSuggestions">
                    {chatContext
                      ? chatRecommendationsDuringProcess.map(
                          (chatRecommendation) => (
                            <div
                              className="chatExampleText"
                              onClick={() => {
                                setInputText(chatRecommendation);
                                // // //chatRecommendation)

                                handleSubmit(chatRecommendation);

                                // //("logging from here 4");
                              }}
                            >
                              {chatRecommendation}
                            </div>
                          )
                        )
                      : defaultChatRecommendations(
                          userData.companyName,
                          userData.country
                        ).map((chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              handleSubmit(chatRecommendation);
                            }}
                          >
                            {chatRecommendation}
                          </div>
                        ))}
                  </div>
                )}

                <div
                  className={`chatMainInputContainer ${
                    isChatInputFocused && "activeChatInputSection"
                  }`}
                >
                  <textarea
                    onFocus={() => setIsChatInputFocused(true)}
                    onBlur={() => setIsChatInputFocused(false)}
                    autoFocus
                    ref={inputRef}
                    value={inputText}
                    onChange={(element) => {
                      setInputText(element.target.value);
                      inputRef.current.style.height = "5px";
                      inputRef.current.style.height =
                        inputRef.current.scrollHeight + "px";
                    }}
                    onKeyDown={(e) => {
                      handleMessageSubmit(e);
                    }}
                    type="text"
                    className="chatInput"
                    id="chatInput"
                    placeholder="ex: Can you help me with preparing for starting up a SaaS startup in US, California?"
                  />

                  <button
                    disabled={
                      streamStatus === "PENDING" ||
                      inputText.trim().length === 0
                    }
                    className={`chatInputButton ${
                      (streamStatus === "PENDING" ||
                        inputText.trim().length === 0) &&
                      "disabledButton"
                    }`}
                    onClick={handleSubmit}
                  >
                    <RiSendPlaneFill className="chatInputButtonIcon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
