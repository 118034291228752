import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import createSignImage from "../createSignImage.png";
import { Oval } from "react-loader-spinner";
import { getSignLinkAPI } from "../api/apiCalls";
import { BsArrowLeftShort, BsPatchCheckFill } from "react-icons/bs";
import { HiOutlineDownload } from "react-icons/hi";
import { downloadPDF } from "../functions";

import { MdError } from "react-icons/md";
export const CreateSignLink = () => {
  const { signId } = useParams();

  const [pdfLink, setPdfLink] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const [loadingPdfInfo, setLoadingPdfInfo] = useState(false);

  const navigate = useNavigate();

  const getSignLink = async () => {
    setLoadingPdfInfo(true);
    try {
      const response = await getSignLinkAPI(signId);
      if (response.data) {
        setLoadingPdfInfo(false);

        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          // // //response.data);

          // const decodedPdfLink = downloadPDF(response.data.pdf,)

          setPdfData(response.data);

          setPdfLink(response.data.pdf);
        }
      }
    } catch (error) {
      setLoadingPdfInfo(false);

      // // //error);
    }
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  useEffect(() => {
    if (signId) {
      // // //params.event);

      getSignLink();
    }
  }, []);

  const MainComponent = ({
    showLoading,
    image,
    headerText,
    description,
    children,
    Icon,
  }) => {
    return (
      <div
        className="signLinkContent"
        style={{
          animation: showLoading && "pulse 2s infinite",
        }}
      >
        {showLoading && (
          <Oval
            height={20}
            width={20}
            color="#1252f3"
            wrapperStyle={{}}
            wrapperClass="signLinkLoadingIcon"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1252f320"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
        )}

        {Icon ? <Icon /> : <img className="signLinkIcon" src={image} />}

        <p className="signLinkHeaderText">{headerText}</p>
        <p className="signLinkDescriptionText">{description}</p>

        {children}
      </div>
    );
  };

  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);

  const downloadPdf = () => {
    setDownloadPdfLoading(true);
    if (pdfLink) {
      downloadPDF(pdfLink, "Signed Document from Airstrip.pdf");
      setDownloadPdfLoading(false);
    }
  };

  useEffect(() => {
    if (downloadPdfLoading && pdfLink) {
      downloadPDF(pdfLink, "Signed Document from Airstrip.pdf");
      setDownloadPdfLoading(false);
    }
  }, [pdfLink, downloadPdfLoading]);

  return (
    <div className="signLinkContainer">
      {signId && !params.event && loadingPdfInfo ? (
        <MainComponent
          showLoading={true}
          headerText="Getting your document ready to sign."
          description="Once you add your signature, you will be redirected here and can
        download it as a pdf."
          image={createSignImage}
        ></MainComponent>
      ) : !pdfData ? (
        <Oval
          height={15}
          width={15}
          color="white"
          wrapperStyle={{}}
          wrapperClass="modalMainContentButtonLoader"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff7b"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      ) : params.event === "signing_complete" ? (
        <MainComponent
          showLoading={false}
          headerText="You have succesfully signed the document."
          description="You can download now or you can also view the document from your dashboard."
          image={createSignImage}
          Icon={() => <BsPatchCheckFill className="signCompleteIcon" />}
        >
          <div className="signSuccessButtons">
            <div
              className="signSuccessButton secondaryButton"
              onClick={() => {
                navigate(`/my-documents`);
              }}
            >
              <BsArrowLeftShort className="signSuccessButtonIcon" />
              <p className="signSuccessButtonText">Go to Documents</p>
            </div>

            <a
              onClick={() => downloadPdf()}
              className={`signSuccessButton primaryButton ${
                downloadPdfLoading && "disabledButton"
              }`}
            >
              {downloadPdfLoading && (
                <Oval
                  height={15}
                  width={15}
                  color="white"
                  wrapperStyle={{}}
                  wrapperClass="modalMainContentButtonLoader"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff7b"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}
              <HiOutlineDownload className="signSuccessButtonIcon" />
              <p className="signSuccessButtonText">Download as PDF</p>
            </a>
          </div>
        </MainComponent>
      ) : (
        (!pdfData || pdfData.isExpired) && (
          <MainComponent
            showLoading={false}
            headerText="Your link is expired."
            description="The link to sign your document has been expired. Contact the sender for verification and more info."
            image={createSignImage}
            Icon={() => (
              <MdError className="signCompleteIcon signCompleteIconRed" />
            )}
          ></MainComponent>
        )
      )}
    </div>
  );
};
