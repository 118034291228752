import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { EachDocument } from "../components/EachDocument";
import { documents } from "./App";
import { deleteDocumentDraftAPI, getMyDraftsAPI } from "../api/apiCalls";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import { HiDocument } from "react-icons/hi2";
import { updateUser } from "../redux/actions/userAction";
import { Modal } from "../components/Modal";
import { FiDelete } from "react-icons/fi";
import { RiDeleteBin2Fill } from "react-icons/ri";
import RadixModal from "../components/Modal/RadixModal";

export const MyDocuments = () => {
  const [drafts, setDrafts] = useState(null);

  const dispatch = useDispatch();

  const getMyDrafts = async () => {
    const response = await getMyDraftsAPI();

    setDrafts(response.data);

    // // //"drafts: ", response.data);
  };

  useEffect(() => {
    getMyDrafts();
  }, []);

  const [selectedId, setSelectedId] = useState("");

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteDocument = async (id) => {
    setDeleteLoading(true);

    const response = await deleteDocumentDraftAPI({ id: selectedId });

    if (response.data) {
      setDeleteLoading(false);
      setShowDeleteModal(false);
      setSelectedId("");

      getMyDrafts();
    }
  };

  const currentDocument = (id) => {
    return drafts.filter((draft) => draft._id === id)[0];
  };

  return (
    <div className="myDocumentsContainer">
      <Navbar />

      {showDeleteModal && (
        <RadixModal
          title="Permanent Delete"
          description={
            "This change cannot be reversed. Confirm if you want to proceed."
          }
          buttonText={"Yes, Permanently delete this Document"}
          openModal={showDeleteModal}
          setOpenModal={setShowDeleteModal}
          className="deleteDocumentModal"
          onClick={() => {
            deleteDocument();
          }}
          loading={deleteLoading}
        >
          <div className="deleteDocumentModalContainer">
            <p className="deleteDocumentText">
              Once you permanently delete{" "}
              <b>{currentDocument(selectedId).name}</b>, you will not able be
              able access this document again. Confirm if you want to proceed
              with this.
            </p>
          </div>
        </RadixModal>
      )}

      <div className="rightContainer">
        {/* <div className="myDocumentsHeader">
          <p className="myDocumentsHeaderText">Your Saved Documents</p>
          {drafts && (
            <p className="myDocumentsLengthCount">{drafts.length} Documents</p>
          )}
        </div> */}

        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Your Saved Documents</h1>
          <p className="rightContainerHeaderDescription">
            {drafts && (
              <p className="myDocumentsLengthCount">
                {drafts.length} Documents
              </p>
            )}
          </p>
        </div>

        {drafts ? (
          drafts.length === 0 ? (
            <div className="emptyMyDocuments">
              <HiDocument className="emptyDocumentsIcon" />
              <p className="emptyMyDocumentsHeader">
                Choose and create your document now
              </p>
              <p className="emptyMyDocumentsDescription">
                Select the document that you need, then start creating it for
                your use-cases.
              </p>
              <button
                onClick={() =>
                  dispatch(updateUser({ showDocumentsModal: true }))
                }
                className="emptyMyDocumentsButton"
              >
                Create Document ->
              </button>
            </div>
          ) : (
            <div className="myDocumentsList">
              {drafts.map((eachDocument) => (
                <EachDocument
                  eachDocument={{
                    ...eachDocument,
                    name: eachDocument.name,
                    id: eachDocument._id,
                  }}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  setShowDeleteModal={setShowDeleteModal}
                  deleteLoading={deleteLoading}
                  draft={true}
                  showDelete={true}
                />
              ))}
            </div>
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};
