import React, { useState } from "react";
import { BsArrowRight, BsPatchCheckFill } from "react-icons/bs";
// import { FiChevronRight } from "react-icons/fi";
import backgroundImage from "../backgroundImage.jpg";
import { useNavigate } from "react-router-dom";
import { easings, useSpring } from "@react-spring/web";
import { animated } from "@react-spring/web";
import {
  sendVerificationCodeAPI,
  signinAPI,
  verifyCodeAPI,
} from "../api/apiCalls";
import { isAllowedToContinueForm } from "../functions";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/actions/userAction";
import { BiCheck } from "react-icons/bi";
import { RiLockPasswordFill } from "react-icons/ri";

export const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const redirectLink =
    typeof localStorage.getItem("redirectLink") === "string"
      ? JSON.parse(localStorage.getItem("redirectLink"))
      : null;

  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0.5,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [signupSectionProps, setSignupSectionProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [verificationSectionProps, setVerificationSectionProps] = useSpring(
    () => ({
      opacity: 0,
    })
  );

  const [checkMarkProps, setCheckMarkProps] = useSpring(() => ({
    opacity: 0,
    marginLeft: 40,
  }));

  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const switchSignupContent = (showVerification) => {
    if (showVerification) {
      setSignupSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          x: -40,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(true);

        setVerificationSectionProps({
          from: {
            opacity: 0,
            x: 40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    } else {
      setVerificationSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          X: 40,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(false);

        setSignupSectionProps({
          from: {
            opacity: 0,
            x: -40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    }
  };

  // ! ONLY SHOW VERIFICATION IS USER IS NOT VERIFIED
  // ! GET IS USER VERIFIED FROM API
  // ! VERIFY USER

  const [loginUserInfo, setLoginUserInfo] = useState(null);

  const [error, setError] = useState(null);

  const changeInput = (label, value) => {
    setInputs({
      ...inputs,
      [label]: value,
    });
  };

  const ifLoginSuccessful = (responseData) => {
    dispatch(
      updateUser({
        id: responseData.user._id,
        name: responseData.user.name,
        email: responseData.user.email,
        token: responseData.token,
        onboardingShown: true,
        isOnboardingCompleted: responseData.user.isOnboardingCompleted,
        companyName: responseData.user.companyName,
        country: responseData.user.country,
        plan: responseData.user.plan,
      })
    );

    setTimeout(() => {
      setLoading(false);

      if (responseData.user.isOnboardingCompleted) {
        if (redirectLink) {
          window.location.replace(redirectLink);
          localStorage.removeItem("redirectLink");
        } else {
          navigate("/");
        }
      } else {
        // navigate("/welcome");
        navigate("/");
      }
    }, 2000);
  };

  const sendVerificationCode = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    try {
      // if (phoneNumber.length >= 10) {
      const response = await sendVerificationCodeAPI({
        // phoneNumber: phoneNumber[0] === "+" ? phoneNumber : `+${phoneNumber}`,
        email: inputs.email,
      });

      if (response.data) {
        setLoading(false);
        setError(null);

        switchSignupContent(true);
      }
      // } else {
      //   setError("Add a valid phone number");
      // }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
  };

  const login = async (e) => {
    try {
      if (e) e.preventDefault();
      setLoading(true);

      const response = await signinAPI({
        email: inputs.email,
        password: inputs.password,
      });

      if (response.data) {
        // //"login data: ", response.data);
        if (!response.data.token && !response.data.user.verified) {
          setLoginUserInfo(response.data.user);

          // setShowPhoneNumber(true);
          sendVerificationCode();
        } else {
          ifLoginSuccessful(response.data);
        }
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const checkMarkTransition = () => {
    setCheckMarkProps({
      from: {
        opacity: 0,
        marginLeft: -20,
      },
      to: {
        opacity: 1,
        marginLeft: 20,
      },
    });
  };

  const [codeInput, setCodeInput] = useState("");
  const [userVerified, setUserVerified] = useState(false);

  const verifyCode = async () => {
    try {
      setLoading(true);
      const response = await verifyCodeAPI({
        email: inputs.email,
        code: parseInt(codeInput),
      });

      if (response.data) {
        setLoading(false);
        setError(null);

        setUserVerified(true);

        checkMarkTransition();

        setTimeout(() => {
          ifLoginSuccessful({
            user: {
              ...loginUserInfo,
            },
            token: response.data.token,
          });
        }, 500);
      }
    } catch (error) {
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <animated.div style={fadeInProps} className="loginContainer">
      {/* <div className="loginLeft"></div> */}

      <div className="loginRight">
        <div className="logoContainer">
          <img src="/logoDarkBlue.png" className="loginLogo" />
          <p className="loginLogoText">Airstrip</p>
        </div>

        {!showVerificationCode ? (
          showPhoneNumber ? (
            <animated.div style={signupSectionProps}>
              <p className="loginRightHeader">
                Add your phone number to verify and secure your account
              </p>

              <form
                onSubmit={(e) => sendVerificationCode(e)}
                className="loginRightForm"
              >
                <div className="loginInputContainer">
                  <p className="loginInputLabel">Phone Number</p>
                  <input
                    type="number"
                    className="loginInput"
                    placeholder="+11234567890"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                {error && <p className="errorText">{error}</p>}

                <button
                  disabled={!isAllowedToContinueForm(inputs) || loading}
                  type="submit"
                  className={`loginButton ${
                    !isAllowedToContinueForm(inputs) && "disabledButton"
                  }`}
                  style={{ opacity: loading ? 0.7 : 1 }}
                  onClick={(e) => sendVerificationCode(e)}
                >
                  <p className="loginButtonText">Send verification</p>
                  {loading ? (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{ marginLeft: 5 }}
                      wrapperClass="loginButtonIcon"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  ) : (
                    <BsArrowRight className="loginButtonIcon" />
                  )}
                </button>
              </form>

              <p className="loginAlreadyHaveAccountText">
                Don't have an account?{" "}
                <span
                  onClick={() => navigate("/signup")}
                  className="signupText"
                >
                  Sign up
                </span>
              </p>
            </animated.div>
          ) : (
            <animated.div style={signupSectionProps}>
              <p className="loginRightHeader">Welcome back, Login.</p>

              <form onSubmit={(e) => login(e)} className="loginRightForm">
                <div className="loginInputContainer">
                  <p className="loginInputLabel">Email</p>
                  <input
                    type="email"
                    className="loginInput"
                    placeholder="ken@gmail.com"
                    autoFocus
                    value={inputs.email}
                    onChange={(e) => changeInput("email", e.target.value)}
                  />
                </div>

                <div className="loginInputContainer">
                  <p className="loginInputLabel">Password</p>
                  <input
                    type="password"
                    className="loginInput"
                    placeholder="kenspassword"
                    value={inputs.password}
                    onChange={(e) => changeInput("password", e.target.value)}
                  />
                </div>

                {error && <p className="errorText">{error}</p>}

                <button
                  disabled={!isAllowedToContinueForm(inputs) || loading}
                  type="submit"
                  className={`loginButton ${
                    !isAllowedToContinueForm(inputs) && "disabledButton"
                  }`}
                  style={{ opacity: loading ? 0.7 : 1 }}
                  onClick={() => login()}
                >
                  <p className="loginButtonText">Login</p>
                  {loading ? (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{ marginLeft: 5 }}
                      wrapperClass="loginButtonIcon"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  ) : (
                    <BsArrowRight className="loginButtonIcon" />
                  )}
                </button>
              </form>

              <p className="loginAlreadyHaveAccountText">
                Don't have an account?{" "}
                <span
                  onClick={() => navigate("/signup")}
                  className="signupText"
                >
                  Sign up
                </span>
              </p>
            </animated.div>
          )
        ) : (
          <>
            <animated.div
              style={verificationSectionProps}
              className="verificationContainer"
            >
              <div className="verifyLockIconContainer">
                <BsPatchCheckFill className="verifyLockIcon" />
                {/* <img src="/secureLogo.png" className="verifyLockIcon" alt="" /> */}
              </div>

              <p className="loginRightHeader verifyLoginHeader">
                Hey {loginUserInfo.name}, just one last step, please verify your
                account.
              </p>
              <p className="loginRightDescription">
                To provide a customised experience and improve security, please
                verify your account with the code sent to your email
              </p>

              <form
                onSubmit={(e) => verifyCode(e)}
                className="loginRightForm verificationForm loginVerificationForm"
              >
                <div className="loginInputContainers verificationCodeInputContainer">
                  <div className="loginInputContainer verificationCodeInputContainer">
                    {/* <p className="loginInputLabel">C</p> */}
                    <input
                      autoFocus
                      type="number"
                      className="loginInput verificationCodeInput"
                      placeholder="2143"
                      value={codeInput}
                      onChange={(e) => setCodeInput(e.target.value)}
                      maxLength={4}
                      minLength={4}
                      required
                    />
                  </div>
                </div>

                {error && <p className="errorText">{error}</p>}

                <button
                  disabled={!isAllowedToContinueForm(inputs) || loading}
                  type="submit"
                  className={`loginButton verifyButton loginVerifyButton ${
                    !isAllowedToContinueForm(inputs) && "disabledButton"
                  }`}
                  style={{ opacity: loading ? 0.7 : 1 }}
                  onClick={() => verifyCode()}
                >
                  <p className="loginButtonText">Continue to Airstrip</p>
                  {userVerified && (
                    <animated.div
                      style={checkMarkProps}
                      className="loginCheckIconContainer"
                    >
                      <BiCheck className="loginCheckIcon" />
                    </animated.div>
                  )}

                  {!userVerified &&
                    (loading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        wrapperClass="loginButtonIcon"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <BsArrowRight className="loginButtonIcon" />
                    ))}
                </button>
              </form>
            </animated.div>
          </>
        )}
      </div>
    </animated.div>
  );
};
