import React, { useEffect, useState } from "react";
import {
  BsArrowRightShort,
  BsCheckLg,
  BsFillBagCheckFill,
  BsPatchCheckFill,
  BsPlayFill,
  BsStars,
} from "react-icons/bs";

import { AiFillSafetyCertificate } from "react-icons/ai";
import { BsSafeFill } from "react-icons/bs";
import { FiCheck, FiChevronRight } from "react-icons/fi";
import { HiDocument, HiOutlineCheck, HiRectangleStack } from "react-icons/hi2";
import { MdCreateNewFolder, MdPerson4, MdPolicy } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { constants, documentsList, iconsForDocumentCategories } from "../data";

import { animated, easings, useSpring } from "@react-spring/web";
import { useDispatch, useSelector } from "react-redux";
import {
  addStartupOnboardingDetailsAPI,
  getAllDocumentsAPI,
} from "../api/apiCalls";
import { EachDocument } from "../components/EachDocument";
import { FindDocumentsPopup } from "../components/FindDocumentsPopup";
import { Navbar } from "../components/Navbar";
import { processArrayStream, readStream } from "../functions";
import { updateDocument } from "../redux/actions/documentActions";
import { ChatWithAI } from "./Chat";

import { Oval } from "react-loader-spinner";
import documentsImage from "../documentsImage.png";
import documentImage from "../documentImage.png";
import saveImage from "../finaliseDocumentImage.png";
import feedbackImage from "../feedback.png";
import { updateUser } from "../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";
import {
  BiCheck,
  BiRightArrow,
  BiSolidCheckCircle,
  BiSolidCustomize,
} from "react-icons/bi";
import { PiCheckFill } from "react-icons/pi";
import { RiCheckFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import RadixModal from "../components/Modal/RadixModal";
import { CgClose, CgFileDocument } from "react-icons/cg";
import SelectDropdown from "../components/UIComponents/SelectDropdown";
import { updateStepper } from "../redux/actions/stepperActions";

export const documents = [
  {
    icon: () => <MdCreateNewFolder className="eachDocumentListHeaderIcon" />,
    category: "Formation",
    documents: documentsList.filter(
      (document) => document.category === constants.documentTypes.formation
    ),
  },
  {
    icon: () => <HiRectangleStack className="eachDocumentListHeaderIcon" />,
    category: "Hiring and Employment",
    documents: documentsList.filter(
      (document) =>
        document.category === constants.documentTypes.hiring_and_employment
    ),
  },
  {
    icon: () => (
      <AiFillSafetyCertificate className="eachDocumentListHeaderIcon" />
    ),
    category: "Intellectual Property Protection",
    documents: documentsList.filter(
      (document) => document.category === constants.documentTypes.ip_protection
    ),
  },
  {
    icon: () => <BsSafeFill className="eachDocumentListHeaderIcon" />,
    category: "Confidentiality and Non-Disclosure",
    documents: documentsList.filter(
      (document) =>
        document.category ===
        constants.documentTypes.confidentiality_and_non_disclosure
    ),
  },
  {
    icon: () => <MdPerson4 className="eachDocumentListHeaderIcon" />,
    category: "Contracts with Stakeholders",
    documents: documentsList.filter(
      (document) =>
        document.category ===
        constants.documentTypes.contracts_with_stakeholders
    ),
  },
  {
    icon: () => <MdPolicy className="eachDocumentListHeaderIcon" />,
    category: "Policies and Terms",
    documents: documentsList.filter(
      (document) =>
        document.category === constants.documentTypes.policies_and_terms
    ),
  },
];

export const onboaringSteps = [
  {
    image: documentImage,
    title: "Get Personalised Legal Documents",
    description:
      "Airstrip AI understands your business, and provides personalised and compliant document.",
  },
  {
    image: documentsImage,
    title: "Find The Right documents for your Startup",
    description:
      "Explain about your business and find the documents that are needed for your specific startup's scenario",
  },
  {
    image: saveImage,
    title: "Finalise your document",
    description:
      "Ask questions about your document, make changes, finalise it and start using it.",
  },
  {
    image: feedbackImage,
    title: "Let us know your Feedback!",
    description:
      "Feel free to let us know your feedback, it helps us improve Airstrip.",
  },
  {
    image: documentsImage,
    title: "That's it!",
    description:
      "You can continue using the app, let us know if you face any issues through the support chat in the menu.",
  },
];

export const App = () => {
  const navigate = useNavigate();

  const [findDocumentsPopupProps, setFindDocumentsPopupProps] = useSpring(
    () => ({
      x: 0,
      y: 0,
      width: "700px",
      minHeight: "400px",
      opacity: 0,
    })
  );

  const [findDocumentsContainerProps, setFindDocumentsContainerProps] =
    useSpring(() => ({
      opacity: 0,
    }));

  const [findDocumentsOverlayProps, setFindDocumentsOverlayProps] = useSpring(
    () => ({
      opacity: 0,
    })
  );

  const [modalProps, setmodalProps] = useSpring(() => ({
    from: {
      opacity: 0,
      marginTop: 40,
    },
    to: {
      opacity: 1,
      marginTop: 0,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [modalContainerProps, setModalContainerProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [documentsListProps, setDocumentsListProps] = useSpring(() => ({
    opacity: 0,
  }));

  const [showFindDocuments, setShowFindDocuments] = useState(false);
  const [showAllDocuments, setShowAllDocuments] = useState(false);

  const [documentsLoading, setDocumentsLoading] = useState(false);

  const scenarioTextState = useState("");

  const [documentsForScenario, setDocumentsForScenario] = useState([]);
  const [fetchingDocuments, setFetchingDocuments] = useState([]);

  const {
    token,
    onboardingShown,
    name,
    email,
    onboardingSteps: onboardingMessages,
    isOnboardingCompleted,
  } = useSelector((state) => state.user);
  const { allDocuments } = useSelector((state) => state.document);
  const dispatch = useDispatch();

  const [showOnboardingModal, setShowOnboardingModal] = useState(true);

  const toggleFindDocuments = (state) => {
    if (state === "open") {
      setShowFindDocuments(true);

      setFindDocumentsOverlayProps({
        to: {
          opacity: 1,
        },
      });

      setFindDocumentsPopupProps({
        from: {
          x: 0,
          y: 0,
          width: "0px",
          minHeight: "400px",
          opacity: 0,
        },
        to: {
          x: 0,
          y: 0,
          width: "700px",
          minHeight: "400px",
          opacity: 1,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setTimeout(() => {
        setFindDocumentsContainerProps({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
          config: {
            easing: easings.easeInElastic(10),
          },
        });
      }, 500);
    } else if (state === "close") {
      setFindDocumentsContainerProps({
        from: {
          opacity: 1,
        },
        to: {
          opacity: 0,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setFindDocumentsOverlayProps({
        to: {
          opacity: 0,
        },
      });

      setFindDocumentsPopupProps({
        from: {
          x: 0,
          y: 0,
          opacity: 1,
        },
        to: {
          x: 0,
          y: 30,
          opacity: 0,
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setDocumentsListProps({
        to: {
          opacity: 0,
        },
      });

      setTimeout(() => {
        setShowAllDocuments(false);
      }, 500);

      setFindDocumentsPopupProps({
        from: {
          minHeight: "400px",
        },
        to: {
          minHeight: "900px",
        },
      });

      const [_, setScenarioText] = scenarioTextState;

      setScenarioText("");
      setFetchingDocuments(false);
      setDocumentsForScenario([]);

      setTimeout(() => {
        setShowFindDocuments(false);
      }, 500);
    }
  };

  useEffect(() => {
    if (showAllDocuments) {
      document.querySelector(`#foundDocuments`).scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [showAllDocuments]);

  const findDocuments = async () => {
    const [scenarioText] = scenarioTextState;

    if (scenarioText.length !== 0) {
      setDocumentsLoading(true);

      const [scenarioText] = scenarioTextState;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/document/find-documents-scenario`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            api_key: "krishna",
            Authorization: token,
          },
          body: JSON.stringify({
            scenario: scenarioText,
          }),
        }
      );

      let documentsArray = [];

      if (response.ok) {
        setFetchingDocuments(true);

        await readStream(response.body, (streamChunk) => {
          if (streamChunk !== "done") {
            processArrayStream(streamChunk, (object) => {
              setDocumentsForScenario([...documentsArray, { ...object }]);

              // // //"object:", object);
              documentsArray.push(object);

              // // //"documents array: ", documentsForScenario);

              setDocumentsLoading(false);

              setShowAllDocuments(true);

              setTimeout(() => {
                setDocumentsListProps({
                  to: {
                    opacity: 1,
                  },
                });
              }, 200);
            });
          } else if (streamChunk === "done") {
            setFetchingDocuments(false);

            // // //"Array: ", documentsArray);
          }
        });
        //
      }

      setFindDocumentsPopupProps({
        from: {
          minHeight: "400px",
        },
        to: {
          minHeight: "900px",
        },
      });

      setTimeout(() => {
        setShowAllDocuments(true);

        setDocumentsListProps({
          to: {
            opacity: 1,
          },
        });
      }, 500);
    }
  };

  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);

  useEffect(() => {
    if (token.length !== 0 && name.length !== 0 && email.length !== 0) {
      Crisp.setTokenId(token);
      Crisp.user.setEmail(email);
      Crisp.user.setNickname(name);
    }
  }, [token]);

  const [onboardingStep, setOnboardingStep] = useState(0);

  const closeOnboardingModal = () => {
    setModalContainerProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      config: {
        easing: easings.easeInElastic(1),
      },
    });

    setTimeout(() => {
      setShowOnboardingModal(false);
      dispatch(
        updateUser({
          onboardingShown: true,
        })
      );
    }, 300);
  };

  //   useEffect(() => {
  //     const formId = 'mVQJll';

  // if(Tally) {
  //   // Open the popup
  // Tally.openPopup(formId, options);
  // }

  //   },[])

  const [openedOnboardingStep, setOpenedOnboardingStep] = useState(0);

  const addOnboardingStepCompleted = (stepData, index) => {
    const filteredData = onboardingMessages.map(
      (eachOnboardingMessage, onboardingIndex) => {
        if (onboardingIndex === index) {
          //("this: ", eachOnboardingMessage);
          return {
            ...eachOnboardingMessage,
            completed: !eachOnboardingMessage.completed,
          };
        } else return { ...eachOnboardingMessage };
      }
    );

    //("filtered: ", filteredData);

    dispatch(
      updateUser({
        onboardingSteps: filteredData,
      })
    );
  };

  let documentsList = [];

  const [docsInfo, setDocsInfo] = useState([]);
  const [filteredAllDocuments, setFilteredAllDocuments] =
    useState(allDocuments);

  const getAllDocuments = async () => {
    const response = await getAllDocumentsAPI();

    if (response.data) {
      let localDocsInfo = response.data;
      const filteredDocsInfo = localDocsInfo.sort((a, b) => {
        const textA = a.type.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const textB = b.type.toUpperCase();

        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setDocsInfo(filteredDocsInfo);

      response.data.map((documentObject) => {
        documentObject.documents.map((eachDocument) => {
          documentsList.push(eachDocument);
        });
      });

      dispatch(
        updateDocument({
          allDocuments: documentsList,
        })
      );
      setFilteredAllDocuments(documentsList);
    }
  };

  useEffect(() => {
    getAllDocuments();

    if (!onboardingMessages) {
      dispatch(
        updateUser({
          onboardingSteps: [
            {
              title: "Provide more context",
              description:
                "Upload any documents with information about your business for Airstrip AI to provide you personalised and accurate assistance.",
              link: "/settings",
              buttonText: "Add Context",
              completed: false,
            },
            {
              title: "Get Answers to your Legal Questions - from Pilot",
              description:
                "Ask your legal questions from Pilot and get personalised and actionable assistance, that will help you make the right decision.",
              link: "/chat",
              buttonText: "Chat with Pilot",
              completed: false,
            },
            {
              title: "Create a New Document",
              description:
                "Browse through available documents, choose what document you need, and get a personalised and lawyer-level document.",
              link: "/browse-documents",
              buttonText: "Browse Documents",
              completed: false,
            },
            {
              title: "View your documents",
              description:
                "Have your documents stored securely all-in-one place, and keep track of them here.",
              link: "/my-documents",
              buttonText: "View saved documents",
              completed: false,
            },
          ],
        })
      );
    }
  }, []);

  const findCompletedOnboardingSteps = () => {
    return (
      onboardingMessages &&
      onboardingMessages?.filter(
        (eachOnboardingMessage) => eachOnboardingMessage.completed === true
      ).length
    );
  };

  const [openModal, setOpenModal] = useState(true);

  const [onboardingStepNumber, setOnboardingStepNumber] = useState(1);
  const [onboardingSuccess, setOnboardingSucess] = useState(false);

  const [documentFilterTag, setDocumentFilterTag] = useState(" ");

  const [documentSearchInput, setDocumentSearchInput] = useState("");

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [onboardingInputs, setOnboardingInputs] = useState({
    company: "",
    description: "",
  });

  useEffect(() => {
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.title
        .toLowerCase()
        .includes(documentSearchInput.toLowerCase())
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentSearchInput]);

  useEffect(() => {
    setDocumentSearchInput("");
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.tag.includes(documentFilterTag)
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentFilterTag]);

  useEffect(() => {
    if (selectedDocument) {
      let newFilteredAllDocuments = allDocuments.filter(
        (eachDocument) => eachDocument === selectedDocument
      );

      setFilteredAllDocuments(newFilteredAllDocuments);
    } else {
      setSelectedDocument(null);
      setFilteredAllDocuments(allDocuments);
    }
  }, [selectedDocument]);

  const [onboardingModalProps, setOnboardingModalProps] = useSpring(() => ({
    opacity: 1,
  }));

  const createDocument = () => {
    setOnboardingModalProps({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      config: {
        easing: easings.easeInElastic(20),
      },
    });

    setTimeout(() => {
      dispatch(
        updateUser({
          isOnboardingCompleted: true,
        })
      );
      navigate(`/document/${selectedDocument._id}`);
    }, 500);
  };

  // location
  const [loading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso3: "USA",
    iso2: "US",
  });

  const [selectedState, setSelectedState] = useState({
    name: "California",
    state_code: "CA",
  });
  const [countries, setCountries] = useState([]);

  let supportedCountries = ["USA", "GBR", "CAN", "AUS", "DEU", "CHE"];

  const getCountries = () => {
    setLoading(true);

    fetch("https://countriesnow.space/api/v0.1/countries/states").then(
      (response) => {
        response.json().then((responseData) => {
          // //responseData)

          const filteredCountries = responseData.data.filter(
            (eachCountryInfo) => {
              if (supportedCountries.includes(eachCountryInfo.iso3)) {
                return {
                  ...eachCountryInfo,
                };
              }
            }
          );

          setCountries(filteredCountries);
          setLoading(false);
        });
      }
    );
  };

  const getCountryInfo = (iso3) => {
    return countries.filter((country) => country.iso3 === iso3)[0];
  };

  const getStateInfo = (stateCode) => {
    let stateInfo;

    getCountryInfo(selectedCountry.iso3).states.filter((state) => {
      // //state)
      // //stateCode)

      if (state.state_code === stateCode) {
        stateInfo = state;
      }
    });

    return stateInfo;
  };

  const [onboardingLoading, setOnboardingLoading] = useState(false);

  const redirectLink = JSON.parse(localStorage.getItem("redirectLink"));

  const addOnboardingDetailsAPI = async () => {
    setOnboardingLoading(true);

    const response = await addStartupOnboardingDetailsAPI({
      name: onboardingInputs.company,
      aboutCompany: onboardingInputs.company,
      country: `${selectedState.name && `${selectedState.name},`} ${
        selectedCountry.name
      }`,
    });

    if (response.data) {
      dispatch(
        updateUser({
          companyName: onboardingInputs.company,
          country: `${selectedState.name && `${selectedState.name},`} ${
            selectedCountry.name
          }`,
        })
      );

      if (redirectLink) {
        if (redirectLink.includes("pricing")) {
          window.location.href = redirectLink;
          localStorage.removeItem("redirectLink");

          dispatch(
            updateUser({
              isOnboardingCompleted: true,
            })
          );
          dispatch(
            updateUser({
              showUpgradeModal: true,
            })
          );
        }
      }

      setOnboardingLoading(false);
      setOnboardingSucess(true);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <div className="homeContainer">
      {window.location.href.indexOf("chat") > -1 && <ChatWithAI />}

      {!isOnboardingCompleted && (
        <div className="appOnboardingModal">
          <animated.div
            style={onboardingModalProps}
            className={`appOnboardingModalContent ${
              onboardingSuccess && "appOnboardingModalContentSuccess"
            }`}
          >
            <div className="appOnboardingContent">
              {!onboardingSuccess && !redirectLink ? (
                !onboardingSuccess ? (
                  <>
                    <div className="appOnboardingHeader">
                      <img
                        src="/blueTransparent.png"
                        className="appOnboardingLogo"
                      />
                      <h1 className="appOnboardingHeaderText">
                        Welcome to Airstrip AI
                      </h1>
                      <p className="appOnboardingHeaderDescription">
                        Airstrip helps you get lawyer-level legal documents with
                        the best-in class AI. Let's get you setup for that in
                        seconds!
                      </p>
                    </div>

                    {onboardingStepNumber === 1 ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setOnboardingStepNumber(2);
                        }}
                        className="appOnboardingMain"
                      >
                        <p className="stepLabel">
                          Step 1 of 3 {"(in < 60 seconds)"}
                        </p>

                        <div className="appOnboardingInputContainer">
                          <label className="label" htmlFor="username">
                            Company Name
                          </label>
                          <input
                            className="input"
                            autoFocus
                            placeholder="Acme Inc."
                            id="username"
                            value={onboardingInputs.company}
                            onChange={(e) => {
                              setOnboardingInputs({
                                ...onboardingInputs,
                                company: e.target.value,
                              });
                            }}
                            required
                          />
                        </div>

                        <div className="appOnboardingMainButtons">
                          <button
                            type="submit"
                            className="onboardingNextButton"
                            // onClick={() => setOnboardingStepNumber(2)}
                          >
                            <p className="onboardingButtonText">Continue</p>
                            <BsArrowRightShort size={18} />
                          </button>
                        </div>
                      </form>
                    ) : onboardingStepNumber === 2 ? (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setOnboardingStepNumber(3);
                        }}
                        className="appOnboardingMain"
                      >
                        <p className="stepLabel">
                          Step 2 of 3 {"(in < 60 seconds)"}
                        </p>

                        <div className="appOnboardingInputContainer">
                          <label className="label" htmlFor="username">
                            Short Description about your business
                          </label>
                          <textarea
                            className="input textarea"
                            autoFocus
                            placeholder="Acme Inc."
                            id="username"
                            value={onboardingInputs.description}
                            onChange={(e) => {
                              setOnboardingInputs({
                                ...onboardingInputs,
                                description: e.target.value,
                              });
                            }}
                            required
                            minLength={150}
                          />
                        </div>

                        <div className="appOnboardingMainButtons">
                          <button
                            type="submit"
                            className="onboardingNextButton"
                            // onClick={() => setOnboardingStepNumber(3)}
                            // onClick={() => {
                            //   setOnboardingSucess(true);
                            // }}
                          >
                            <p className="onboardingButtonText">Continue</p>
                            <BsArrowRightShort size={18} />
                          </button>

                          <div
                            className="appOnboardingBackButton"
                            onClick={() => setOnboardingStepNumber(1)}
                          >
                            Back
                          </div>
                        </div>
                      </form>
                    ) : (
                      <>
                        <div className="appOnboardingMain">
                          <p className="stepLabel">
                            Step 3 of 3 {"(in < 60 seconds)"}
                          </p>

                          <div className="appOnboardingInputContainer">
                            <label className="label" htmlFor="username">
                              Company Location
                            </label>

                            {loading ? (
                              <div className="loadingContent">
                                <p className="loadingText">Getting countries</p>

                                <Oval
                                  height={12}
                                  width={12}
                                  color="#1252f3"
                                  wrapperStyle={{ marginLeft: 5 }}
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#1252f320"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              </div>
                            ) : (
                              countries.length !== 0 && (
                                <>
                                  <SelectDropdown
                                    values={countries}
                                    placeholder={"Select a country"}
                                    defaultValue={"United States of America"}
                                    value={selectedCountry.iso3}
                                    onValueChange={(value) => {
                                      setSelectedCountry(getCountryInfo(value));

                                      if (
                                        getCountryInfo(value).states.length > 1
                                      ) {
                                        setSelectedState({
                                          state_code:
                                            getCountryInfo(value).states[0][
                                              "state_code"
                                            ],
                                          name: getCountryInfo(value).states[0][
                                            "name"
                                          ],
                                        });
                                      }
                                    }}
                                    valueLabel="iso3"
                                    textLabel="name"
                                  />

                                  {getCountryInfo(selectedCountry.iso3).states
                                    .length > 1 && (
                                    <SelectDropdown
                                      defaultValue={
                                        getCountryInfo(selectedCountry.iso3)
                                          .states[0]["state_code"]
                                      }
                                      values={
                                        getCountryInfo(selectedCountry.iso3)
                                          .states
                                      }
                                      placeholder="Select a country"
                                      value={selectedState.state_code}
                                      onValueChange={(value) =>
                                        setSelectedState(getStateInfo(value))
                                      }
                                      valueLabel="state_code"
                                      textLabel="name"
                                    />
                                  )}
                                </>
                              )
                            )}
                          </div>

                          <div className="appOnboardingMainButtons">
                            <div
                              className={`onboardingNextButton ${
                                onboardingLoading && "disabledButton"
                              }`}
                              onClick={() => {
                                addOnboardingDetailsAPI();
                              }}
                            >
                              <p className="onboardingButtonText">Continue</p>
                              <BsArrowRightShort size={18} />

                              {onboardingLoading && (
                                <Oval
                                  height={12}
                                  width={12}
                                  color="#ffffff"
                                  wrapperStyle={{ marginLeft: 5 }}
                                  visible={true}
                                  ariaLabel="oval-loading"
                                  secondaryColor="#ffffff30"
                                  strokeWidth={5}
                                  strokeWidthSecondary={5}
                                />
                              )}
                            </div>

                            <div
                              onClick={() => setOnboardingStepNumber(2)}
                              className="appOnboardingBackButton"
                            >
                              Back
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div className="appOnboardingSuccess">
                    <div className="appOnboardingTopContent">
                      <p className="appOnbaordingTopEmoji">🎉</p>

                      <div className="appOnboardingTopInfo">
                        <p className="appOnboardingTopText">
                          You're now all setup!
                        </p>
                        <p className="appOnboardingTopDescription">
                          Select and start creating the document with the best
                          in-class AI
                        </p>
                      </div>

                      {/* <div className="appOnboardingVideoContainer">
                      <BsPlayFill className="appOnboardingVideoIcon" />
                    </div> */}
                    </div>
                    {/* <img src="/blueTransparent.png" className="appOnboardingLogo" /> */}
                    <div className="appOnboardingSuccessHeaderContent">
                      {/* <h1 className="appOnboardingSuccessHeader">🎉</h1> */}

                      <h1 className="appOnboardingSuccessHeader">
                        Choose the Document you need
                      </h1>
                      <p className="appOnboardingSuccessDescription">
                        Browse through and select the document that you need to
                        create.
                      </p>
                    </div>
                    <p></p>
                    <div className="appOnboardingSuccessContent">
                      {!selectedDocument && (
                        <>
                          <fieldset className="Fieldset appOnboardingInputContainer">
                            <input
                              className="input"
                              autoFocus
                              placeholder="ex: Founders agreement"
                              id="username"
                              value={documentSearchInput}
                              onChange={(e) =>
                                setDocumentSearchInput(e.target.value)
                              }
                            />
                          </fieldset>

                          <div className="appOnboardingTags">
                            <p
                              className={`appOnboardingTag ${
                                documentFilterTag === " " &&
                                "selectedAppOnboardingTag"
                              }`}
                              onClick={() => setDocumentFilterTag(" ")}
                            >
                              All ({allDocuments.length})
                            </p>
                            {docsInfo.map((eachDocInfo) => (
                              <p
                                className={`appOnboardingTag ${
                                  eachDocInfo.type === documentFilterTag &&
                                  "selectedAppOnboardingTag"
                                }`}
                                onClick={() =>
                                  setDocumentFilterTag(eachDocInfo.type)
                                }
                              >
                                {eachDocInfo.type} (
                                {eachDocInfo.documents.length})
                              </p>
                            ))}
                          </div>
                        </>
                      )}

                      <div
                        className={`appOnboardingDocuments ${
                          selectedDocument && "documentSelected"
                        }`}
                      >
                        {selectedDocument && (
                          <p className="appOnboardingInfoLabel">
                            Let's create you this document:
                          </p>
                        )}

                        {filteredAllDocuments.map((eachDocument) => (
                          <div
                            className={`appOnboardingEachDocument ${
                              selectedDocument === eachDocument &&
                              "selectedAppOnboardingEachDocument"
                            }`}
                            onClick={() => {
                              // when document is selected this is replacing the state
                              if (!selectedDocument) {
                                setSelectedDocument(eachDocument);
                              }
                            }}
                          >
                            <CgFileDocument className="appOnboardingEachDocumentIcon" />
                            <p className="appOnboardingEachDocumentText">
                              {eachDocument.title}
                            </p>

                            {selectedDocument === eachDocument && (
                              <div
                                className="documentCheckIconContainer"
                                onClick={() => {
                                  setSelectedDocument(null);
                                }}
                              >
                                <CgClose className="appOnboardingEachDocumentIcon documentCheckIcon" />
                              </div>
                            )}
                          </div>
                        ))}

                        {filteredAllDocuments.length === 0 && (
                          <p className="noDocumentsFound">
                            No document found.{" "}
                            <span onClick={() => Crisp.chat.open()}>
                              Please Send us a quick message
                            </span>{" "}
                          </p>
                        )}
                        {/* 
                      {!selectedDocument && (
                        <div className="appOnboardingGradient"></div>
                      )} */}
                      </div>

                      <div
                        className={`documentButton ${
                          !selectedDocument && "disabledDocumentButton"
                        }`}
                        onClick={() => createDocument()}
                      >
                        <BsStars className="documentButtonIcon" />
                        <p className="documentButtonText">Create Document</p>
                      </div>

                      {/* <iframe
                      src="https://www.loom.com/embed/5cc9011a120044758a5bec52c5a4716c?sid=473359fd-d8f0-466b-9461-cc8a46ced421"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                      className="appOnboardingVideo"
                      style={{ height: "300px", width: "100%" }}
                    ></iframe> */}

                      {/* <p
                      className="appOnboardingSuccessDescription"
                      style={{
                        fontSize: "15px",
                        opacity: 0.7,
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      {" "}
                      Watch this quick demo to see a overview of Airstrip AI's
                      features and how you can use them.
                    </p>

                    <div
                      className="onboardingNextButton"
                      onClick={() => {
                        dispatch(
                          updateUser({
                            isOnboardingCompleted: true,
                          })
                        );
                      }}
                    >
                      <p className="onboardingButtonText">Open Airstrip AI</p>
                      <BsArrowRightShort size={18} />
                    </div> */}
                    </div>
                  </div>
                )
              ) : null}

              {/* <div className="appOnboardingAddWebsite">
              <div className="appOnboardingAddWebsiteHeader">
                <BsStars />
                <p>Add your website link — we'll get the information for you</p>
              </div>

              <div className="appOnboardingAddWebsiteInputs">
                <input
                  className="input"
                  autoFocus
                  placeholder="acme.app"
                  id="username"
                />
                <div className="onboardingNextButton">
                  <p className="onboardingButtonText">Get from website</p>
                </div>
              </div>
            </div> */}
            </div>
          </animated.div>
          <div
            className="appOnboardingModalOverlay"
            // onClick={() => {
            //   dispatch(
            //     updateUser({
            //       isOnboardingCompleted: true,
            //     })
            //   );
            // }}
          ></div>
        </div>
      )}

      {/* <RadixModal
        triggerComponent={null}
        title="Tell us a little about your self"
        description="Let's get you setup with some information about you"
        buttonText="Continue"
        onClick={() => null}
        openModal={openModal}
        setOpenModal={setOpenModal}
        className={"onboardingModalContainer"}
      >
        <fieldset className="Fieldset">
          <label className="Label" htmlFor="username">
            Feedback (optional)
          </label>
          <input
            className="Input"
            autoFocus
            placeholder="What can be improved more?"
            id="username"
          />
        </fieldset>
      </RadixModal> */}

      {showOnboardingModal && !onboardingShown && (
        <animated.div style={modalContainerProps} className="onboardingModal">
          <div className="onboardingModalOverlay"></div>

          <animated.div style={modalProps} className="onboardingModalContent">
            <div className="onboardingModalTop">
              <p className="onboardingModalWelcomeText">
                Hello, Welcome to Airstrip!
              </p>

              <div
                style={{
                  backgroundImage:
                    onboardingStep !== onboaringSteps.length - 1 &&
                    `url(${onboaringSteps[onboardingStep].image})`,
                }}
                className={`onboardingModalImage ${
                  onboardingStep === onboaringSteps.length - 1 &&
                  "lastOnboaradingModalStep"
                }`}
              >
                {onboardingStep === onboaringSteps.length - 1 && (
                  <>
                    <div className="logoSection">
                      <img
                        src="/logoDarkBlue.png"
                        className="logoImage"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <p className="onboardingLogoName">Airstrip</p>
                    </div>
                    {/* <div className="logoSectionLine"></div> */}
                    <p className="onboardingLogoText">You're set up!</p>
                  </>
                )}
              </div>

              <div className="onboardingModalInfo">
                <h1 className="onboardingModalTitle">
                  {onboaringSteps[onboardingStep].title}
                </h1>
                <p className="onboardingModalDescription">
                  {onboaringSteps[onboardingStep].description}
                </p>
              </div>

              <div className="stepCircles">
                {onboaringSteps.map((eachStep, index) => (
                  <div
                    onClick={() => setOnboardingStep(index)}
                    className={`stepCircle ${
                      index === onboardingStep && "currentStepCircle"
                    }`}
                  ></div>
                ))}
                {/* <div className="stepCircle currentStepCircle"></div>
              <div className="stepCircle"></div> */}
              </div>
            </div>

            <div className="onboardingModalButtons">
              <div
                className="skipButton"
                onClick={() => closeOnboardingModal()}
              >
                <p className="skipButtonText">Skip</p>
              </div>

              {onboardingStep === onboaringSteps.length - 1 ? (
                <div
                  className="nextButton finishButton"
                  onClick={() => closeOnboardingModal()}
                >
                  <p className="nextButtonText">Finish</p>
                  <FiCheck className="nextButtonIcon" />
                </div>
              ) : (
                <div
                  className="nextButton"
                  onClick={() => setOnboardingStep((prev) => prev + 1)}
                >
                  <p className="nextButtonText">Next</p>
                  <FiChevronRight className="nextButtonIcon" />
                </div>
              )}
            </div>
          </animated.div>
        </animated.div>
      )}

      <Navbar />

      <div className="rightContainer">
        {/* <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Home</h1>
          <p className="rightContainerHeaderDescription">
            Get your Account set-up and view overall insights
          </p>
        </div> */}

        <div className="homeContentContainer">
          <img src="/blueTransparent.png" className="homeContainerLogo" />

          {/* <p
            className="homeContentHeaderDescription versionText"
            style={{ marginBottom: "10px" }}
          >
            v1.0.5
          </p> */}

          <p className="homeContentHeaderDescription">
            Get Legal Documents created with the best-in class AI.
          </p>

          <div className="homeContentButtonsContainer">
            <Link to="/browse-documents" className="homeContentContainerLink">
              Create new document ->
            </Link>
            <Link to="/browse-documents" className="homeContentContainerLink">
              View your documents ->
            </Link>
          </div>

          {/* <p className="homeContentHeaderText">
            Hey {name}, Welcome to Airstrip
          </p> */}

          {/* <div className="homeContent">
            <div className="homeContentButtons">
              <div className="homeContentButtonsHeader">
                <BiSolidCustomize className="homeContentButtonsHeaderIcon" />

                <h1 className="homeContentButtonsHeaderText">
                  Let's get you started with these steps,
                </h1>
                <p className="homeContentButtonsHeaderDescription">
                  Use this personalised guide to get setup with getting
                  personalied legal assistance
                </p>

                <div className="tasksCompleted">
                  <p className="tasksCompletedText">
                    {findCompletedOnboardingSteps()} / 4 Tasks Completed{" "}
                    {findCompletedOnboardingSteps() === 4 && "🎉"}
                  </p>

                  <div className="tasksCompletedProgressContainer">
                    <div
                      className="tasksCompletedProgress"
                      style={{
                        width: `${(findCompletedOnboardingSteps() / 4) * 100}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="homeContentButtonsList">
                {onboardingMessages?.map((eachOnboardingMessage, index) => (
                  <div
                    className={`homeContentLink ${
                      openedOnboardingStep === index &&
                      "selectedHomeContentLink"
                    }`}
                    onClick={() => setOpenedOnboardingStep(index)}
                  >
                    <div
                      className={`homeContentLinkIcon ${
                        eachOnboardingMessage.completed &&
                        "completedHomeContentLinkIcon"
                      }`}
                      onClick={() =>
                        addOnboardingStepCompleted(eachOnboardingMessage, index)
                      }
                    >
                      {eachOnboardingMessage.completed && <FaCheck />}
                    </div>
                    <div className="homeContentLinkRight">
                      <p className="homeContentLinkText">
                        {eachOnboardingMessage.title}
                      </p>
                      {openedOnboardingStep === index && (
                        <>
                          <p className="homeContentLinkDescription">
                            {eachOnboardingMessage.description}
                          </p>
                          <Link
                            to={eachOnboardingMessage.link}
                            className="homeContentLinkButton"
                          >
                            {eachOnboardingMessage.buttonText}
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                ))}

                <div className="homeContentLink">
                  <div className="homeContentLinkIcon"></div>
                  <p className="homeContentLinkText">Create a New Document</p>
                </div>

                <div className="homeContentLink">
                  <div className="homeContentLinkIcon"></div>
                  <p className="homeContentLinkText">View your Documents</p>
                </div>

                <div className="homeContentLink">
                  <div className="homeContentLinkIcon"></div>
                  <p className="homeContentLinkText">Chat with Pilot</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* 
        {showFindDocuments && (
          <FindDocumentsPopup
            findDocumentsOverlayProps={findDocumentsOverlayProps}
            toggleFindDocuments={toggleFindDocuments}
            findDocumentsPopupProps={findDocumentsPopupProps}
            findDocumentsContainerProps={findDocumentsContainerProps}
            findDocuments={findDocuments}
            showAllDocuments={showAllDocuments}
            documentsListProps={documentsListProps}
            documents={documentsForScenario}
            scenarioTextState={scenarioTextState}
            documentLoading={documentsLoading}
            fetchingDocuments={fetchingDocuments}
          />
        )} */}
      {/* </div> */}

      {/* <div className="container">
          <div className="homeMain">
            <div className="findDocumentsForYouContainer">
              <p className="findDocumentsHeaderText">
                Find the right documents for your startup.
              </p>
              <p className="findDocumentsHeaderDescription">
                Explain your startup, what it does, and our AI will find the
                right documents for your specific use case.
              </p>
              <animated.div className="findDocumentsInputContainer">
                <input
                  type="text"
                  placeholder="ex: My Startup is an app that provides home services"
                  className="findDocumentInput"
                  onFocus={() => {
                    toggleFindDocuments("open");
                  }}
                />
                <button className="findDocumentButton">
                  <BsStars className="findDocumentIcon" />
                  <p className="findDocumentText">Find</p>
                </button>
              </animated.div>
            </div>
          </div>
        </div> */}

      {/* <div className="chatWithMiraAiOverlay" onClick={() => navigate("/chat")}>
        <div className="chatWithMiraAiButton">
          <BsStars className="chatWithMiraAiIcon" />
          <p className="chatWithMiraAiText">Chat with Mira AI</p>
        </div>
      </div> */}
    </div>
  );
};
