import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { Oval } from "react-loader-spinner";

import { animated, easings, useSpring } from "@react-spring/web";

export const Modal = ({
  onClose,
  HeaderIcon,
  headerText,
  children,
  onClick,
  loading,
  buttonText,
  showButtonContainer,
  modalClassName,
  showButtonArrow,
  description,
  headerClassName,
}) => {
  const [modalProps, setmodalProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  return (
    <animated.div style={modalProps} className="modal">
      <div
        className="modalOverlay"
        onClick={() => {
          onClose();
        }}
      ></div>

      <div className={`modalContent ${modalClassName}`}>
        <div className={`modalContentHeader`}>
          <div className={`modalContentHeaderTop ${headerClassName}`}>
            {HeaderIcon && (
              <div className="modalContentHeaderLeft">
                <HeaderIcon className="modalContentHeaderIcon" />
              </div>
            )}

            <div className="modalContentHeaderTextContent">
              <p className="modalContentHeaderText">{headerText}</p>
              {description && (
                <p className="modalContentHeaderDescription">{description}</p>
              )}
            </div>

            <IoClose
              className="modalContentHeaderCloseIcon"
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>

        <div className="modalMainContent">{children}</div>

        {showButtonContainer && (
          <div
            className={`modalButtonContainer ${loading && "disabledButton"}`}
            onClick={() => onClick()}
            disabled={loading}
          >
            <div className="modalMainContentButton">
              <p className="modalMainContentButtonText">{buttonText}</p>

              {loading && (
                <Oval
                  height={15}
                  width={15}
                  color="white"
                  wrapperStyle={{}}
                  wrapperClass="modalMainContentButtonLoader"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff7b"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}

              {showButtonArrow && (
                <FiChevronRight className="modalMainButtonArrowIcon" />
              )}
            </div>
          </div>
        )}
      </div>
    </animated.div>
  );
};
