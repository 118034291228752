import {
  ADD_NEW_VALUE,
  PUSH_TO_STEPS,
  UPDATE_STEPPER,
} from "../reducers/stepperReducer";

export const updateStepper = (payload) => {
  return {
    type: UPDATE_STEPPER,
    payload: payload,
  };
};

export const pushToSteps = (payload) => {
  return {
    type: PUSH_TO_STEPS,
    payload: payload,
  };
};

export const addNewValue = (payload) => {
  return {
    type: ADD_NEW_VALUE,
    payload: payload,
  };
};
