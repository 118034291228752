import { animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { Tooltip } from "../Tooltip";
import { BiQuestionMark } from "react-icons/bi";
import { BsInfo, BsInfoLg } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

export const StartupLocationInput = ({
  inputContainerProps,
  toggleStep,
  value,
  changeInput,
}) => {
  const [countries, setCountries] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso3: "USA",
    iso2: "US",
  });

  const [selectedState, setSelectedState] = useState({
    name: "California",
    state_code: "CA",
  });

  let supportedCountries = ["USA", "GBR", "CAN"];

  const getCountries = () => {
    setLoading(true);

    fetch("https://countriesnow.space/api/v0.1/countries/states").then(
      (response) => {
        response.json().then((responseData) => {
          // //responseData)

          const filteredCountries = responseData.data.filter(
            (eachCountryInfo) => {
              if (supportedCountries.includes(eachCountryInfo.iso3)) {
                return {
                  ...eachCountryInfo,
                };
              }
            }
          );

          setCountries(filteredCountries);
          setLoading(false);
        });
      }
    );
  };

  const getCountryInfo = (iso3) => {
    return countries.filter((country) => country.iso3 === iso3)[0];
  };

  const getStateInfo = (stateCode) => {
    let stateInfo;

    getCountryInfo(selectedCountry.iso3).states.filter((state) => {
      // //state)
      // //stateCode)

      if (state.state_code === stateCode) {
        stateInfo = state;
      }
    });

    return stateInfo;
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      changeInput({
        country: {
          ...selectedCountry,
        },
        state: {
          ...selectedState,
        },
      });
    }
  }, [selectedCountry, selectedState]);

  return (
    <animated.form
      className="signupFlowQuestionContainer"
      style={inputContainerProps ? inputContainerProps : null}
    >
      <div className="signupFlowQuestionInputsContainer">
        <div
          className="signupFlowQuestion"
          style={{
            width:
              getCountryInfo(selectedCountry?.iso3)?.states.length !== 0
                ? "100%"
                : "100%",
          }}
        >
          <div className="signupFlowQuestionLabelContainer">
            <p className="contextInputLabel">Location</p>
            {/* <Tooltip text="It could be where your users are located or where your headquaters are. By adding this, This makes your startup be more compliant and improve trust with customers.">
              <div>
                <BsInfoLg className="signupFlowQuestionToolTipIcon" />
              </div>
            </Tooltip> */}
          </div>
          {/* <p className="signupFlowQuestionLabel">Where does your startup operate?</p> */}

          {loading && (
            <Oval
              height={15}
              width={15}
              color="#191f2f"
              wrapperStyle={{ marginLeft: 5 }}
              wrapperClass="countriesLoading"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#191f2f10"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
          )}

          <select
            value={selectedCountry.iso3}
            disabled={loading}
            className={`signupFlowQuestionInput ${loading && "loading"}`}
            placeholder="Choose the country you operate from"
            name=""
            defaultValue="USA"
            id=""
            onChange={(e) => {
              // //e.target.value)
              // //getCountryInfo(e.target.value))
              setSelectedCountry({
                name: getCountryInfo(e.target.value).name,
                iso3: getCountryInfo(e.target.value).iso3,
                iso2: getCountryInfo(e.target.value).iso2,
              });
            }}
            style={{ opacity: loading ? 0.2 : 1 }}
          >
            {/* {JSON.stringify(countries)} */}
            {countries.length !== 0 &&
              countries.map((eachCountry) => (
                <option value={eachCountry.iso3}>{eachCountry.name}</option>
              ))}
          </select>
        </div>

        {!loading &&
          selectedCountry &&
          countries.length !== 0 &&
          getCountryInfo(selectedCountry?.iso3).states.length !== 0 && (
            <div className="signupFlowQuestion">
              <p className="contextInputLabel">State</p>
              <select
                value={selectedState.state_code}
                disabled={loading}
                className={`signupFlowQuestionInput signupFlowStates ${
                  loading && "loading"
                }`}
                name=""
                id=""
                onChange={(e) => {
                  setSelectedState(getStateInfo(e.target.value));

                  // //getStateInfo(e.target.value))
                }}
              >
                {getCountryInfo(selectedCountry?.iso3).states.map(
                  (eachState) => (
                    <option value={eachState.state_code}>
                      {eachState.name}
                    </option>
                  )
                )}
              </select>
            </div>
          )}
      </div>
      {/* <input value={value} onChange={(e) => changeInput('location', e.target.value)} type="text" placeholder='Ticketmaster Inc' className="signupFlowQuestionInput" /> */}
    </animated.form>
  );
};
