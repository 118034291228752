import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { TbArrowsExchange2 } from "react-icons/tb";

export const EachReviewItem = ({
  toggleOpenedBox,
  index,
  eachReviewItem,
  setShowUpdateModal,
  setSelectedClauseID,
  isBoxOpened,
}) => {
  return (
    <div className="createDocumentEachDetail">
      <div
        className="createDocumentEachDetailHeader"
        // style={{
        //   borderBottom:
        //     index === localConfirmArray.length - 1 && "none",
        // }}
        onClick={() => toggleOpenedBox(index + 1)}
      >
        <div className="createDocumentEachDetailLeft">
          <p className="createDocumentEachDetailNumber">{index + 1}</p>

          <div className="createDocumentEachDetailLeftMain">
            <p className="detailTitle">{eachReviewItem.question}</p>
            <p className="detailDescription">{eachReviewItem.decision}</p>
          </div>
        </div>

        <div className="createDocumentEachDetailRight">
          {/* <CheckIcon className="createDocumentEachDetailCheckIcon" /> */}
          <p
            className="eachDetailRejectIcon"
            title="Make changes to this decision"
            onClick={() => {
              setShowUpdateModal(true);
              setSelectedClauseID(eachReviewItem._id);
            }}
          >
            {/* Change{" "} */}
            {/* <GrUpdate fontSize={10} style={{ marginLeft: 4 }} /> */}
            <TbArrowsExchange2 />
          </p>
          {/* <IoCheckmarkSharp className="eachDetailAcceptIcon" /> */}
          <BiChevronDown
            className="createDocumentEachDetailDownIcon"
            style={{
              transform: `${isBoxOpened(index + 1) ? "rotate(180deg)" : ""} `,
            }}
          />
        </div>
      </div>

      {isBoxOpened(index + 1) && (
        <div className={`createDocumentEachDetailExpand`}>
          <div className="eachDetailInfoSection">
            <p className="eachDetailInfoSectionHeader">Decision:</p>
            <p className="eachDetailInfoSectionDescription">
              {eachReviewItem.decision}
            </p>
          </div>

          <div className="eachDetailInfoSection">
            <p className="eachDetailInfoSectionHeader">Reason:</p>
            <p className="eachDetailInfoSectionDescription">
              {eachReviewItem.reason}
            </p>
          </div>

          <div className="eachDetailInfoSection">
            <p className="eachDetailInfoSectionHeader">Used By:</p>
            <p className="eachDetailInfoSectionDescription highltightText">
              {/* TODO */}
              {eachReviewItem.used}
            </p>
          </div>

          {/* <hr style={{ marginBottom: 25, opacity: 0.3 }} /> */}

          <div className="eachDetailInfoSection eachDetailUpdateSection">
            <p
              className="eachDetailInfoSectionDescription"
              style={{ fontWeight: 500 }}
            >
              Don't want to move forward with this decision?
            </p>
            <p
              className="eachDetailInfoSectionDescription highltightText"
              style={{
                position: "unset",
                width: "fit-content",
                marginTop: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                setShowUpdateModal(true);
                setSelectedClauseID(eachReviewItem._id);
              }}
            >
              Update it{" "}
              {/* <GrUpdate
                                fontSize={10}
                                style={{ marginLeft: 4 }}
                              /> */}
            </p>
          </div>

          {/* <div className="eachDetailAcceptSection">
                           
                            <IoCheckmarkSharp
                              className="eachDetailAcceptIcon"
                              style={{
                                backgroundColor: "#1252f3",
                                color: "white",
                                boxShadow:
                                  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                              }}
                            />
                          </div> */}
        </div>
      )}

      {/* <div className="eachDetailInfoStepper">
                      {showingClauseInfo !== 0 && (
                        <div
                          className={`eachDetailInfoStepperPrevious ${
                            showingClauseInfo === 0 && "disabledButton"
                          }`}
                          onClick={() => toggleReviewItems("previous")}
                        >
                          <MdSkipPrevious className="eachDetailInfoStepperIcon" />
                          <p className="eachDetailInfoStepperText">Previous</p>
                        </div>
                      )}
                      <div
                        className={`eachDetailInfoStepperNext ${
                          showingClauseInfo === reviewItems.length - 1 &&
                          "disabledButton"
                        }`}
                        onClick={() => toggleReviewItems("next")}
                      >
                        <p className="eachDetailInfoStepperText">Next</p>
                        <MdSkipNext className="eachDetailInfoStepperIcon" />
                      </div>
                    </div> */}
    </div>
  );
};
