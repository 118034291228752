export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";

const initialState = {
  documentInfo: null,
  signatures: [],
  context: null,
  creatingProcess: false,
  allDocuments: [],
  documentDetails: null,
  documentElements: null,
  unsavedChanges: false,
  location: null,
  lawyerReview: "",
};

export const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DOCUMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
