import { combineReducers } from "redux";
import { userReducer } from "./reducers/userReducer";
import { stepperReducer } from "./reducers/stepperReducer";
import { chatReducer } from "./reducers/chatReducer";
import { documentReducer } from "./reducers/documentReducer";

export const rootReducer = combineReducers({
  stepper: stepperReducer,
  user: userReducer,
  chat: chatReducer,
  document: documentReducer,
});
