import React, { useEffect, useState } from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { Link, redirect, useParams } from "react-router-dom";
import { convertTextToSlug, getSimplifiedCardsData } from "../functions";
import { Navbar } from "../components/Navbar";
import { useSelector } from "react-redux";
import { BsStars } from "react-icons/bs";

export const SimplifiedDocument = () => {
  const { id } = useParams();

  const [simplifiedDocument, setSimplifiedDocument] = useState(null);

  const { token } = useSelector((state) => state.user);

  const getSimplifiedDocument = async () => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/document/get-simplified-document/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
      }
    ).then(async (response) => {
      const documentData = await response.json();
      setSimplifiedDocument(documentData);
    });
  };

  useEffect(() => {
    getSimplifiedDocument();
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="simplifyContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Simplify your Document</h1>
          <p className="rightContainerHeaderDescription">
            View your document in a easy-to understand and a visual format and
            identify important points.
          </p>
        </div>

        <div className="simplifyDocumentsContainer">
          <div className="simplifyDocumentsResultsContainer">
            <Link to="/simplify" className="simplifyDocumentsGoBack">
              <MdOutlineArrowBack className="simplifyDocumentsGoBackIcon" />
              <p className="simplifyDocumentsGoBackText">Go Back</p>
            </Link>

            <div className="simplifyDocumentsResults">
              <div className="simplifyDocumentResultsMainContent">
                <div className="simplifiedDocumentRightSection">{/*  */}</div>
                {/* )} */}

                <div className="simplifyDocumentsHeader">{/*  */}</div>

                {simplifiedDocument?.content.map((eachElement) => {
                  if (eachElement.type === "TITLE") {
                    return (
                      <>
                        <h1 className="simplifyDocumentsHeaderText">
                          {eachElement.text}
                        </h1>
                      </>
                    );
                  } else if (eachElement.type === "SUBTITLE") {
                    if (eachElement.text.includes("<>")) {
                      return (
                        <div className="simplifyResultsCards">
                          {getSimplifiedCardsData(eachElement.text).map(
                            (eachCardData) => (
                              <div className="simplifyResultEachCard">
                                <BsStars className="resultCardIcon" />

                                <div className="simplifyResultCardContent">
                                  <p className="resultCardLabel">
                                    {eachCardData.label}
                                  </p>
                                  <p className="resultCardText">
                                    {eachCardData.data}
                                  </p>
                                </div>
                              </div>
                            )
                          )}

                          {/* 
                        <div className="simplifyResultEachCard">
                          <BsStars className="resultCardIcon" />

                          <div className="simplifyResultCardContent">
                            <p className="resultCardLabel">Monthly Salary</p>
                            <p className="resultCardText">$400/month</p>
                          </div>
                        </div>

                        <div className="simplifyResultEachCard">
                          <BsStars className="resultCardIcon" />
                          <div className="simplifyResultCardContent">
                            <p className="resultCardLabel">
                              Probation Period
                            </p>
                            <p className="resultCardText">3 months</p>
                          </div>
                        </div>

                        <div className="simplifyResultEachCard">
                          <BsStars className="resultCardIcon" />
                          <div className="simplifyResultCardContent">
                            <p className="resultCardLabel">Expiration Date</p>
                            <p className="resultCardText">12th Feb 2024</p>
                          </div>
                        </div> */}
                        </div>
                      );
                    } else {
                      return (
                        <p
                          className="simplifyResultInfoHeader"
                          id={convertTextToSlug(eachElement.text)}
                        >
                          {eachElement.text}
                        </p>
                      );
                    }
                  } else if (eachElement.type === "PARAGRAPH") {
                    return (
                      <p className="simplifyResultInfoDescription">
                        {eachElement.text}
                      </p>
                    );
                  }
                })}

                {simplifiedDocument === null && (
                  <div className="skeletonLoader" id="simplifyDocumentLoader">
                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>

                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>

                    <div className="skeletonLoaderElement skeletonHeader"></div>
                    <div className="skeletonParagraphGroup">
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                      <div className="skeletonLoaderElement skeletonParagraph"></div>
                    </div>
                  </div>
                )}
              </div>

              {simplifiedDocument && (
                <div className="simplifyDocumentsContents">
                  <p className="simplifyDocumentsContentsHeader">CONTENTS</p>

                  <div className="simplifyDocumentsContentsList">
                    {simplifiedDocument?.content
                      .filter(
                        (eachElement) =>
                          eachElement.type === "SUBTITLE" &&
                          !eachElement.text.includes("<>")
                      )
                      .map((eachContent) => (
                        <p
                          onClick={() => {
                            scrollToSection(
                              convertTextToSlug(eachContent.text)
                            );
                          }}
                          className="simplifyDocumentsEachContent"
                        >
                          {eachContent.text}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
