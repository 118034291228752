import React, { useEffect, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
// import { FiChevronRight } from "react-icons/fi";
import backgroundImage from "../backgroundImage.jpg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { animated, easings, useSpring } from "@react-spring/web";
import {
  registerAPI,
  sendVerificationCodeAPI,
  verifyCodeAPI,
} from "../api/apiCalls";
import { isAllowedToContinueForm } from "../functions";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../redux/actions/userAction";
import { BiCheck } from "react-icons/bi";
import { IoChevronBackOutline } from "react-icons/io5";
import axios from "axios";

export const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const redirectLink = JSON.parse(localStorage.getItem("redirectLink"));

  const { userToVerify } = useSelector((state) => state.user);

  const [showVerificationCode, setShowVerificationCode] = useState(false);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const nameInputRef = useRef();
  const emailInputRef = useRef();

  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0.5,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [signupSectionProps, setSignupSectionProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [verificationSectionProps, setVerificationSectionProps] = useSpring(
    () => ({
      opacity: 0,
    })
  );

  const [checkMarkProps, setCheckMarkProps] = useSpring(() => ({
    opacity: 0,
    marginLeft: 40,
  }));

  const switchSignupContent = (showVerification) => {
    if (showVerification) {
      setSignupSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          x: -40,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(true);

        setVerificationSectionProps({
          from: {
            opacity: 0,
            x: 40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    } else {
      setVerificationSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          x: 20,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(false);

        setSignupSectionProps({
          from: {
            opacity: 0,
            x: -40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    }
  };

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    email: searchParams.get("email") || "",
    password: "",
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  const [error, setError] = useState(null);

  const signup = async (e) => {
    // fetch(`https://app.audienceful.com/api/people/`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json, text/plain, */*",
    //     "Content-Type": "application/json",
    //     "x-api-key": process.env.REACT_APP_EMAIL_LIST_API_KEY,
    //   },
    //   body: JSON.stringify({
    //     email: inputs.email,
    //     tags: "Signed up Users",
    //     extra_data: { name: inputs.name },
    //   }),
    // });

    const apiKey = process.env.REACT_APP_EMAIL_LIST_API_KEY; // Replace with your API key
    const apiUrl = "https://app.audienceful.com/api/people/";

    const requestData = {
      email: inputs.email,
      tags: "Signed up Users",
      extra_data: { name: inputs.name },
    };

    const headers = {
      "Content-Type": "application/json",
      "X-Api-Key": apiKey,
    };

    axios
      .post(apiUrl, requestData, { headers })
      .then(function (response) {
        //("Request successful:", response.data);
      })
      .catch(function (error) {
        console.error("Request failed:", error);
      });

    try {
      if (e) e.preventDefault();
      setLoading(true);

      const response = await registerAPI({
        name: inputs.name,
        email: inputs.email,
        password: inputs.password,
      });

      if (response.data) {
        dispatch(
          updateUser({
            userToVerify: {
              id: response.data.user._id,
              name: response.data.user.name,
              email: response.data.user.email,
              token: response.data.token,
              onboardingShown: false,
              isOnboardingCompleted: false,
              plan: response.data.user.plan,
              // token not sent
            },
            onboardingShown: false,
          })
        );

        setError(null);
        setLoading(false);

        // ! REMOVE THIS ONCE RESEND WORKS

        dispatch(
          updateUser({
            ...userToVerify,
            id: response.data.user._id,
            name: response.data.user.name,
            email: response.data.user.email,
            token: response.data.user.token,
            onboardingShown: false,
            isOnboardingCompleted: false,
            plan: response.data.user.plan,
            userToVerify: null,
          })
        );

        setTimeout(() => {
          navigate("/");
        }, 500);

        // setShowPhoneNumber(true);

        // sendVerificationCode();
      }
    } catch (error) {
      setError(error.response.data.message || error.response.data);
      setLoading(false);
    }
  };

  const changeInput = (label, value) => {
    setInputs({
      ...inputs,
      [label]: value,
    });
  };

  const [codeInput, setCodeInput] = useState("");

  const sendVerificationCode = async (e) => {
    if (e) e.preventDefault();

    setLoading(true);
    const response = await sendVerificationCodeAPI({
      // phoneNumber: phoneNumber,
      email: inputs.email,
    });

    if (response.data) {
      setLoading(false);
      setError(null);

      switchSignupContent(true);
    }
  };

  const checkMarkTransition = () => {
    setCheckMarkProps({
      from: {
        opacity: 0,
        marginLeft: -20,
      },
      to: {
        opacity: 1,
        marginLeft: 20,
      },
    });
  };

  const [userVerified, setUserVerified] = useState(false);

  const verifyCode = async () => {
    try {
      setLoading(true);
      const response = await verifyCodeAPI({
        email: userToVerify.email,
        code: parseInt(codeInput),
      });

      if (response.data) {
        setLoading(false);
        setError(null);

        dispatch(
          updateUser({
            ...userToVerify,
            token: response.data.token,
            userToVerify: null,
          })
        );

        setUserVerified(true);
        checkMarkTransition();

        // navigate("/welcome");

        setTimeout(() => {
          // if (redirectLink) {
          //   window.location.replace(redirectLink);
          //   localStorage.removeItem("redirectLink");
          // } else {
          navigate("/");
          // }
        }, 500);
      }
    } catch (error) {
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    // const email = searchParams.get("email");
    // if (!showVerificationCode) {
    //   if (email) {
    //     changeInput("email", email);
    //     nameInputRef.current.focus();
    //   } else {
    //     nameInputRef.current.focus();
    //   }
    // }
  }, []);

  return (
    <animated.div style={fadeInProps} className="loginContainer">
      {/* <div className="loginLeft"></div> */}
      <div className="loginRight">
        <div className="logoContainer">
          <img src="/logoDarkBlue.png" className="loginLogo" />
          <p className="loginLogoText">Airstrip</p>
        </div>

        {!showVerificationCode ? (
          <>
            {showPhoneNumber ? (
              <animated.div style={signupSectionProps}>
                <p className="loginRightHeader">
                  Add your phone number for verification
                </p>

                <p className="loginRightDescription">
                  We will send you a verification code to verify and secure your
                  account.
                </p>

                <form
                  onSubmit={(e) => sendVerificationCode(e)}
                  className="loginRightForm"
                >
                  <div className="loginInputContainer">
                    <p className="loginInputLabel">Phone Number</p>
                    <input
                      // ref={emailInputRef}
                      type="number"
                      required
                      minLength={5}
                      maxLength={13}
                      className="loginInput"
                      placeholder="+11234567890"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      style={{ width: "100%" }}
                    />
                  </div>

                  {error && <p className="errorText">{error}</p>}

                  <button
                    disabled={loading}
                    type="submit"
                    className={`loginButton ${loading && "disabledButton"}`}
                    style={{ opacity: loading ? 0.7 : 1 }}
                    // onClick={() => signup()}
                  >
                    <p className="loginButtonText">Verify Phone Number</p>
                    {loading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        wrapperClass="loginButtonIcon"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <BsArrowRight className="loginButtonIcon" />
                    )}
                  </button>
                </form>
              </animated.div>
            ) : (
              <animated.div style={signupSectionProps}>
                <p className="loginRightHeader">
                  Create an account to get started.
                </p>
                <p className="loginRightDescription">
                  Add your details, to create an account and start preparing
                  your documents.
                </p>

                <form onSubmit={(e) => signup(e)} className="loginRightForm">
                  <div className="loginInputContainers">
                    <div className="loginInputContainer">
                      <p className="loginInputLabel">Name</p>
                      <input
                        ref={nameInputRef}
                        type="text"
                        className="loginInput"
                        placeholder="Ken James"
                        autoFocus
                        value={inputs.name}
                        onChange={(e) => changeInput("name", e.target.value)}
                        required
                        minLength={2}
                      />
                    </div>

                    <div className="loginInputContainer">
                      <p className="loginInputLabel">Email</p>
                      <input
                        ref={emailInputRef}
                        type="email"
                        required
                        minLength={5}
                        maxLength={200}
                        className="loginInput"
                        placeholder="ken@gmail.com"
                        value={inputs.email}
                        onChange={(e) => changeInput("email", e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="loginInputContainer">
                    <p className="loginInputLabel">Password</p>
                    <input
                      type="password"
                      className="loginInput"
                      placeholder="kenspassword"
                      minLength={7}
                      value={inputs.password}
                      onChange={(e) => changeInput("password", e.target.value)}
                      required
                    />
                  </div>

                  {error && <p className="errorText">{error}</p>}

                  <button
                    disabled={!isAllowedToContinueForm(inputs) || loading}
                    type="submit"
                    className={`loginButton ${
                      !isAllowedToContinueForm(inputs) && "disabledButton"
                    }`}
                    style={{ opacity: loading ? 0.7 : 1 }}
                    onClick={() => signup()}
                  >
                    <p className="loginButtonText">Sign up</p>
                    {loading ? (
                      <Oval
                        height={15}
                        width={15}
                        color="white"
                        wrapperStyle={{ marginLeft: 5 }}
                        wrapperClass="loginButtonIcon"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#ffffff7b"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <BsArrowRight className="loginButtonIcon" />
                    )}
                  </button>
                </form>
              </animated.div>
            )}
          </>
        ) : (
          <animated.div style={verificationSectionProps}>
            <div
              className="changeEmailContainer"
              onClick={() => {
                switchSignupContent(false);
              }}
            >
              <IoChevronBackOutline className="changeEmailIcon" />
              <p className="changeEmailText">Change Email</p>
            </div>

            <p className="loginRightHeader">
              Hey {userToVerify?.name}, You're almost there!
            </p>
            <p className="loginRightDescription verificationDescription">
              We have sent a code to <b>{inputs.email}</b>, to verify your
              account.
            </p>

            <form
              onSubmit={(e) => verifyCode(e)}
              className="loginRightForm verificationForm"
            >
              <div className="loginInputContainers verificationCodeInputContainer">
                <div className="loginInputContainer verificationCodeInputContainer">
                  {/* <p className="loginInputLabel">C</p> */}
                  <input
                    autoFocus
                    type="number"
                    className="loginInput verificationCodeInput"
                    placeholder="2314"
                    value={codeInput}
                    onChange={(e) => setCodeInput(e.target.value)}
                    maxLength={4}
                    minLength={4}
                    required
                  />
                </div>
              </div>

              {error && <p className="errorText">{error}</p>}

              <button
                disabled={!isAllowedToContinueForm(inputs) || loading}
                type="submit"
                className={`loginButton verifyButton ${
                  !isAllowedToContinueForm(inputs) && "disabledButton"
                }`}
                style={{ opacity: loading ? 0.7 : 1 }}
                onClick={() => verifyCode()}
              >
                <p className="loginButtonText">Get Started with Airstrip</p>
                {userVerified && (
                  <animated.div
                    style={checkMarkProps}
                    className="loginCheckIconContainer"
                  >
                    <BiCheck className="loginCheckIcon" />
                  </animated.div>
                )}
                {!userVerified &&
                  (loading ? (
                    <Oval
                      height={15}
                      width={15}
                      color="white"
                      wrapperStyle={{ marginLeft: 5 }}
                      wrapperClass="loginButtonIcon"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#ffffff7b"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  ) : (
                    <BsArrowRight className="loginButtonIcon" />
                  ))}
              </button>
            </form>
          </animated.div>
        )}

        {!showVerificationCode && (
          <p className="loginAlreadyHaveAccountText">
            Already have an account?{" "}
            <span className="signupText" onClick={() => navigate("/login")}>
              Sign in
            </span>
          </p>
        )}
      </div>
    </animated.div>
  );
};
