import React, { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import { HiDocument } from "react-icons/hi2";
import { MdOutlineDriveFolderUpload } from "react-icons/md";
import { formatDate, isValidFileType } from "../../functions";
import { useSelector } from "react-redux";
import { BsArrowRight } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { Oval } from "react-loader-spinner";

export const AccountSettings = () => {
  return (
    <div className="contextSetting">
      <div className="contextSettingUploadContainer">
        <p className="contextSettingUploadHeader">Delete all your data </p>

        <p className="contextSettingUploadDescription">
          If you choose to delete your account and all the data associated with
          it, request for deletion and your data will be deleted within 24
          hours.
        </p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="ButtonMain"
            style={{ width: "250px", backgroundColor: "red" }}
          >
            Request to Delete All My Data
          </div>

          <p
            style={{
              marginLeft: "20px",
              fontSize: "14px",
              opacity: 0.7,
              marginTop: "15px",
            }}
          >
            We received your request! We will delete your data within and 24
            hours and contact you if we need any information.
          </p>
        </div>
      </div>
    </div>
  );
};
