import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Document, Page, pdfjs } from "react-pdf";
import { DocumentViewerLoader } from "../DocumentViewer/loader";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  return (
    <div className="pdfViewer">
      <Document
        className="pdfViewerDocument"
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<DocumentViewerLoader />}
      >
        {new Array(numPages).fill(10).map((index, pageNumber) => (
          <Page className="pdfViewerPage" pageNumber={pageNumber + 1} />
        ))}
      </Document>
    </div>
  );
};
