import React from "react";
import ContentLoader from "react-content-loader";

export const DocumentDetailLoader = () => {
  return (
    <div className="skeletonLoader">
      <div className="skeletonLoaderElement skeletonHeader"></div>
      <div className="skeletonParagraphGroup">
        <div className="skeletonLoaderElement skeletonParagraph"></div>
        <div className="skeletonLoaderElement skeletonParagraph"></div>
        <div className="skeletonLoaderElement skeletonParagraph"></div>
      </div>

      <div className="skeletonLoaderElement skeletonDivider"></div>

      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div>
      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div>
      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div>

      <div className="skeletonLoaderElement skeletonDivider"></div>

      <div className="skeletonLoaderElement skeletonButton"></div>
    </div>
  );
};
