import React, { useState } from "react";
import { Navbar } from "../components/Navbar";
import { ContextSettings } from "../components/Settings/ContextSettings";
import { AccountSettings } from "../components/Settings/ProfileSettings";

const componentsOfSettings = {
  context: <ContextSettings />,
  account: <AccountSettings />,
};

export const Settings = () => {
  const [selectedSetting, setSelectedSetting] = useState("account");

  return (
    <div className="settingsContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Settings</h1>
          <p className="rightContainerHeaderDescription">Manage your account</p>
        </div>

        <div className="settingsContent">
          <div className="settingsButtons">
            <div
              className={`eachSettingButton ${
                selectedSetting === "account" && "selectedSettingButton"
              }`}
              onClick={() => setSelectedSetting("account")}
            >
              <p className="eachSettingButtonText">Account</p>
            </div>
            {/* 
            <div
              className={`eachSettingButton ${
                selectedSetting === "context" && "selectedSettingButton"
              }`}
              onClick={() => setSelectedSetting("context")}
            >
              <p className="eachSettingButtonText">Context</p>
            </div> */}

            {/* <div
              className={`eachSettingButton ${
                selectedSetting === "accountSettings" && "selectedSettingButton"
              }`}
              onClick={() => setSelectedSetting("accountSettings")}
            >
              <p className="eachSettingButtonText">Account Settings</p>
            </div> */}
          </div>

          <div className="eachSettingContent">
            {componentsOfSettings[selectedSetting]}
          </div>
        </div>
      </div>
    </div>
  );
};
