export const UPDATE_STEPPER = "UPDATE_STEPPER";
export const PUSH_TO_STEPS = "PUSH_TO_STEPS";
export const ADD_NEW_VALUE = "ADD_NEW_VALUE";

const initialState = {
  currentStep: 1,
  steps: [],
  documentDetails: null,
  warnings: [],
  mainStep: 1,
  reviewItems: [],
  flowStatus: "",
  loading: false,
  pendingFlows: [],
};

export const stepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STEPPER:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_NEW_VALUE:
      return {
        ...state,
        pendingFlows: [],
      };
    case PUSH_TO_STEPS:
      return {
        ...state,
        steps: [...state.steps, action.payload],
      };
    default:
      return state;
  }
};
