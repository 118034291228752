import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
  Hr,
} from "@react-email/components";
import * as React from "react";

const baseUrl = "https://useairstrip.com";

export const VerificationCode = ({ validationCode }) => (
  <Html>
    <Head />
    <Preview>Confirm your email address</Preview>
    <Body style={main}>
      <Container style={container}>
        <Section style={logoContainer}>
          <Img
            src={`https://res.cloudinary.com/dcfwksv4l/image/upload/v1705825620/pimhjcypv4xxmmrjs5au.png`}
            width="106"
            height="50"
            alt="Slack"
            style={
              {
                // textAlign: "center",
                // verticalAlign: "middle",
                // margin: "0 auto",
              }
            }
          />
          {/* 
          <Text style={logoDescription}>
            - Lawyer level legal documents for startups -
          </Text> */}
        </Section>
        <Heading
          style={{
            ...h1,
            fontSize: "20px",
            marginBottom: "0px",
            marginTop: "20px",
          }}
        >
          One Last Step...
        </Heading>
        <Heading style={{ ...h1, marginTop: "10px" }}>
          Verify your Account
        </Heading>
        <Text style={heroText}>
          Here is the 4-digit verification code below — copy and paste this in
          the input and let's get you signed in.
        </Text>

        <Section style={codeBox}>
          <Text style={confirmationCodeText}>4917</Text>
        </Section>

        {/* <Text style={text}>
          If you didn't request this email, there's nothing to worry about, you
          can safely ignore it.
        </Text> */}

        <Hr />

        <Text
          style={{
            ...heroText,
            fontSize: "16px",
            marginBottom: "10px",
            // fontWeight: "500",
            marginTop: "30px",
          }}
        >
          Get the latest updates
        </Text>

        <Section>
          <Link
            style={footerLink}
            href="https://slackhq.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </Link>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <Link
            style={footerLink}
            href="https://slack.com/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
          {/* &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <Link
            style={footerLink}
            href="https://slack.com/help"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help center
          </Link>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <Link
            style={footerLink}
            href="https://slack.com/community"
            target="_blank"
            rel="noopener noreferrer"
            data-auth="NotApplicable"
            data-linkindex="6"
          >
            Slack Community
          </Link> */}
          <Text style={footerText}>
            ©2024 Airstrip AI
            <br />
            All rights reserved.
          </Text>
        </Section>
      </Container>
    </Body>
  </Html>
);

const footerText = {
  fontSize: "12px",
  color: "#b7b7b7",
  lineHeight: "15px",
  textAlign: "left",
  marginBottom: "50px",
};

const footerLink = {
  color: "#00000090",
  textDecoration: "underline",
  fontSize: "15px",
};

const footerLogos = {
  marginBottom: "32px",
  paddingLeft: "8px",
  paddingRight: "8px",
  width: "100%",
};

const socialMediaIcon = {
  display: "inline",
  marginLeft: "32px",
};

const main = {
  backgroundColor: "#2146D109",
  margin: "0 auto",
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI'",
  padding: "0px 0",
  paddingTop: "20px",
  paddingBottom: "50px",
};

const container = {
  margin: "0 auto",
  padding: "0px 50px",
  // filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
  borderRadius: 4,
  width: "700px",
  maxWidth: "100%",
  backgroundColor: "#ffffff",
};

const logoContainer = {
  marginTop: "30px",
};

const logoDescription = {
  fontSize: "16px",
  lineHeight: "28px",
  marginBottom: "0px",
  letterSpacing: "-0.1px",
  textAlign: "center",
};

const h1 = {
  color: "#1d1c1d",
  fontSize: "34px",
  fontWeight: "700",
  margin: "30px 0",
  padding: "0",
  lineHeight: "42px",
  marginBottom: "10px",
  letterSpacing: "-0.3px",
};

const heroText = {
  fontSize: "16px",
  lineHeight: "28px",
  marginBottom: "30px",
  letterSpacing: "-0.1px",
};

const codeBox = {
  borderRadius: "4px",
  marginBottom: "30px",
  padding: "20px 10px",
};

const confirmationCodeText = {
  fontSize: "40px",
  textAlign: "center",
  verticalAlign: "middle",
};

const text = {
  color: "#000",
  fontSize: "14px",
  lineHeight: "24px",
};
