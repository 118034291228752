import React, { useEffect, useRef, useState } from "react";
import { BsFillChatDotsFill, BsStars } from "react-icons/bs";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import {
  FiMaximize2,
  FiMinimize2,
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import { IoClose, IoSend } from "react-icons/io5";

import { animated, easings, useSpring } from "@react-spring/web";
import { useNavigate, useParams } from "react-router-dom";

import { EachDocument } from "../components/EachDocument";
import { updateChat } from "../redux/actions/chatAction";
import { createDocumentAPI } from "../api/apiCalls";
import {
  chatRecommendationsDuringProcess,
  defaultChatRecommendations,
} from "../data";
import RadixModal from "../components/Modal/RadixModal";
import { Tooltip } from "../components/Tooltip";
import { BiLink } from "react-icons/bi";

export const ChatWithAI = () => {
  const { chatOpen, defaultState, chatContext } = useSelector(
    (state) => state.chat
  );

  const { allDocuments, context } = useSelector((state) => state.document);
  const { steps, currentStep, stepTitles } = useSelector(
    (state) => state.stepper
  );

  const [chats, setChats] = useState([]);

  const { id } = useParams();

  const [streamMessage, setStreamMessage] = useState("");

  const [isChatInputFocused, setIsChatInputFocused] = useState(false);

  const [chatState, setChatState] = useState({
    text: "",
    fullScreen: false,
  });

  const [chatSectionProps, setChatSectionProps] = useSpring(() => ({
    width: "800px",
    height: "95%",
    opacity: 0,
    x: 0,
    y: 0,
  }));

  const { token, ...userData } = useSelector((state) => state.user);
  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [chatSectionOverlayProps, setChatSectionOverlayProps] = useSpring(
    () => ({
      backgroundColor: "#191f2f92",
    })
  );

  useEffect(() => {
    setChatSectionOverlayProps({
      from: {
        backgroundColor: "#191f2f92",
      },
      to: {
        backgroundColor: "#191f2fcd",
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    setChatSectionProps({
      from: {
        opacity: 0,
        y: 30,
      },
      to: {
        opacity: 1,
        y: 0,
      },
      config: {
        easing: easings.easeInElastic(10),
      },
    });

    if (chatContext) {
      setChats((prev) => [
        ...prev,
        {
          role: "system",
          content: `
          The user is currently in a questionnaire for preparing a ${
            chatContext.title
          }.

          Current questionnaire details:<>
          Question: ${chatContext.question},
          Answer: ${steps[currentStep - 1].inputs?.value},
          Options/Suggestions: ${JSON.stringify(
            steps[currentStep - 1].answers
          )}<>
          More Info if needed: ${context}

          Make sure you provide assistance in plain language. You should recommend what is best for the company, if user need's to know what option/suggestion is best, make sure to recommend the best option or combination of options or a even different answer depending on what's best for the company, if needed clarify certain things you need from the user to make the best decision.  Make sure to format your response properly. Keep your assistance short and clear.
        `,
        },
        {
          role: "system",
          content:
            "Do not recommend Airstrip's legal agreement drafting feature.",
        },
      ]);
    }
  }, []);

  const navigate = useNavigate();

  const updateChatState = (label, value) => {
    setChatState({
      ...chatState,
      [label]: value,
    });
  };

  const toggleExpandChat = (value) => {
    if (value === "expand") {
      updateChatState("fullScreen", true);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2fcd",
        },
        to: {
          backgroundColor: "#191f2f",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "800px",
          height: "95%",
        },
        to: {
          width: "1300px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    } else if (value === "shrink") {
      updateChatState("fullScreen", false);

      setChatSectionOverlayProps({
        from: {
          backgroundColor: "#191f2f",
        },
        to: {
          backgroundColor: "#191f2fcd",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });

      setChatSectionProps({
        from: {
          width: "1300px",
          height: "95%",
        },
        to: {
          width: "800px",
          height: "95%",
        },
        config: {
          easing: easings.easeInElastic(10),
        },
      });
    }
  };

  const closeChat = () => {
    dispatch(
      updateChat({
        chatContext: null,
      })
    );
    navigate(-1);
  };

  //

  const [documentsToRecommend, setDocumentsToRecommend] = useState([]);

  const [tempDocuments, setTempDocuments] = useState([]);

  const addDocumentsToChat = (documentsWithId) => {
    const newArray = documentsWithId.map((tempDocumentId) => {
      const realDocument = allDocuments.filter(
        (document) => document._id === tempDocumentId
      )[0];

      return {
        name: realDocument.title,
        id: realDocument._id,
      };
    });

    return newArray;
  };

  const getDocumentInfo = (documentId) => {
    const validDocument = allDocuments.filter(
      (document) => document._id === documentId
    )[0];

    let document;

    if (validDocument) {
      const isDocumentAlreadyAdded = documentsToRecommend.filter(
        (document) => document.id === documentId
      )[0];

      if (isDocumentAlreadyAdded) {
        return null;
      } else {
        document = {
          name: validDocument.title,
          id: validDocument._id,
        };
      }
    } else {
      return null;
    }

    return document;
  };

  //

  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const [inputText, setInputText] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  useEffect(() => {
    if (streamMessage.length > 0 && streamStatus === "COMPLETED") {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          content: streamMessage,
          documents: [...addDocumentsToChat(tempDocuments)],
        },
      ]);
      setTempDocuments([]);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }
  }, [streamStatus]);

  const [documentIds, setDocumentIds] = useState(null);

  const readStream = async (stream) => {
    // setStreamMessageProps({
    //   from: { opacity: 0 },
    //   to: { opacity: 1 },
    // });
    const reader = stream.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        // setChats([...newChatsArray]);
        const element = document.querySelectorAll(".chatSectionChats");
        element[0].scrollTo(0, element[0].scrollHeight);

        setStreamStatus("COMPLETED");
        focusSearchInput();
        return;
      }

      const populatStreams = (object) => {
        if (document) {
          const element = document.getElementsByClassName("chatSectionChats");
          element[0].scrollTo(0, element[0].scrollHeight);
        }
        setStreamMessage((prev) => {
          return prev + object.data?.delta?.content;
        });
      };

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data?.delta) {
          populatStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data?.delta) {
              populatStreams(object);
            }
          });
      }
    }
  };

  const handleSubmit = async (customInputText) => {
    // // //'custom:', customInputText)

    setStreamStatus("PENDING");

    const userInputText =
      typeof customInputText === "string" && customInputText
        ? customInputText
        : inputText;

    let loadedChats = [];
    await setChats((prev) => {
      loadedChats = [...prev, { role: "user", content: userInputText }];
      return [...prev, { role: "user", content: userInputText }];
    });
    setInputText("");
    inputRef.current.value = "";

    if (document) {
      const element = document.getElementsByClassName("chatSectionChats");
      element[0].scrollTo(0, element[0].scrollHeight);
    }

    const newLoadedChatsArray = loadedChats.map((loadedChat) => {
      const { documents: loadedChatDocuments, ...otherLoadedChatInfo } =
        loadedChat;

      return {
        ...otherLoadedChatInfo,
      };
    });

    // // // //chats);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot`, {
      body: JSON.stringify({
        messages: newLoadedChatsArray,
      }),
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        api_key: "krishna",
        Authorization: token,
      },
    });
    if (response.ok) {
      const stream = response.body;
      await readStream(stream);
    } else {
      setStreamStatus("NOT_STARTED");
      console.error(`Failed to fetch stream: ${response.statusText}`);
    }
  };

  // // // //documentIds);

  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const handleChatShortcut = (e) => {
    // e.preventDefault();

    // // // //"key", e);

    if (e.keyCode === 13 && e.metaKey && inputText.trim().length !== 0) {
      handleSubmit();
    }
  };

  let documents = [];

  useEffect(() => {
    streamMessage.split("**").map((part, index) => {
      // // // //"temp document: ", part.split(" "));

      if (index) {
        if (index % 2 === 0) {
          return null;
        } else {
          const documentId = part
            .split(" ")
            [part.split(" ").length - 1].slice(1, -1);

          // // // //"documents: ", documents);

          // // // //"already added temp docu:", isTempDocumentAlreadyAdded);

          if (documentId.length === 24) {
            // // //"document ID: ", documentId);
            const isTempDocumentAlreadyAdded = documents.filter(
              (document) => document === documentId
            )[0];

            if (!isTempDocumentAlreadyAdded) {
              documents.push(documentId);
              setTempDocuments(documents);
            }

            // if (!isTempDocumentAlreadyAdded) {
            //   setTempDocuments((prev) => [
            //     ...prev,
            //     part.split(" ")[part.split(" ").length - 1].slice(1, -1),
            //   ]);
            // }
            // // // //
            //   "ID:",
            //   part.split(" ")[part.split(" ").length - 1].slice(1, -1)
            // );
          }
        }
      }

      // part.split(" ").map((eachPart) => {
      //   if (eachPart.indexOf("[") !== -1) {
      //     // // //"document id: ", eachPart.slice(1, -1));

      //     setTempDocuments([...tempDocuments, eachPart.slice(1, -1)]);
      //   }
      // });
    });
  }, [streamMessage]);

  const isChatEmpty = () => {
    return (
      chats.filter((chat) => chat.role === "assistant" || chat.role === "user")
        .length === 0
    );
  };

  const [feedbackType, setFeedbackType] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const sendFeedbackEvent = (type, text) => {
    //
  };

  const triggerComponent = () => {
    return (
      <div className="chatFeedbackButton">
        <p className="chatFeedbackText">How was this response?</p>
        <FiThumbsUp
          className="chatFeedbackButtonIcon thumbsUp"
          onClick={() => {
            sendFeedbackEvent("good");
            setFeedbackType("good");
          }}
        />
        <FiThumbsDown
          className="chatFeedbackButtonIcon thumbsDown"
          onClick={() => {
            sendFeedbackEvent("bad");
            setFeedbackType("bad");
          }}
        />
      </div>
    );
  };

  const EachChat = ({ chat }) => {
    return (
      <div className="eachChatContainer">
        <div
          className={`eachChat processEachChat ${
            chat.role === "assistant"
              ? "leftChat"
              : chat.role === "user" && "rightChat"
          }`}
        >
          <p
            className={`eachChatType ${
              chat.role === "assistant" && "assistantChatType"
            }`}
          >
            {chat.role === "assistant" ? "Pilot" : `${userData.name} (You)`}
          </p>
          {/* 
                        <img
                          src="https://media.tenor.com/VinSlhZc6jIAAAAC/meme-typing.gif"
                          className="typingLoadingGif"
                        /> */}

          <p className="eachChatText">
            {" "}
            {chat.content.split("**").map((part, index) => {
              if (index % 2 === 0) {
                return part;
              } else {
                return (
                  <strong className="font-bold " key={index}>
                    {part.indexOf("[") === -1
                      ? part
                      : part.substring(0, part.indexOf("["))}
                  </strong>
                );
              }
            })}
          </p>

          {chat.documents && chat.documents.length !== 0 && (
            <div className="chatDocumentSuggestions">
              {/* <p className="documentSuggestionsHeaderText">Documents</p> */}
              <animated.div
                style={fadeInProps}
                className="chatDocumentSuggestionsList"
              >
                {chat.documents.map((eachDocument) => (
                  <animated.div
                    style={fadeInProps}
                    className="eachDocumentSuggestion"
                  >
                    <EachDocument
                      openInNewTab={true}
                      eachDocument={eachDocument}
                      size="small"
                    />
                  </animated.div>
                ))}
              </animated.div>
            </div>
          )}
          {/* 
          {chat.role === "assistant" && (
            <RadixModal
              title="Share additional Feedback"
              description="Let us know your thoughts about the response."
              triggerComponent={triggerComponent}
              buttonText="Send"
              openModal={openModal}
              setOpenModal={setOpenModal}
              onClick={() => {
                // send feedback
                sendFeedbackEvent(feedbackType, feedbackText);
              }}
            >

              <fieldset className="Fieldset">
                <label className="Label" htmlFor="username">
                  Feedback (optional)
                </label>
                <textarea
                  className="TextArea"
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                  autoFocus
                  placeholder="What can be improved more?"
                  id="username"
                />
              </fieldset>
            </RadixModal>
          )} */}
        </div>

        {/* {JSON.stringify(chat.documents)} */}
      </div>
    );
  };

  return (
    <animated.div className="chatWithAIContainer">
      {!chatOpen && (
        <div className="chatPopupContainer">
          <animated.div
            style={chatSectionOverlayProps}
            className={`chatPopupOverlay
            `}
            onClick={() => closeChat()}
          ></animated.div>

          <animated.div style={chatSectionProps} className="chatMainSection">
            <div className="chatMainSectionHeader">
              <div className="chatPopupHeader">
                <div
                  className="chatPopupHeaderLeft"
                  style={{ padding: "13px 15px" }}
                >
                  <BsStars className="chatPopupHeaderIcon" />

                  <div className="chatHeaderRight" style={{ marginLeft: 10 }}>
                    <p className="chatPopupHeaderLeftText">Ask Pilot AI</p>
                    <p className="chatHeaderLeftDescription">
                      Have any questions before moving forward? Get if cleared
                      here.
                    </p>
                  </div>

                  {/* {chatContext ? (
                    <Tooltip
                      text={`Ask any questions related to this question: ${chatContext.question}`}
                    >
                      <div className="chatContext">
                        <BiLink className="chatContextIcon" />
                        <p className="chatContextText">
                          {chatContext.title.substring(0, 100)}
                        </p>
                        <IoClose
                          className="chatContextCloseIcon"
                          onClick={() => {
                            dispatch(
                              updateChat({
                                chatContext: null,
                              })
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      onClick={() => {
                        dispatch(
                          updateChat({
                            chatContext: {
                              title: stepTitles[currentStep - 1].title,
                              question: steps[currentStep - 1].title,
                              documentInfo: {
                                id: id,
                                title: stepTitles[currentStep - 1].title,
                                question: steps[currentStep - 1].title,
                                step: currentStep - 1,
                              },
                            },
                          })
                        );
                      }}
                      className="chatContext"
                      style={{
                        opacity: 1,
                        cursor: "pointer",
                        backgroundColor: "rgba(0,0,0,0.03)",
                        paddingLeft: 14,
                      }}
                    >
                      <p className="chatContextText">Link with this question</p>
                    </div>
                  )} */}
                </div>

                <div className="chatPopupHeaderRight">
                  {chatState.fullScreen ? (
                    <FiMinimize2
                      className="chatRightIcon"
                      onClick={() => toggleExpandChat("shrink")}
                    />
                  ) : (
                    <FiMaximize2
                      className="chatRightIcon"
                      onClick={() => toggleExpandChat("expand")}
                    />
                  )}

                  <IoClose
                    className="chatRightIcon"
                    onClick={() => closeChat()}
                  />
                </div>
              </div>
            </div>

            <div className="chatSectionChats processEachChatContainer">
              {chats
                .filter((chat) => chat.role !== "system")
                .map((chat) => (
                  <EachChat chat={chat} />
                ))}

              {streamStatus === "PENDING" && (
                <div className="eachChatContainer">
                  <div
                    id="temp_chat"
                    className={`eachChat processEachChat ${
                      chatState.fullScreen && "fullScreenEachChat"
                    } ${"leftChat"}`}
                  >
                    <p className={`eachChatType assistantChatType`}>Pilot</p>

                    <p className="eachChatText">
                      {streamMessage.length === 0 && (
                        <img
                          src="https://media.tenor.com/VinSlhZc6jIAAAAC/meme-typing.gif"
                          className="typingLoadingGif"
                        />
                      )}

                      {streamMessage.split("**").map((part, index) => {
                        if (index % 2 === 0) {
                          return part;
                        } else {
                          // // // //
                          //   part
                          //     .split(" ")
                          //     [part.split(" ").length - 1].slice(1, -1)
                          // );

                          // // // //part.length - part.indexOf("["));
                          return (
                            <strong className="font-bold" key={index}>
                              {part.indexOf("[") === -1
                                ? part
                                : part.substring(0, part.indexOf("["))}
                            </strong>
                          );
                        }
                      })}
                    </p>

                    {tempDocuments && tempDocuments.length !== 0 && (
                      <div className="chatDocumentSuggestions">
                        <p className="documentSuggestionsHeaderText">
                          Documents
                        </p>
                        <animated.div
                          style={fadeInProps}
                          className="chatDocumentSuggestionsList"
                        >
                          {tempDocuments.map((eachDocument) => (
                            <animated.div
                              style={fadeInProps}
                              className="eachDocumentSuggestion"
                            >
                              <EachDocument
                                openInNewTab={true}
                                eachDocument={getDocumentInfo(eachDocument)}
                                size="small"
                              />
                            </animated.div>
                          ))}
                        </animated.div>
                      </div>
                    )}
                  </div>
                  {/* {JSON.stringify(tempDocuments)} */}
                </div>
              )}

              {isChatEmpty() && (
                <div className="chatExamples">
                  <p className="chatExamplesHeader">
                    <BsFillChatDotsFill className="chatExamplesHeaderIcon" />{" "}
                    Quick Question
                  </p>

                  {chatContext
                    ? chatRecommendationsDuringProcess.map(
                        (chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              // // //chatRecommendation)

                              handleSubmit(chatRecommendation);
                            }}
                          >
                            <div className="chatExampleTextContent">
                              {chatRecommendation}
                            </div>

                            <IoSend className="chatExampleIcon" />
                          </div>
                        )
                      )
                    : defaultChatRecommendations(
                        userData.companyName,
                        userData.country
                      ).map((chatRecommendation) => (
                        <div
                          className="chatExampleText"
                          onClick={() => {
                            setInputText(chatRecommendation);
                            handleSubmit(chatRecommendation);
                          }}
                        >
                          <p className="chatExampleTextContent">
                            {chatRecommendation}
                          </p>

                          <IoSend className="chatExampleIcon" />
                        </div>
                      ))}
                </div>
              )}
            </div>

            <div
              className={`chatInputSection ${
                isChatInputFocused && "activeChatInputSection"
              }`}
            >
              {!isChatEmpty() && (
                <div className="chatSuggestions processChatSuggestions">
                  {chatContext
                    ? chatRecommendationsDuringProcess.map(
                        (chatRecommendation) => (
                          <div
                            className="chatExampleText"
                            onClick={() => {
                              setInputText(chatRecommendation);
                              // // //chatRecommendation)

                              handleSubmit(chatRecommendation);
                            }}
                          >
                            {chatRecommendation}
                          </div>
                        )
                      )
                    : defaultChatRecommendations(
                        userData.companyName,
                        userData.country
                      ).map((chatRecommendation) => (
                        <div
                          className="chatExampleText"
                          onClick={() => {
                            setInputText(chatRecommendation);
                            handleSubmit(chatRecommendation);
                          }}
                        >
                          {chatRecommendation}
                        </div>
                      ))}
                </div>
              )}

              <form
                onSubmit={(e) => {
                  handleChatShortcut(e);
                }}
                className="chatInputContainer"
              >
                <textarea
                  onFocus={() => setIsChatInputFocused(true)}
                  onBlur={() => setIsChatInputFocused(false)}
                  autoFocus
                  ref={inputRef}
                  value={inputText}
                  onChange={(element) => {
                    setInputText(element.target.value);
                    inputRef.current.style.height = "5px";
                    inputRef.current.style.height =
                      inputRef.current.scrollHeight + "px";
                  }}
                  onKeyDown={(e) => {
                    handleChatShortcut(e);
                  }}
                  type="text"
                  className="chatInput"
                  id="chatInput"
                  placeholder={
                    chatContext
                      ? "Ask: What is the best option for me?"
                      : "ex: Can you help me with preparing for starting up a SaaS startup in US, California?"
                  }
                />

                <button
                  type="submit"
                  disabled={
                    streamStatus === "PENDING" || inputText.trim().length === 0
                  }
                  className={`chatInputButton ${
                    (streamStatus === "PENDING" ||
                      inputText.trim().length === 0) &&
                    "disabledButton"
                  }`}
                  onClick={handleSubmit}
                >
                  <RiSendPlaneFill className="chatInputButtonIcon" />
                </button>
              </form>
            </div>
          </animated.div>
        </div>
      )}
    </animated.div>
  );
};
