import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { Oval } from "react-loader-spinner";
import { EachDocument } from "../components/EachDocument";
import { getAllDocumentsAPI } from "../api/apiCalls";
import { useDispatch } from "react-redux";
import { updateDocument } from "../redux/actions/documentActions";
import { iconsForDocumentCategories } from "../data";

export const BrowseDocuments = () => {
  const dispatch = useDispatch();

  const [formattedDocuments, setFormattedDocuments] = useState([]);

  const scenarioTextState = useState("");

  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);

  const getAllDocuments = async () => {
    setAllDocumentsLoading(true);

    const response = await getAllDocumentsAPI();

    let documents = [];

    if (response.data) {
      setFormattedDocuments(response.data);

      response.data.map((documentObject) => {
        documentObject.documents.map((eachDocument) => {
          documents.push(eachDocument);
        });
      });

      dispatch(
        updateDocument({
          allDocuments: documents,
        })
      );

      setAllDocumentsLoading(false);
    }
  };

  const [searchDocumentsInput, setSearchDocumentsInput] = useState("");

  useEffect(() => {
    const [text] = scenarioTextState;
    // // //"scenario: ", text);
  }, [scenarioTextState]);

  const [documentsList, setDocumentsList] = useState([]);

  const getDocuments = async () => {
    const response = await getAllDocumentsAPI();

    if (response.data) {
      // // //response.data);
      setDocumentsList(response.data);
    }
  };

  useEffect(() => {
    getDocuments();
    getAllDocuments();
  }, []);

  return (
    <div className="browseDocumentsContainer">
      <Navbar />

      <div className="rightContainer">
        <div className="documentsContainer">
          {/* <div className="reviewAndRewriteContainer">
            <BsStars className="reviewAndRewriteIcon" />
            <div className="reviewAndRewriteInfo">
              <p className="reviewAndRewriteText">
                Upload your own Document for Review
              </p>
              <p className="reviewAndRewriteDescription">
                Our AI will review your document, find any errors, and will
                provide the corrected re-written document.
              </p>
            </div>

            <div className="reviewAndRewriteButton">
              <p className="reviewAndRewriteButtonText">
                Review your own document
              </p>
              <FiChevronRight className="reviewAndRewriteButtonIcon" />
            </div>
          </div> */}

          <div className="rightContainerHeader">
            <h1 className="rightContainerHeaderText">Browse all Documents</h1>
            <p className="rightContainerHeaderDescription">
              Browse documents that are essential for businesses, freelancers,
              startups, choose what you need, and start creating instantly.
            </p>
          </div>

          <div className="documents">
            {allDocumentsLoading ? (
              <div className="documentsLoadingContainer">
                <Oval
                  height={25}
                  width={25}
                  color="#1253f3"
                  wrapperStyle={{}}
                  wrapperClass="modalMainContentButtonLoader"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1253f340"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
                <p className="documentsLoadingText">
                  Getting all the documents...
                </p>
              </div>
            ) : (
              formattedDocuments?.map((documentObject) => (
                <div className="eachDocumentList">
                  <div className="eachDocumentListHeader">
                    {iconsForDocumentCategories[documentObject.type]}
                    <p className="eachDocumentListHeaderText">
                      {documentObject.type}
                    </p>
                  </div>

                  <div className="documentsList">
                    {documentObject.documents.map((eachDocument, index) => (
                      <EachDocument
                        key={index}
                        eachDocument={{
                          name: eachDocument.title,
                          id: eachDocument._id,
                        }}
                      />
                    ))}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
