import React, { useState } from "react";
import { BsArrowRight, BsPatchCheckFill } from "react-icons/bs";
// import { FiChevronRight } from "react-icons/fi";
import backgroundImage from "../backgroundImage.jpg";
import { useNavigate } from "react-router-dom";
import { easings, useSpring } from "@react-spring/web";
import { animated } from "@react-spring/web";
import {
  resetPasswordAPI,
  sendVerificationCodeAPI,
  signinAPI,
  verifyCodeAPI,
} from "../api/apiCalls";
import { isAllowedToContinueForm } from "../functions";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/actions/userAction";
import { BiCheck } from "react-icons/bi";
import { RiLockPasswordFill } from "react-icons/ri";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const redirectLink =
    typeof localStorage.getItem("redirectLink") === "string"
      ? JSON.parse(localStorage.getItem("redirectLink"))
      : null;

  const [fadeInProps, setFadeInProps] = useSpring(() => ({
    from: {
      opacity: 0.5,
    },
    to: {
      opacity: 1,
    },
    config: {
      easing: easings.easeInElastic(20),
    },
  }));

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [signupSectionProps, setSignupSectionProps] = useSpring(() => ({
    opacity: 1,
  }));

  const [verificationSectionProps, setVerificationSectionProps] = useSpring(
    () => ({
      opacity: 0,
    })
  );

  const [checkMarkProps, setCheckMarkProps] = useSpring(() => ({
    opacity: 0,
    marginLeft: 40,
  }));

  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const switchSignupContent = (showVerification) => {
    if (showVerification) {
      setSignupSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          x: -40,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(true);

        setVerificationSectionProps({
          from: {
            opacity: 0,
            x: 40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    } else {
      setVerificationSectionProps({
        from: {
          opacity: 1,
          x: 0,
        },
        to: {
          opacity: 0,
          X: 40,
        },
      });

      setTimeout(() => {
        setShowVerificationCode(false);

        setSignupSectionProps({
          from: {
            opacity: 0,
            x: -40,
          },
          to: {
            opacity: 1,
            x: 0,
          },
        });
      }, 150);
    }
  };

  // ! ONLY SHOW VERIFICATION IS USER IS NOT VERIFIED
  // ! GET IS USER VERIFIED FROM API
  // ! VERIFY USER

  const [loginUserInfo, setLoginUserInfo] = useState(null);

  const [error, setError] = useState(null);

  const changeInput = (label, value) => {
    setInputs({
      ...inputs,
      [label]: value,
    });
  };

  const ifLoginSuccessful = (responseData) => {
    dispatch(
      updateUser({
        id: responseData.user._id,
        name: responseData.user.name,
        email: responseData.user.email,
        token: responseData.token,
        onboardingShown: true,
        isOnboardingCompleted: responseData.user.isOnboardingCompleted,
        companyName: responseData.user.companyName,
        country: responseData.user.country,
        plan: responseData.user.plan,
      })
    );

    setTimeout(() => {
      setLoading(false);

      navigate("/");
    }, 2000);
  };

  const login = async (e) => {
    try {
      if (e) e.preventDefault();
      setLoading(true);

      const response = await resetPasswordAPI({
        email: inputs.email,
        password: inputs.password,
      });

      if (response.data || response.data.success) {
        ifLoginSuccessful(response.data);
      }
    } catch (error) {
      setError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <animated.div style={fadeInProps} className="loginContainer">
      {/* <div className="loginLeft"></div> */}

      <div className="loginRight">
        <div className="logoContainer">
          <img src="/logoDarkBlue.png" className="loginLogo" />
          <p className="loginLogoText">Airstrip</p>
        </div>
        <animated.div style={signupSectionProps}>
          <p className="loginRightHeader">Reset your password.</p>

          <form onSubmit={(e) => login(e)} className="loginRightForm">
            <div className="loginInputContainer">
              <p className="loginInputLabel">Email</p>
              <input
                type="email"
                className="loginInput"
                placeholder="ken@gmail.com"
                autoFocus
                value={inputs.email}
                onChange={(e) => changeInput("email", e.target.value)}
              />
            </div>

            <div className="loginInputContainer">
              <p className="loginInputLabel">New Password</p>
              <input
                type="text"
                className="loginInput"
                placeholder="kenspassword"
                value={inputs.password}
                onChange={(e) => changeInput("password", e.target.value)}
              />
            </div>

            {error && <p className="errorText">{error}</p>}

            <button
              disabled={!isAllowedToContinueForm(inputs) || loading}
              type="submit"
              className={`loginButton ${
                !isAllowedToContinueForm(inputs) && "disabledButton"
              }`}
              style={{ opacity: loading ? 0.7 : 1 }}
              onClick={() => login()}
            >
              <p className="loginButtonText">Reset Password</p>
              {loading ? (
                <Oval
                  height={15}
                  width={15}
                  color="white"
                  wrapperStyle={{ marginLeft: 5 }}
                  wrapperClass="loginButtonIcon"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#ffffff7b"
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              ) : (
                <BsArrowRight className="loginButtonIcon" />
              )}
            </button>
          </form>
        </animated.div>
      </div>
    </animated.div>
  );
};
