import { Crisp } from "crisp-sdk-web";
import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { CgClose, CgFileDocument } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/actions/userAction";

export const CreateDocumentModal = () => {
  const [documentFilterTag, setDocumentFilterTag] = useState(" ");

  const [documentSearchInput, setDocumentSearchInput] = useState("");

  const [selectedDocument, setSelectedDocument] = useState(null);

  const { allDocuments } = useSelector((state) => state.document);

  const [docsInfo, setDocsInfo] = useState([]);

  const dispatch = useDispatch();

  const [filteredAllDocuments, setFilteredAllDocuments] =
    useState(allDocuments);

  useEffect(() => {
    let localDocsInfo = allDocuments;

    // console.log("all docs: ", allDocuments);

    // Use reduce to group objects by tags
    const groupedData = allDocuments.reduce((result, item) => {
      const { tag } = item;

      // Find the index of the tag in the result array
      const tagIndex = result.findIndex((group) => group.type === tag);

      // If the tag is not found, create a new group object
      if (tagIndex === -1) {
        result.push({ type: tag, documents: [] });
      }

      // Push the current item into the documents array for the corresponding tag
      result[tagIndex !== -1 ? tagIndex : result.length - 1].documents.push(
        item
      );

      return result;
    }, []);

    const filteredDocsInfo = groupedData.sort((a, b) => {
      const textA = a.type.toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const textB = b.type.toUpperCase();

      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
    setDocsInfo(filteredDocsInfo);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.title
        .toLowerCase()
        .includes(documentSearchInput.toLowerCase())
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentSearchInput]);

  useEffect(() => {
    setDocumentSearchInput("");
    let newFilteredAllDocuments = allDocuments.filter((eachDocument) =>
      eachDocument.tag.includes(documentFilterTag)
    );
    setFilteredAllDocuments(newFilteredAllDocuments);
  }, [documentFilterTag]);

  useEffect(() => {
    if (selectedDocument) {
      let newFilteredAllDocuments = allDocuments.filter(
        (eachDocument) => eachDocument === selectedDocument
      );

      setFilteredAllDocuments(newFilteredAllDocuments);
    } else {
      setSelectedDocument(null);
      setFilteredAllDocuments(allDocuments);
    }
  }, [selectedDocument]);

  const createDocument = () => {
    dispatch(updateUser({ showDocumentsModal: false }));

    navigate(`/document/${selectedDocument._id}`);
  };

  return (
    <div className="appOnboardingModal">
      <div className="appOnboardingModalContent appOnboardingModalContentSuccess">
        <div className="appOnboardingContent">
          <div className="appOnboardingSuccess">
            {/* <img src="/blueTransparent.png" className="appOnboardingLogo" /> */}

            <div className="appOnboardingSuccessHeaderContent">
              {/* <h1 className="appOnboardingSuccessHeader">🎉</h1> */}

              <h1 className="appOnboardingSuccessHeader">
                Choose the Document you need
              </h1>

              <p className="appOnboardingSuccessDescription">
                Browse through and select the document that you need to create.
              </p>
            </div>

            <div className="appOnboardingSuccessContent">
              {!selectedDocument && (
                <>
                  <fieldset className="Fieldset appOnboardingInputContainer">
                    <input
                      className="input"
                      autoFocus
                      placeholder="ex: Founders agreement"
                      id="username"
                      value={documentSearchInput}
                      onChange={(e) => setDocumentSearchInput(e.target.value)}
                    />
                  </fieldset>

                  <div className="appOnboardingTags">
                    <p
                      className={`appOnboardingTag ${
                        documentFilterTag === " " && "selectedAppOnboardingTag"
                      }`}
                      onClick={() => setDocumentFilterTag(" ")}
                    >
                      All ({allDocuments.length})
                    </p>
                    {docsInfo.map((eachDocInfo) => (
                      <p
                        className={`appOnboardingTag ${
                          eachDocInfo.type === documentFilterTag &&
                          "selectedAppOnboardingTag"
                        }`}
                        onClick={() => setDocumentFilterTag(eachDocInfo.type)}
                      >
                        {eachDocInfo.type} ({eachDocInfo.documents.length})
                      </p>
                    ))}
                  </div>
                </>
              )}

              <div
                className={`appOnboardingDocuments ${
                  selectedDocument && "documentSelected"
                }`}
              >
                {selectedDocument && (
                  <p className="appOnboardingInfoLabel">
                    Let's create you this document:
                  </p>
                )}

                {filteredAllDocuments.map((eachDocument) => (
                  <div
                    className={`appOnboardingEachDocument ${
                      selectedDocument === eachDocument &&
                      "selectedAppOnboardingEachDocument"
                    }`}
                    onClick={() => {
                      // when document is selected this is replacing the state
                      if (!selectedDocument) {
                        setSelectedDocument(eachDocument);
                      }
                    }}
                  >
                    <CgFileDocument className="appOnboardingEachDocumentIcon" />
                    <p className="appOnboardingEachDocumentText">
                      {eachDocument.title}
                    </p>

                    {selectedDocument === eachDocument && (
                      <div
                        className="documentCheckIconContainer"
                        onClick={() => {
                          setSelectedDocument(null);
                        }}
                      >
                        <CgClose className="appOnboardingEachDocumentIcon documentCheckIcon" />
                      </div>
                    )}
                  </div>
                ))}

                {filteredAllDocuments.length === 0 && (
                  <p className="noDocumentsFound">
                    No document found.{" "}
                    <span onClick={() => Crisp.chat.open()}>
                      Please Send us a quick message
                    </span>{" "}
                  </p>
                )}

                {/* {!selectedDocument && (
                  <div className="appOnboardingGradient"></div>
                )} */}
              </div>

              <div
                className={`documentButton ${
                  !selectedDocument && "disabledDocumentButton"
                }`}
                onClick={() => createDocument()}
              >
                <BsStars className="documentButtonIcon" />
                <p className="documentButtonText">Create Document</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="appOnboardingModalOverlay"
        onClick={() => {
          dispatch(
            updateUser({
              showDocumentsModal: false,
            })
          );
        }}
      ></div>
    </div>
  );
};
