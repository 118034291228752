import React, { createContext, useContext, useState } from "react";
import { returnFormattedDocumentsArray } from "../../functions";

const ArrayContext = createContext();

export function FinalDocumentContext({ children }) {
  const [documentElementsArray, setDocumentElementsArray] = useState([]);

  const updateArray = (updatedArray) => {
    // Logic to update dataArray based on headerID and newParagraph
    // For simplicity, I'm assuming you have a function similar to the one provided earlier
    // updateDocument(headerID, newParagraph);

    //"update array ⚡️", documentElementsArray);

    if (typeof updatedArray === "function") {
      const passedArray = updatedArray(documentElementsArray);

      setDocumentElementsArray(passedArray); // Set the updated array
      //"document array from context: ", passedArray);
    } else {
      setDocumentElementsArray(updatedArray);

      //"document array from context: ", updatedArray);
    }
  };
  return (
    <ArrayContext.Provider
      value={{
        documentElementsArray,
        updateArray,
      }}
    >
      {children}
    </ArrayContext.Provider>
  );
}

export function useDocumentElementsContext() {
  return useContext(ArrayContext);
}
