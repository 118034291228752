import React, { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { GiTicket } from "react-icons/gi";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { BsRocketFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import {
  createBlackFridayPlanLink,
  getDocumentPaymentLinkAPI,
  upgradePlanAPI,
} from "../../api/apiCalls";
import { Oval } from "react-loader-spinner";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PricingPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [perDocumentLoading, setPerDocumentLoading] = useState(false);

  const document = useSelector((state) => state.document);

  const upgradePlan = async () => {
    setLoading(true);

    if (selectedPlanType === "annual") {
      const response = await createBlackFridayPlanLink({
        redirectUrl: window.location.href.includes("pricing")
          ? `https://app.useairstrip.com`
          : window.location.href,
      });

      if (response.data) {
        setLoading(false);

        //   //response.data);

        window.location.href = response.data.url;

        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
      }
    } else {
      const response = await upgradePlanAPI({
        redirectUrl: window.location.href,
      });

      if (response.data) {
        setLoading(false);
        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
        //   //response.data);

        window.location.href = response.data.url;
      }
    }
  };

  const generatePaymentLink = async (onSuccess) => {
    setPerDocumentLoading(true);

    // //document.documentInfo);

    const response = await getDocumentPaymentLinkAPI({
      documentId: document.documentDetails._id,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPerDocumentLoading(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const buyDocument = () => {
    if (
      document.documentInfo &&
      window.location.href.split("/").includes("document")
    ) {
      generatePaymentLink(() => {
        dispatch(
          updateUser({
            showUpgradeModal: false,
          })
        );
      });
    } else {
      dispatch(
        updateUser({
          showUpgradeModal: false,
        })
      );
      navigate("/browse-documents");
    }
  };

  const [selectedPlanType, setSelectedPlanType] = useState("annual");

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("plan") && searchParams.get("plan") === "pro") {
      upgradePlan();
    }
  }, [searchParams]);

  const getHoursRemaining = () => {
    var currentDate = new Date();

    // Specify the target date (November 24)
    var targetDate = new Date(
      currentDate.getFullYear(),
      10 /* November is 0-based in JavaScript */,
      24
    );

    // Calculate the time difference in milliseconds
    var timeDifference = targetDate - currentDate;

    // Convert the time difference to hours
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference.toFixed(2);
  };

  return (
    <div className="pricingPlansContentContainer">
      {loading && (
        <div className="pricingLoading">
          <Oval
            height="50"
            width="50"
            radius={4}
            strokeWidth={5}
            color="#6550eb"
            secondaryColor="#8e7ef2"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            // wrapperClass="loadingIconButton"
            visible={true}
          />
        </div>
      )}

      <div className="pricingPlansNav">
        <div
          className="pricingPlansBackButton"
          onClick={() => {
            dispatch(
              updateUser({
                showUpgradeModal: false,
              })
            );
            window.location.href = "/";
          }}
        >
          <IoMdArrowBack className="pricingPlansBackButtonIcon" />
          <p className="pricingPlansBackButtonText">Back</p>
        </div>

        <img
          src="/logoBlueTransparent.png"
          alt=""
          className="pricingPlanLogo"
        />
      </div>

      <div className="pricingPlansHeader">
        <h1 className="pricingPlansHeaderText">
          <span className="pricingPlansHeaderTextPurple">Upgrade</span> your
          plan
          {/* <BsRocketFill className="upgradePlanButtonIcon" /> */}
        </h1>
        <p className="pricingPlansHeaderDescription">
          Create unlimited personalised and compliant legal documents, make
          unlimited revisions, and store them securely all in-one-place.
        </p>
      </div>

      <div className="selectPlanType">
        <div
          className={`planType  ${
            selectedPlanType === "annual" && "selectedPlanType annualPlanType"
          }`}
          onClick={() => setSelectedPlanType("annual")}
        >
          Annual (55% off)
        </div>
        <div
          className={`planType ${
            selectedPlanType === "monthly" && "selectedPlanType"
          }`}
          onClick={() => setSelectedPlanType("monthly")}
        >
          Monthly
        </div>
      </div>

      {selectedPlanType === "monthly" ? (
        <div className="pricingPlansContainer">
          <div className="pricingPlansTop">
            {/* <div className="eachPricingPlan">
            <div className="eachPricingPlanHeader">
              <p className="pricingPlanName">Starter</p>
              <p className="pricingPlanPrice">Free</p>
            </div>

            <div className="pricingFeatures">
              <li className="eachPricingFeature">
                One Compliant & Personalised Legal Document
              </li>
              <li className="eachPricingFeature">
                Legal Assistance from Pilot
              </li>
              <li className="eachPricingFeature">
                Simplify <b>Unlimited</b> Legal Documents
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Unlimited Revisions using Plain English
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Securely store your documents
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Priority Support
              </li>
            </div>

            <div className="pricingPlanButton currentPlan">
              <p className="pricingPlanButtonText">Current Plan</p>
            </div>
          </div> */}

            <div className="eachPricingPlan proPlan">
              <div className="eachPricingPlanHeader">
                <p className="pricingPlanName">
                  Pro
                  <BsRocketFill className="upgradePlanButtonIcon" />
                </p>
                <p className="pricingPlanPrice">$50/month</p>

                <div className="pricingPlanBestValue">
                  <GiTicket className="bestValueIcon" />
                  <p className="bestValueText">Best Value!</p>
                </div>
              </div>

              <div className="pricingFeatures">
                <li className="eachPricingFeature">
                  <b>Unlimited</b> Documents
                </li>
                <li className="eachPricingFeature">
                  <b>Unlimited</b> Revisions using Plain English
                </li>
                {/* <li className="eachPricingFeature">
                <b>Unlimited</b> Legal Assistance from Pilot
              </li> */}
                <li className="eachPricingFeature">
                  Simplify <b>Unlimited</b> Legal Documents
                </li>
                <li className="eachPricingFeature">
                  Securely store your documents
                </li>
                <li className="eachPricingFeature">Priority Support</li>
              </div>

              <div
                className={`pricingPlanButton proPlanButton ${
                  loading && "disabledButton"
                }`}
                onClick={() => upgradePlan()}
              >
                <p className="pricingPlanButtonText">Upgrade to Pro</p>
                {loading && (
                  <Oval
                    height="18"
                    width="18"
                    radius={4}
                    strokeWidth={5}
                    color="#ffffff"
                    secondaryColor="#ffffff40"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass="loadingIconButton"
                    visible={true}
                  />
                )}
              </div>
            </div>

            <div className="eachPricingPlan">
              <div className="eachPricingPlanHeader">
                <p className="pricingPlanName">Pay for this document</p>
                <p className="pricingPlanPrice">$30 per document</p>
              </div>

              <div className="pricingFeatures">
                <li className="eachPricingFeature">
                  One Compliant & Personalised Document
                </li>
                <li className="eachPricingFeature">
                  <b>Unlimited</b> Revisions using Plain English
                </li>
                <li className="eachPricingFeature">
                  Simplify <b>Unlimited</b> Legal Documents
                </li>
                <li className="eachPricingFeature">
                  Securely store your documents
                </li>
                <li className="eachPricingFeature">Priority Support</li>
              </div>

              <div
                className={`pricingPlanButton ${
                  perDocumentLoading && "disabledButton"
                }`}
                onClick={() => {
                  buyDocument();
                  // dispatch(
                  //   updateUser({
                  //     showUpgradeModal: false,
                  //   })
                  // );
                  // navigate("/browse-documents");
                }}
              >
                <p className="pricingPlanButtonText">
                  {document.documentInfo &&
                  window.location.href.split("/").includes("document") ? (
                    <>
                      Purchase <b>{document.documentDetails.title}</b>
                    </>
                  ) : (
                    "Choose Document"
                  )}
                  {perDocumentLoading && (
                    <Oval
                      height="18"
                      width="18"
                      radius={4}
                      strokeWidth={5}
                      color="#ffffff"
                      secondaryColor="#ffffff40"
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperClass="loadingIconButton"
                      visible={true}
                    />
                  )}
                  {/* Choose Document */}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="payAsYouGoPricingPlan">
          <div className="payAsYouGoPricingHeader">
            <h1 className="payAsYouGoPricingPlanText">Pay as you go</h1>
            <p className="payAsYouGoPricingPlanDescription">
              Choose what document you need, make unlimited revisions using
              plain english, unlimited legal assistance, and secure storage -
              and pay as you use.
            </p>

            <div className="chooseDocument">
              <p className="chooseDocumentText">Choose Document</p>
              <BiRightArrowAlt className="chooseDocumentIcon" />
            </div>
          </div>

          <div className="payAsYouGoPricingPlanPriceContainer">
            <p className="payAsYouGoPrice">$30</p>
            <p className="payAsYouGoPriceText">per document</p>
          </div>
        </div> */}
        </div>
      ) : (
        <div className="pricingPlansContainer">
          <div className="pricingPlansTop">
            {/* <div className="eachPricingPlan">
            <div className="eachPricingPlanHeader">
              <p className="pricingPlanName">Starter</p>
              <p className="pricingPlanPrice">Free</p>
            </div>

            <div className="pricingFeatures">
              <li className="eachPricingFeature">
                One Compliant & Personalised Legal Document
              </li>
              <li className="eachPricingFeature">
                Legal Assistance from Pilot
              </li>
              <li className="eachPricingFeature">
                Simplify <b>Unlimited</b> Legal Documents
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Unlimited Revisions using Plain English
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Securely store your documents
              </li>
              <li className="eachPricingFeature notAvailableFeature">
                Priority Support
              </li>
            </div>

            <div className="pricingPlanButton currentPlan">
              <p className="pricingPlanButtonText">Current Plan</p>
            </div>
          </div> */}

            <div
              className="eachPricingPlan proPlan"
              style={{ width: "380px", marginLeft: "30px" }}
            >
              <div className="eachPricingPlanHeader">
                <p className="pricingPlanName">
                  Pro
                  <BsRocketFill className="upgradePlanButtonIcon" />
                </p>
                <p className="pricingPlanPrice">
                  <div className="slashedPriceText">$50</div> <p>$20/month</p>
                </p>

                <div className="pricingPlanBestValue">
                  <GiTicket className="bestValueIcon" />
                  <p className="bestValueText">Best Value!</p>
                </div>

                <span className="savedAmount">
                  <b>Save 55% ($300)</b> with annual plan
                </span>
              </div>

              <div className="pricingFeatures">
                <li className="eachPricingFeature">
                  <b>Unlimited</b> Documents
                </li>
                <li className="eachPricingFeature">
                  <b>Unlimited</b> Revisions using Plain English
                </li>
                {/* <li className="eachPricingFeature">
                <b>Unlimited</b> Legal Assistance from Pilot
              </li> */}
                <li className="eachPricingFeature">
                  Simplify <b>Unlimited</b> Legal Documents
                </li>
                <li className="eachPricingFeature">
                  Securely store your documents
                </li>
                <li className="eachPricingFeature">Priority Support</li>
              </div>

              {/* <p className="accountInfoPlanExpire">Expires in 48 Hours (Nov 24)</p> */}

              <div
                className={`pricingPlanButton proPlanButton ${
                  loading && "disabledButton"
                }`}
                onClick={() => upgradePlan()}
              >
                <p className="pricingPlanButtonText">Get Annual Pro Plan</p>
                {loading && (
                  <Oval
                    height="18"
                    width="18"
                    radius={4}
                    strokeWidth={5}
                    color="#ffffff"
                    secondaryColor="#ffffff40"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass="loadingIconButton"
                    visible={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
