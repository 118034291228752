export const UPDATE_USER = "UPDATE_USER";

const initialState = {
  name: "",
  email: "",
  token: "",
  onboardingShown: false,
  redirectLink: null,
  isOnboardingCompleted: false,
  companyName: "",
  country: "",
  userToVerify: {
    name: "",
    email: "",
    token: "",
  },
  showUpgradeModal: false,
  showDocumentsModal: false,
  onboardingSteps: [
    {
      title: "Provide more context",
      description:
        "Upload any documents with information about your business for Airstrip AI to provide you personalised and accurate assistance.",
      link: "/settings",
      buttonText: "Add Context",
      completed: false,
    },
    {
      title: "Get your Legal questions answered - from Pilot",
      description:
        "Ask your legal questions from Pilot and get personalised and actionable assistance, that will help you make the right decision.",
      link: "/chat",
      buttonText: "Chat with Pilot",
      completed: false,
    },
    {
      title: "Create a New Document",
      description:
        "Browse through available documents, choose what document you need, and get a personalised and lawyer-level document.",
      link: "/browse-documents",
      buttonText: "Browse Documents",
      completed: false,
    },
    {
      title: "View your documents",
      description:
        "Have your documents stored securely all-in-one place, and keep track of them here.",
      link: "/my-documents",
      buttonText: "View saved documents",
      completed: false,
    },
  ],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
