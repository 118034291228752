import React, { useEffect, useState } from "react";
import { HiDocumentText } from "react-icons/hi";
import { VscCloudUpload } from "react-icons/vsc";
import { Oval } from "react-loader-spinner";
import { getDocumentDraftAPI, getMyDraftsAPI } from "../../api/apiCalls";
import { formatDate } from "../../functions";

export const UploadDocument = ({
  openFileUpload,
  documentUploading,
  allDocuments,
  documentsLoading,
  getDraft,
}) => {
  const [searchText, setSearchText] = useState("");

  return (
    <div
      className="simplifyDocumentsUploadContainer"
      id="simplifyDocumentsUploadContainer"
    >
      {documentUploading && (
        <div className="loadingContainer">
          <Oval
            height={20}
            width={20}
            color="#1253f3"
            wrapperStyle={{ marginLeft: 5 }}
            wrapperClass="loadingIcon"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1253f340"
            strokeWidth={5}
            strokeWidthSecondary={5}
          />
        </div>
      )}

      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf"
      />

      <h1 className="chooseDocumentHeaderText">
        Choose a Document to Simplify
      </h1>
      <div className="simplifyDocumentsContent">
        <div className="simplifyDocumentsListContainer">
          <input
            type="text"
            className="simplifyDocumentsSearch"
            placeholder="Search your Documents"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          {documentsLoading ? (
            <div className="simplifyDocumentsListLoading">
              <Oval
                height={15}
                width={15}
                color="#1253f3"
                wrapperStyle={{ marginLeft: 5 }}
                wrapperClass="loadingIcon"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#1253f340"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />

              <p className="simplifyDocumentsListLoadingText">
                Getting Documents...
              </p>
            </div>
          ) : (
            <div className="simplifyDocumentsList">
              {allDocuments.length === 0 && (
                <p
                  className="simplifyDocumentsListLoadingText"
                  style={{
                    opacity: 0.7,
                    backgroundColor: "#191f2f10",
                    padding: 20,
                    borderRadius: 5,
                    lineHeight: "1.4em",
                  }}
                >
                  No documents created yet. Create or Drop a legal document here
                  to simplify it.
                </p>
              )}

              {allDocuments
                .filter((eachDocument) =>
                  eachDocument.name
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                .map((eachDocument) => (
                  <div
                    className="simplifyEachDocument"
                    onClick={() => {
                      getDraft(eachDocument._id);
                    }}
                  >
                    <div className="simplifyEachDocumentLeft">
                      <HiDocumentText className="simplifyEachDocumentIcon" />
                      <p className="simplifyEachDocumentName">
                        {eachDocument.name}
                      </p>
                    </div>

                    <p className="simplifyEachDocumentCreatedDate">
                      {formatDate(eachDocument.createdAt)}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <p className="orText">or</p>

      <div
        className="simplifyDocumentsUploadButton"
        onClick={() => openFileUpload()}
      >
        <VscCloudUpload className="simplifyDocumentsUploadButtonIcon" />
        <p className="simplifyDocumentsUploadButtonText">
          Upload a new Document
        </p>
      </div>
    </div>
  );
};
